<template>
  <div id="processplaceholder">
    <b-row>
      <b-col>
        <h1 v-if="errorDescription.length > 0"><b-icon icon="gear-wide-connected"></b-icon> {{ $t('OVERVIEW.title') }}</h1><!-- Title, display only when no permission -->
        <b-alert v-if="errorDescription.length > 0" show variant="danger">{{ errorDescription }}</b-alert><!-- Errors? -->
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="currentProcessData != null">
            <div id="processTitlePlaceholder">
              <!-- Root, Parent link -->
              <b-button v-if="currentProcessData.process.id != currentProcessData.process.rootId"
                        size="sm" variant="info" class="my-1 mx-1"
                        v-on:click="loadProcessData(currentProcessData.process.rootId)">
                <b-icon icon="arrow-up-short"></b-icon> {{ $t('OVERVIEW.buttonRootProcess') }}
              </b-button>
              <b-button v-if="currentProcessData.process.parentId != currentProcessData.process.rootId"
                        size="sm" variant="warning" class="my-1 mx-1"
                        v-on:click="loadProcessData(currentProcessData.process.parentId)">
                <b-icon icon="arrow-up-short"></b-icon> {{ $t('OVERVIEW.buttonParentProcess') }}
              </b-button>
              <div class="bg-secondary text-primary px-2 text-right"><ViewStatus type="process" :status="currentProcessData.process.status" :noText="false"></ViewStatus></div>
              <h2 class="text-center">{{ currentProcessData.process.name }}</h2>
            </div>
            <hr>
            <b-icon v-if="currentProcessData.workflow.categoryName && currentProcessData.workflow.categoryName.length > 0"
                    icon="square-fill"
                    :style="{ color: currentProcessData.workflow.categoryColor }"></b-icon>
                {{ currentProcessData.workflow.categoryName }}
            <h4>{{ currentProcessData.workflow.name }}</h4>
            <hr>
            <b-row class="bg-secondary mx-1 py-2" v-if="(taskList.length > 0 || approveList.length > 0) && !hideActions">
              <b-col md="2" class="text-right"></b-col>
              <b-col md="8">
                <!-- Open Tasks for this process (and parents) -->
                <b-row v-for="task in taskList" v-bind:key="task.processId + '-' + task.taskId">
                  <b-col><ViewStatus type="task" :status="task.status" :noText="true"></ViewStatus>
                    <span v-if="task.processId !== task.processParentId" class="ml-1 px-1 text-secondary">[{{ task.workflowName }}]</span><span class="ml-1 px-1 text-primary font-weight-bold">{{ task.taskName }}</span>
                    <b-badge variant="info">{{ task.modificationDate.slice(0, 19) }}</b-badge>
                  </b-col>
                  <b-col class="text-right">
                    <span v-if="task.status === 1"><!-- not yet assigned but started -->
                      <b-button
                          variant="success"
                          size="sm"
                          @click="startTask(task.workflowId, task.taskId, task.processId)"
                          class="m-1"
                      >
                          <b-icon icon="play-fill"></b-icon> {{ $t('MYTASKS.btnTakeInCharge') }}
                      </b-button>
                    </span>
                    <span v-if="task.status === 2"><!-- assigned to this user -->
                      <b-button
                          variant="success"
                          size="sm"
                          @click="startTask(task.workflowId, task.taskId, task.processId)"
                          class="m-1"
                      >
                          <b-icon icon="play-fill"></b-icon> {{ $t('MYTASKS.btnContinue') }}
                      </b-button>
                      <b-button
                          variant="warning"
                          size="sm"
                          @click="releaseTask(task.workflowId, task.taskId, task.processId)"
                          class="m-1"
                      >
                          <b-icon icon="stop-fill"></b-icon> {{ $t('MYTASKS.btnRelease') }}
                      </b-button>
                    </span>
                  </b-col>
                </b-row>

                <!-- Open Approvals for this process (and parents) -->
                <b-row v-for="approval in approveList" v-bind:key="approval.processId + '-' + approval.approvalId" class="mb-1">
                  <b-col><ViewStatus type="approval" :status="approval.approvalStatus" :noText="true"></ViewStatus>
                    <span v-if="approval.processId !== approval.processParentId" class="ml-1 px-1 text-secondary">[{{ approval.workflowName }}]</span><span class="ml-1 px-1 text-primary font-weight-bold">{{ approval.approvalDefName }}</span>
                    <b-badge variant="info">{{ approval.approvalCreationDate.slice(0, 19) }}</b-badge>
                  </b-col>
                  <b-col class="text-right">
                    <span v-if="approval.approvalStatus === 0"><!-- created ==> available for approval/reject -->
                      <b-button
                          variant="primary"
                          size="sm"
                          @click="startApproval(approval)"
                      >
                          <b-icon icon="play-fill"></b-icon> {{ $t('MYTASKS.btnApproveReject') }}
                      </b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-tabs class="mt-4">
                <!-- Workflow tab, description + attachments -->
                <b-tab v-if="currentProcessData.workflow.attachments.length > 0 || currentProcessData.workflow.description !== null">
                  <template v-slot:title><b-icon style="color:gray" icon="grid"></b-icon>&nbsp;{{ $t('OVERVIEW.workflow') }}</template>
                  <b-row>
                    <b-col><br>
                      <span v-if="currentProcessData.workflow.description !== null" v-html="currentProcessData.workflow.description"></span>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row v-for="attachment in currentProcessData.workflow.attachments" v-bind:key="attachment.id">
                    <b-col><hr>
                      <div>
                        <!-- Direct download - workflow files -->
                        <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + attachment.downloadToken" target="_blank" class="font-weight-bold">
                          <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{attachment.originalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( attachment.fileSize / 1024) }} KB )
                        </a>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
                <!-- Task tabs -->
                <b-tab v-for="task in currentProcessData.tasks" v-bind:key="task.id"><!-- Generate one tab for each task -->
                    <template v-slot:title><!-- Tab title -->
                      <ViewStatus type="task" :status="task.status" :noText="true"></ViewStatus>
                      &nbsp;<i>{{ task.name }}</i>
                    </template>
                    <b-row>
                        <b-col style="text-align:right">
                            <span>
                              <ViewStatus type="task" :status="task.status" :noText="false"></ViewStatus>
                              &nbsp; - {{ task.modificationDate.slice(0,19) }}
                            </span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col><span v-if="task.assignedToUserName">{{ $t('OVERVIEW.assignedTo') }} <b-icon icon="person-plus-fill"></b-icon> &nbsp;<b>{{ task.assignedToUserName }}</b></span></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                          <br>
                            <b-alert v-if="task.permission === 0" variant="danger" show>
                              <b-icon icon="shield-lock-fill" scale="1.5" class="mr-2"></b-icon> {{ $t('OVERVIEW.unauthorized') }}
                            </b-alert>
                            <!-- render the Form -->
                            <div v-for="field in task.items" v-bind:key="field.id">
                              <b-row>
                                <b-col sm="12" v-if="field.type == 'form_text'"><!-- Field name + description FULL WIDTH -->
                                  <!--<div class="text-primary .d-none"><b><b-icon style="color:green" icon="hash"></b-icon>test {{ field.name }}</b></div>-->
                                  <span v-if="field.description !== null" v-html="field.description" ></span>
                                  <span v-else>{{ field.name }}</span> <!-- put the field name if empty -->
                                </b-col>

                                <b-col sm="12" lg="6" v-if="field.type != 'form_text'"><!-- Field name + description -->
                                  <!--<div class="text-primary"><b-icon style="color:green" icon="hash"></b-icon> <b>{{ field.name }}</b></div>-->
                                  <span v-if="field.description !== null" v-html="field.description" ></span>
                                  <span v-else>{{ field.name }}</span> <!-- put the field name if empty -->
                                </b-col>
                                <b-col sm="12" lg="6" v-if="field.type != 'form_text'"><!-- Value of field -->
                                  <div v-if="field.type == 'boolean'" class="user_input">
                                      <b-form-checkbox v-model="field.value"
                                          value="1"
                                          unchecked-value="0"
                                          :disabled="true"
                                      >
                                      </b-form-checkbox>
                                  </div>
                                  <div v-else-if="field.type == 'text' && field.isMultiline === 1" class="user_input"><!-- html text -->
                                      <span v-if="!field.value">-</span>
                                      <div v-html="field.value" class="p-2" style="background-color:white"></div>
                                  </div>
                                  <div v-else-if="field.type == 'date'" class="user_input"><!-- date -->
                                      <b-form-datepicker v-model="field.value" class="mb-2"
                                          :locale="$i18n.locale"
                                          disabled
                                      >
                                      </b-form-datepicker>
                                  </div>
                                  <div v-else-if="field.type == 'file'" class="user_input"> <!-- file -->
                                    <span v-if="!field.value">-</span>
                                    <span v-if="field.value != null">
                                      <b-container fluid class="p-4 bg-light text-center">
                                         <!-- Render form_image as image if possible -->
                                        <b-img center thumbnail fluid :src="$store.state.serviceAPIUrl + 'workflow/image/preview/' + field.downloadToken" alt=""></b-img>
                                        <!-- Direct download - process files -->
                                        <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + field.downloadToken" target="_blank" class="font-weight-bold">
                                          <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{field.value.split('|')[2]}}&nbsp;&nbsp;&nbsp;( {{ Math.round( field.value.split('|')[1] / 1024) }} KB )
                                        </a>
                                      </b-container>
                                    </span>
                                  </div>
                                  <span v-else-if="field.type == 'form_video'"><!-- form video -->
                                    <div v-if="field.defaultValue != null && field.defaultValue.length > 5" class="p-4">
                                      <video-embed :src="field.defaultValue"></video-embed>
                                    </div>
                                  </span>
                                  <span v-else-if="field.type == 'form_file' || field.type == 'form_image'"><!-- form files -->
                                    <span v-if="field.fileIdentifier != null">
                                      <b-container fluid class="p-4 bg-light text-center">
                                        <!-- Render form_image as image if possible -->
                                        <b-img v-if="field.type === 'form_image'" center thumbnail fluid :src="$store.state.serviceAPIUrl + 'workflow/image/preview/' + field.downloadToken" alt=""></b-img>
                                        <!-- Direct download - task files -->
                                        <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + field.downloadToken" target="_blank" class="font-weight-bold">
                                          <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{field.fileOriginalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( field.fileSize / 1024) }} KB )
                                        </a>
                                      </b-container>
                                    </span>
                                  </span>
                                  <div v-else class="user_input">{{ field.value || '-' }}</div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col><hr></b-col>
                              </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-tab>
                <!-- Approval tabs -->
                <b-tab v-for="approval in currentProcessData.approvals" v-bind:key="approval.id"><!-- Generate one tab for each approval task -->
                  <template v-slot:title><!-- Tab title -->
                    <ViewStatus type="approval" :status="approval.status" :noText="true"></ViewStatus>
                    &nbsp;<i>{{ approval.name }}</i>
                  </template>
                  <b-row>
                    <b-col>
                      <b-alert v-if="approval.permission === 0" variant="danger" show class="mt-4">
                        <b-icon icon="shield-lock-fill" scale="1.5" class="mr-2"></b-icon> {{ $t('OVERVIEW.unauthorized') }}
                      </b-alert>
                      <!-- Single approval -->
                      <div v-for="(singleApproval, index) in approval.approvals" v-bind:key="index">
                        <br>
                        <ViewStatus type="approval" :status="singleApproval.status" :noText="true"></ViewStatus>
                        &nbsp;<b>{{ $t('OVERVIEW.approval') }}</b> {{ $t('OVERVIEW.approval.created') }} {{ singleApproval.creationDate.slice(0,19) }}
                        <span v-if="singleApproval.approvalTime != null">, {{ $t('OVERVIEW.approval.completed') }} {{ singleApproval.approvalTime.slice(0,19) }}</span>
                        <br><br>
                        <ol><!-- List of users -->
                          <li v-for="user in singleApproval.users" v-bind:key="user.id">
                            <span v-if="user.status === 0"><b-icon variant="danger"  icon="hand-thumbs-down"></b-icon>&nbsp; <b>{{ user.name }}</b> {{ $t('OVERVIEW.approval.rejected') }} </span>
                            <span v-if="user.status === 1"><b-icon variant="success" v-if="user.status == 1" icon="hand-thumbs-up"></b-icon>&nbsp; <b>{{ user.name }}</b> {{ $t('OVERVIEW.approval.approved') }} </span>
                            {{ user.approvalTime.slice(0,19) }}
                            <div v-if="user.reason !== null"><b>{{ $t('OVERVIEW.approval.reason') }}:</b><br> {{ user.reason }}</div>
                          </li>
                        </ol>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
                <!-- Subprocess tabs -->
                <b-tab v-for="subprocess in currentProcessData.subprocesses" v-bind:key="subprocess[0].definitionId"><!-- Generate one tab for each subprocess (of the same definition) -->
                  <template v-slot:title><!-- Tab title -->
                    <ViewStatus type="process" :status="subprocess[0].status" :noText="true"></ViewStatus>
                    &nbsp;<i>{{ subprocess[0].definitionName }}</i>
                  </template>
                  <br>
                  <b-row v-if="subprocess.length > 0 && subprocess[0].permission === 0">
                    <b-col>
                      <b-alert variant="danger" show class="mt-4">
                          <b-icon icon="shield-lock-fill" scale="1.5" class="mr-2"></b-icon> {{ $t('OVERVIEW.unauthorized') }}
                      </b-alert>
                    </b-col>
                  </b-row>
                  <b-row class="m-2" v-for="(singleSubprocess, index) in subprocess" v-bind:key="index"><!-- Single subprocess -->
                    <b-col v-if="singleSubprocess.permission === 1">
                      <span>{{ singleSubprocess.creationDate.slice(0,19) }}</span><br>
                      <h3 class="text-primary font-weight-bold">{{ singleSubprocess.workflowName }}</h3>
                    </b-col>
                    <b-col v-if="singleSubprocess.permission === 1" class="text-right">
                      <ViewStatus type="process" :status="singleSubprocess.status" :noText="false"></ViewStatus><br>
                      <b-button
                          size="sm" variant="primary" class="my-1"
                          v-on:click="loadProcessData(singleSubprocess.processId)">
                        <b-icon icon="arrow-right-short"></b-icon> {{ $t('OVERVIEW.buttonSubProcess') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
                <!-- Reports tab (only 1 for all) -->
                <b-tab v-if="Object.keys(currentProcessData.reports).length > 0">
                  <template v-slot:title><!-- Tab title -->
                    <b-img style="height: 25px" :src="wordImg"></b-img> {{ $t('OVERVIEW.report') }}
                  </template>
                  <b-row>
                    <b-col class="mt-3">
                      <b-alert v-if="Object.keys(currentProcessData.reports).length > 0 && currentProcessData.reports[Object.keys(currentProcessData.reports)[0]].permission === 0" variant="danger" show class="mt-4">
                          <b-icon icon="shield-lock-fill" scale="1.5" class="mr-2"></b-icon> {{ $t('OVERVIEW.unauthorized') }}
                      </b-alert>
                      <!-- Report -->
                      <div class="my-2 mx-2" v-for="report in currentProcessData.reports" v-bind:key="report.id">
                        <div v-if="report.permission === 1">
                          <b-link :href="$store.state.serviceAPIUrl + 'workflow/download/' + report.downloadToken" target="_blank">
                            <b-img style="height: 25px" :src="wordImg"></b-img> {{ report.name }}
                          </b-link> <span class="mx-3"><i>( {{ Math.round( report.fileSize / 1024) }} KB, {{ report.creationDate.slice(0,19) }} )</i></span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
            </b-tabs>
            <br>
        </div>
        <div v-if="loading">
            <br><b-spinner label="Spinning"></b-spinner> {{ $t('GLOBAL.loading') }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ViewStatus from '../common/ViewStatus'

export default {
  name: 'ViewProcess',
  components: {
    ViewStatus
  },
  props: {
    processId: String,
    hideActions: Boolean
  },
  data () {
    return {
      currentProcessData: null,
      errorDescription: '',
      loading: true,
      wordImg: process.env.BASE_URL + 'mxGraph/custom/icons8-microsoft-word-2019-96.png?t=20210414',
      taskList: [],
      approveList: []
    }
  },
  computed: {
  },
  mounted () {
    this.loadProcessData(this.processId)
    this.getMyTaskList()
    this.getMyApprovalList()
  },
  methods: {
    async loadProcessData (theProcessId) {
      // Load all data and values related to all tasks of this process
      this.currentProcessData = null
      this.errorDescription = ''
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/process', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ processId: theProcessId })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.currentProcessData = content.data
            this.loading = false
          } else {
            this.errorDescription = content.errorDescription
            this.loading = false
          }
        } else {
          this.errorDescription = this.$t('OVERVIEW.approval.error')
          this.loading = false
        }
      } catch (err) {
        this.errorDescription = err
        this.loading = false
      }
    },
    async getMyTaskList () {
      // Retrieve the list of tasks
      this.errorDescription = ''
      try {
        // Get the list of my tasks
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/mylist', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ fromDate: null, toDate: null, status: null, workflowId: null, process: null, rootProcessId: this.processId })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.taskList = content.taskList
          this.totalRowsTask = content.taskList.length
        } else {
          this.errorDescription = this.$t('MYTASKS.errorTasks')
          this.taskList = []
        }
      } catch (err) {
        this.errorDescription = err
        this.taskList = []
      }
    },
    async getMyApprovalList () {
      // Retrieve the list of tasks
      try {
        // Get full list of approval tasks
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'approve/mylist', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ fromDate: null, toDate: null, workflowId: null, process: null, rootProcessId: this.processId })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.approveList = content.approveList
          this.totalRowsApproval = content.approveList.length
        } else {
          this.approveList = []
        }
      } catch (err) {
        this.approveList = []
      }
    },
    async startTask (workflowId, taskId, processId) {
      // Take task (or continue)
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/work', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({
            workflowId: workflowId,
            taskId: taskId,
            processId: processId
          })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.$store.commit('currentTaskSet', content) // Update store with this data
            this.$router.push({ name: 'TaskEdit' }) // Open the task edit page
          } else {
            this.$bvToast.toast(this.$t('MYTASKS.errorTaskWork') + ': ' + content.errorDescription, {
              title: this.$t('MYTASKS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('MYTASKS.errorTaskWork'), {
            title: this.$t('MYTASKS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
      }
    },
    async releaseTask (workflowId, taskId, processId) {
      // Take task (or continue)
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/release', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({
            workflowId: workflowId,
            taskId: taskId,
            processId: processId
          })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.$bvToast.toast(this.$t('MYTASKS.released'), {
              title: this.$t('MYTASKS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
            this.getMyTaskList() // Reload
            this.loadProcessData(this.processId) // Reload
          } else {
            this.$bvToast.toast(this.$t('MYTASKS.errorRelease') + ': ' + content.errorDescription, {
              title: this.$t('MYTASKS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('MYTASKS.errorRelease'), {
            title: this.$t('MYTASKS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
      }
    },
    async startApproval (approval) {
      // Store approval data into store and open approval page
      this.$store.commit('currentApprovalSet', approval)
      this.$router.push({ name: 'ApproveEdit' })
    }
  }
}
</script>

<style scoped>
.user_input {
  padding:0.3em;
  background-color:rgb(219, 250, 240);
  border: 1px solid rgb(125, 221, 191);
}
#processplaceholder {
  text-align: left;
}
#processTitlePlaceholder {
  text-align: left;
  color:white;
  background-color:#0d78b6;
  background: linear-gradient(0deg, #185d85 0%, #0d78b6 25%, #159be9 100%);
  xxborder: 10px solid #159be9;
  border-radius:10px;
  padding:10px;
}
.fields {
  font-weight: bold;
}
</style>
