<template>
  <!-- Locale selector -->
  <b-form inline>
    <span v-if="$i18n.locale === 'it'"><country-flag country='it' size='normal'/></span>
    <span v-if="$i18n.locale === 'en'"><country-flag country='gb' size='normal'/></span>
    <b-form-select class="mx-1" style="width:4rem" v-model="language" :options="languages" ></b-form-select>
  </b-form>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data () {
    return {
      languages: [{ value: 'en', text: 'en' }, { value: 'it', text: 'it' }]
    }
  },
  computed: {
    language: {
      get () {
        if (this.$cookies.isKey('language')) {
          return this.$cookies.get('language')
        } else {
          return 'en'
        }
      },
      set (value) {
        // Set both locally and into the cookie
        this.$i18n.locale = value // Locally
        this.$cookies.set('language', value) // Cookie
      }
    }
  }
}
</script>
