<template>
    <b-container class="myContainer">
    <b-row>
      <b-col sm="12" md="3" style="text-align:left">
        <div style="text-align:center"><b-img :src="require('../assets/LogoLG.png')" fluid alt=""></b-img></div>
      </b-col>
    </b-row>
    <b-row>
        <b-col><br><h1 class="bg-secondary text-primary">Welcome to <strong>DigiWi</strong> Software Service for your Business</h1><br>
        </b-col>
    </b-row>
    <b-row>
        <b-col sm="12"  md="3" class="text-left"></b-col>
        <b-col sm="12"  md="9" class="text-left">
          <h4><span class="myColor">D</span>igital Business Processes Management</h4>
          <h4><span class="myColor">I</span>nitiative to digital transformation</h4>
          <h4><span class="myColor">G</span>raphical processes and forms design</h4>
          <h4><span class="myColor">I</span>nnovative business collaboration</h4>
          <h4><span class="myColor">W</span>orkflows administration and execution</h4>
          <h4><span class="myColor">I</span>ntegration between processes and forms</h4>
        </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="2"></b-col>
      <b-col sm="12" md="8" style="text-align:left">
        <br>
        <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger" dismissible fade>{{ errorDescription }}</b-alert></div>
        <div v-if="displayMessage.length > 0"><br><b-alert show variant="info" dismissible fade>{{ displayMessage }}</b-alert></div>
        <br>
      </b-col>
    </b-row>
    <b-row>
        <b-col sm="12" md="3"></b-col>
        <b-col sm="12" md="6" style="text-align:left">
          <div><LanguageSelector></LanguageSelector></div>
          <b-form @submit="doLogin">
            <b-form-group>
              <div class="text-primary mt-2 mb-0 p-0"><b>{{ $t('LOGIN.Email') }}</b></div>
              <b-form-input v-model="form.email" type="text" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('LOGIN.Password') }}</b></div>
              <b-form-input v-model="form.password" type="password" placeholder="" trim required></b-form-input>
            </b-form-group>
             <b-button v-if="!loading" fluid type="submit" variant="primary" block >{{ $t('LOGIN.btnLogin') }}</b-button>
             <div v-if="loading" style="text-align: center"><br><b-spinner label="Spinning"></b-spinner> {{ $t('LOGIN.loggingIn') }}</div>
          </b-form>
        </b-col>
        <b-col sm="12" md="3"></b-col>
    </b-row>
    <b-row>
      <b-col><br>{{ $t('LOGIN.createAccount.1') }} <b-link :to="{ name: 'RegisterPage' }"><b>{{ $t('LOGIN.createAccount.2') }}</b></b-link></b-col>
    </b-row>
    <b-row>
      <b-col><br><br><br>{{ $t('LOGIN.forgotPassword') }} <b-button class="ml-2" v-on:click="sendReset()" variant="secondary">{{ $t('LOGIN.sendResetLink') }}</b-button>
        <br><br>
      </b-col>
    </b-row>
    </b-container>
</template>

<script>
import LanguageSelector from './common/LanguageSelector'

export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      errorDescription: '',
      displayMessage: '',
      loading: false
    }
  },
  components: {
    LanguageSelector
  },
  mounted () {
    // Ping the service
    fetch(this.$store.state.serviceAPIUrl + '/ping?fromPage=LoginPage', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Language': this.$i18n.locale
      }
    })
  },
  methods: {
    async sendReset () {
      // Send reset password link
      this.errorDescription = ''
      this.displayMessage = ''

      if (this.form.email.length === 0) {
        this.errorDescription = this.$t('LOGIN.insertEmail')
        return
      }
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + '/usermanager/resetpasswordlink', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ email: this.form.email })
        })

        // Get the json contents
        const content = await rawResponse.json()
        // Complete the credentials with the token if login successful
        if (content.errorCode === 0) {
          // Reset link send
          this.displayMessage = this.$t('LOGIN.emailSent', { email: this.form.email })
        } else {
          // Reset link error
          this.errorDescription = content.errorDescription
        }
      } catch (err) {
        // Login failed (connection problems)
        this.errorDescription = err
      }
    },
    async doLogin (evt) {
      // Do the authentication
      evt.preventDefault()
      this.errorDescription = ''
      this.displayMessage = ''
      this.loading = true
      await this.$nextTick()

      try {
        // Login with user and password
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify(this.form)
        })

        // Get the json contents
        const content = await rawResponse.json()
        // Complete the credentials with the token if login successful
        if (content.errorCode === 0) {
          // Login successful
          this.errorDescription = ''
          this.$store.commit('setUserData', content.user_data)
          this.$store.commit('setUserToken', content.token)
          // Go to the home page
          this.$router.push({ name: 'HomePage' })
        } else {
          // Login failure (invalid mail/password)
          this.errorDescription = content.errorDescription
        }
      } catch (err) {
        // Login failed (connection problems)
        this.errorDescription = err
      }

      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.myContainer {
  border: 1px solid var(--primary);
  margin-top:1em;
  margin-bottom:1em;
}
h1, h2 {
  font-weight: normal;
  color: var(--primary);
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.myColor {
  color: var(--primary);
  font-weight: bold;
}
.imageTitle {
  font-size: 30px;
  font-weight: bold;
}
</style>
