<template>
  <div>
      <b-row>
        <b-col sm="12">
            <span v-if="isReference === 0">
            <b-form-file
                v-model="selectedInputFile"
                :state="Boolean(selectedInputFile)"
                :placeholder="$t('UPLOAD.placeHolder')"
                :drop-placeholder="$t('UPLOAD.dropPlaceholder')"
            ></b-form-file>
            </span><br>
            <span v-if="isReference === 0">
              <div class="text-right m-1" >
                <b-button v-if="!uploading && selectedInputFile != null" enabled variant="outline-primary" class="m-1" @click="uploadFile()">
                  <b-icon icon="cloud-upload" animation="throb"></b-icon> {{ $t('UPLOAD.btnUpload') }}
                </b-button>
                <b-button v-if="uploading" disabled variant="outline-primary" class="m-1" @click="uploadFile()">
                  <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon> {{ $t('UPLOAD.uploading') }}
                </b-button>
              </div>
            </span>
        </b-col>
        <b-col sm="12">
          <!-- Display some file data -->
          <div v-if="value!==null && parseFloat(value.split('|')[1]) > 0">
            <b-container fluid class="p-4 bg-light text-center">
              <!-- Render form_image as image if possible -->
              <b-img center thumbnail fluid :src="$store.state.serviceAPIUrl + 'workflow/image/preview/' + downloadTokenUrl" alt=""></b-img>
              <!-- Direct download - process files -->
              <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + downloadTokenUrl" target="_blank" class="font-weight-bold">
                <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{value.split('|')[2]}}&nbsp;&nbsp;&nbsp;( {{ Math.round( value.split('|')[1] / 1024) }} KB )
              </a>
            </b-container>
          </div>
        </b-col>
    </b-row>
  </div>
</template>

<script>
// Task editor item
import { arrayBufferToBase64 } from '../common/Utility'

export default {
  name: 'FileUpload',
  props: {
    workflowId: String,
    taskId: String,
    processId: String,
    isReference: Number,
    value: String, // for v-model: uuid|size|original name,
    downloadToken: String
  },
  components: {
  },
  data () {
    return {
      selectedInputFile: null,
      enableDownload: true,
      downloadTokenUrl: this.downloadToken,
      uploading: false
    }
  },
  methods: {
    async uploadFile () {
      if (this.selectedInputFile != null) {
        this.uploading = true

        var reader = new FileReader()

        reader.onloadend = async (e) => {
          // Callback that is called when finished the reading of the file

          // Call backend service
          try {
            const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/upload', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.$store.state.userToken,
                'Accept-Language': this.$i18n.locale
              },
              body: JSON.stringify({
                type: 'process_task',
                workflowId: this.workflowId,
                workflowTaskId: this.taskId,
                workflowProcessId: this.processId,
                fileName: this.selectedInputFile.name,
                fileContents: arrayBufferToBase64(reader.result)
              })
            })

            if (rawResponse.ok) {
              const resContent = await rawResponse.json()
              if (resContent.errorCode === 0) {
                // Upload ok

                // Build the v-model return value
                const newValue = resContent.fileId + '|' + resContent.fileSize + '|' + resContent.fileName
                this.$emit('input', newValue) // Send back the new value (implement v-model)
                this.enableDownload = false // The file has been uploaded, but the task is not yet saved, prevent an attempt to download it.
                this.selectedInputFile = null
                this.downloadTokenUrl = resContent.downloadToken
              } else {
                // An error occurred
                this.$bvToast.toast(resContent.errorDescription, {
                  title: this.$t('UPLOAD.errorTitle'),
                  autoHideDelay: 10000,
                  appendToast: false,
                  variant: 'danger',
                  solid: true,
                  toaster: 'b-toaster-top-full'
                })
              }
            }
            this.uploading = false
          } catch (err) {
            // this.errorDescription = err
            this.uploading = false
          }
        }
        reader.readAsArrayBuffer(this.selectedInputFile) // Start reading the local file
      }
    }
  },
  computed: {
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
