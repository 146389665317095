<template>
  <div class="bg-primary">
    <div class="p-1 p-md-3">
      <b-card bg-variant="secondary">
          <b-form-group
          label-cols-lg="3"
          :label="$t('USERS.userData')"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="m-1"
          >
            <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger">{{ errorDescription }}</b-alert></div>
            <div v-if="successMessage.length > 0"><br><b-alert show variant="success">{{ successMessage }}</b-alert></div>

            <b-form-group
                label-cols-sm="3"
                :label="$t('USERS.email')"
                label-align-sm="right"
                label-for="Email"
            >
                <b-form-input v-if="userObject.id" v-model="userObject.email" disabled trim required></b-form-input>
                <b-form-input v-else v-model="userObject.email" trim required></b-form-input>
            </b-form-group>

            <b-form-group v-if="!userObject.id"
                label-cols-sm="3"
                :label="$t('USERS.password')"
                label-align-sm="right"
                label-for="password"
            >
                <b-form-input v-model="userObject.password" type="password" class="my-1" :placeholder="$t('USERS.password')" trim required></b-form-input>
                <b-form-input v-model="passwordRepeat" type="password" class="my-1" :placeholder="$t('USERS.repeatPassword')" trim required></b-form-input>
            </b-form-group>

            <b-form-group
                label-cols-sm="3"
                :label="$t('USERS.firstName')"
                label-align-sm="right"
                label-for="FirstName"
            >
                <b-form-input v-model="userObject.firstName" trim required></b-form-input>
            </b-form-group>

            <b-form-group
                label-cols-sm="3"
                :label="$t('USERS.lastName')"
                label-align-sm="right"
                label-for="LastName"
            >
                <b-form-input v-model="userObject.lastName" trim required></b-form-input>
            </b-form-group>

            <b-form-group label="" class="text-right" >
              <b-form-checkbox
                  name="IsAdmin"
                  v-model="userObject.isAdmin"
                  value=1
                  unchecked-value=0
                  inline
                  >{{ $t('USERS.isAdmin') }}
              </b-form-checkbox>
              <b-form-checkbox
                  name="UserActive"
                  v-model="userObject.active"
                  value=1
                  unchecked-value=0
                  inline
                  >{{ $t('USERS.active') }}
              </b-form-checkbox>
              <br>
              <b-button v-if="userObject.id" variant="primary" v-on:click="showChangePassword=!showChangePassword">
                <b-icon icon="pencil"></b-icon> {{ $t('USERS.password') }} ...
              </b-button>

              <div v-if="showChangePassword" class="mt-3">
                <b-form-group
                    label-cols-sm="3"
                    :label="$t('USERS.newPassword')"
                    label-align-sm="right"
                    label-for="NewPassword"
                >
                  <b-form-input v-model="newPassword" type="password" placeholder="" trim></b-form-input>
                  <div style="text-align:right">
                    <b-button variant="success" class="m-1" v-on:click="changePassword()"><b-icon icon="arrow-right"></b-icon> {{ $t('USERS.btnChangePassword') }}</b-button>
                  </div>
                </b-form-group>
              </div>

            </b-form-group>

          </b-form-group>

          <div v-if="userObject.id" class="text-right">
            <b-button variant="danger" class="m-1" v-on:click="deleteUser()"><b-icon icon="trash"></b-icon> {{ $t('GLOBAL.delete') }}</b-button>
            <b-button variant="success" class="m-1" v-on:click="updateUser()"><b-icon icon="pencil"></b-icon> {{ $t('GLOBAL.update') }}</b-button>
          </div>
          <div v-else class="text-right">
            <b-button variant="success" class="m-1" v-on:click="insertUser()"><b-icon icon="plus"></b-icon> {{ $t('GLOBAL.insert') }}</b-button>
          </div>

      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'User',
  props: {
    userData: {
      type: Object,
      required: false,
      default: function () {
        return { id: '', email: '', password: '', firstName: '', lastName: '', isAdmin: 0, active: 1 }
      }
    },
    getUsers: {
    }
  },
  data () {
    return {
      errorDescription: '',
      successMessage: '',
      userObject: { ...this.userData }, // Copy the object
      passwordRepeat: '',
      showChangePassword: false,
      newPassword: ''
    }
  },
  methods: {
    async insertUser () {
      this.errorDescription = ''
      this.successMessage = ''

      // Check if password are the same
      if (this.userObject.password !== this.passwordRepeat) {
        this.errorDescription = this.$t('USERS.errorPasswordRepeat')
        return
      }

      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/data/', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify(this.userObject)
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.successMessage = this.$t('USERS.userInserted')
            this.userObject = { id: '', email: '', password: '', firstName: '', lastName: '', isAdmin: 0, active: 1 }
            this.passwordRepeat = ''
            setTimeout(this.getUsers, 3000) // Force a refresh (3s)
          } else {
            this.errorDescription = content.errorDescription
          }
        } else {
          this.errorDescription = this.$t('USERS.errorInsert')
        }
      } catch (err) {
        this.errorDescription = err
      }
    },
    async updateUser () {
      this.errorDescription = ''
      this.successMessage = ''
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/data/', {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify(this.userObject)
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.successMessage = this.$t('USERS.infoUpdated')
            setTimeout(() => {
              this.successMessage = ''
              this.getUsers()
            }, 3000)
          } else {
            this.errorDescription = content.errorDescription
          }
        } else {
          this.errorDescription = this.$t('USERS.errorUpdate')
        }
      } catch (err) {
        this.errorDescription = err
      }
    },
    async deleteUser () {
      this.errorDescription = ''
      this.successMessage = ''
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/data/' + this.userObject.id, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.successMessage = this.$t('USERS.infoDelete')
            setTimeout(this.getUsers, 3000) // Force a refresh (3s)
          } else {
            this.errorDescription = content.errorDescription
          }
        } else {
          this.errorDescription = this.$t('USERS.errorDelete')
        }
      } catch (err) {
        this.errorDescription = err
      }
    },
    async changePassword () {
      this.errorDescription = ''
      this.successMessage = ''

      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/changepassword', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ email: this.userData.email, password: this.newPassword })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.successMessage = this.$t('USERS.infoPasswordChanged')
            setTimeout(() => {
              this.successMessage = ''
              this.showChangePassword = false
              this.newPassword = ''
            }, 5000) // Remove message after some time
          } else {
            this.errorDescription = content.errorDescription
            setTimeout(() => { this.errorDescription = '' }, 5000) // Remove message after some time
          }
        } else {
          this.errorDescription = this.$t('USERS.errorChangePassword')
        }
      } catch (err) {
        this.errorDescription = err
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
