<template>
  <div>
      <b-icon :icon="name"></b-icon>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {}
  }
}
</script>

<style scoped>
</style>
