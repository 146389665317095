import Vue from 'vue'
import Vuex from 'vuex'
import { uuid } from 'vue-uuid'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    serviceAPIUrl: process.env.VUE_APP_SERVICEAPIURL, // process.env.VUE_APP_SERVICEAPIURL, 'E.g.: http://svcdev.digiwi.app:8080/'
    userData: [], // User data received upon login
    userToken: '', // The token to be used to call secured API,
    /* Workflow designer objects */
    designerGraphModelJson: null,
    designerGraphModelXml: null,
    designerBaseData: { id: '', originatorId: '', name: '', description: '' }, // designer workflow basic data
    designerSwimlanes: {}, // Swimlane definitions, with associated users
    designerAttachments: {}, // designer Attachments
    designerTasks: {}, // designer workflow task list
    designerDecisions: {}, // designer workflow decision list
    designerEmails: {}, // designer workflow emails list
    designerReports: {}, // designer workflow reports list
    designerApprovals: {}, // designer workflow approval list - TODO eventi
    designerProcesses: {}, // designer workflow processes (to run another workflow)
    /* Task edit */
    currentTask: {}, // keys: workflow, task, process, fields, attachments
    currentApproval: {}, // processId, approvalId, ...
    nextTasksData: { processId: null, rootProcessId: null, sourceObject: '', sourcePage: '' }, // For usage in NextTasksPage (set before entering)

    /* Process page filters and paging */
    processPageParameters: {
      showFilter: false, // Show / hide filter
      filter: null, // Filter
      fromDate: '', // Search
      toDate: '', // Search
      status: null, // Search
      workflowId: '', // Search
      process: '', // Search
      includeSubProcesses: 1, // Search
      totalRows: 1, // Paging
      currentPage: 1, // Paging
      perPage: 10, // Paging
      pageOptions: [5, 10, 15, 100] // Paging
    },
    /* My task page filters and paging */
    mytaskspageParameters: {
      showFilter: false, // Show / hide filter
      fromDate: '', // Search (used for tasks and approvals)
      toDate: '', // Search (used for tasks and approvals)
      workflowId: '', // Search (used for tasks and approvals)
      process: '', // Search (used for tasks and approvals)
      status: null, // Search (used for tasks)
      filterTask: null, // Filter for tasks
      totalRowsTask: 1, // Paging for tasks
      currentPageTask: 1, // Paging for tasks
      perPageTask: 5, // Paging for tasks
      pageOptionsTask: [5, 10, 15, 100], // Paging for tasks
      filterApproval: null, // Filter for tasks
      totalRowsApproval: 1, // Paging for approvals
      currentPageApproval: 1, // Paging for approvals
      perPageApproval: 5, // Paging for approvals
      pageOptionsApproval: [5, 10, 15, 100] // Paging for approvals
    },
    /* Saved workflows page filters and paging */
    savedWorkflowsPageParameters: {
      filter: null, // Filter
      totalRows: 1, // Paging
      currentPage: 1, // Paging
      perPage: 10, // Paging
      pageOptions: [5, 10, 15, 100] // Paging
    }
  },
  mutations: {
    setUserData (state, userData) {
      state.userData = userData
    },
    setUserToken (state, token) {
      state.userToken = token
    },
    /* *********************     Workflow designer mutations     **********************/
    designerReset (state) {
      // Reset all the data related to the designer
      state.designerGraphModelJson = null
      state.designerGraphModelXml = null
      var newId = uuid.v4() // Workflow identifier
      state.designerBaseData = { id: newId, originatorId: newId, name: '', description: '', categoryId: null }
      state.designerSwimlanes = {}
      state.designerAttachments = {}
      state.designerTasks = {}
      state.designerDecisions = {}
      state.designerApprovals = {}
      state.designerEmails = {}
      state.designerReports = {}
      state.designerProcesses = {}
    },
    designerGraphModelJsonSet (state, designerGraphModelJson) {
      state.designerGraphModelJson = designerGraphModelJson
    },
    designerGraphModelXmlSet (state, designerGraphModelXml) {
      state.designerGraphModelXml = designerGraphModelXml
    },
    designerBaseData (state, baseData) {
      state.designerBaseData = { ...baseData } // Recreate the list
    },
    designerSwimlaneSet (state, swimlane) {
      Vue.set(state.designerSwimlanes, swimlane.id, { ...swimlane }) // Replace single swimlane, id is the key
    },
    designerSwimlaneRemove (state, id) {
      Vue.delete(state.designerSwimlanes, id) // Remove swimlane
    },
    designerSwimlaneUserInsert (state, swimlaneUser) {
      /* Insert a new user into the swimlane */
      Vue.set(state.designerSwimlanes[swimlaneUser.swimlaneId].users, swimlaneUser.userId, 1)
    },
    designerSwimlaneUserRemove (state, swimlaneUser) {
      /* Remove a user from swimlane */
      Vue.delete(state.designerSwimlanes[swimlaneUser.swimlaneId].users, swimlaneUser.userId)
    },
    designerSwimlaneGroupInsert (state, swimlaneGroup) {
      /* Insert a new group into the swimlane */
      Vue.set(state.designerSwimlanes[swimlaneGroup.swimlaneId].groups, swimlaneGroup.groupId, 1)
    },
    designerSwimlaneGroupRemove (state, swimlaneGroup) {
      /* Remove a group from swimlane */
      Vue.delete(state.designerSwimlanes[swimlaneGroup.swimlaneId].groups, swimlaneGroup.groupId)
    },
    designerTaskSet (state, task) {
      Vue.set(state.designerTasks, task.id, { ...task }) // Replace single task, id is the key
    },
    designerTaskRemove (state, id) {
      Vue.delete(state.designerTasks, id) // Remove task
    },
    designerTaskItemSet (state, taskItem) {
      /* Update (or insert) a single task item (number, text, ...)
         Pass in object payload as:
          taskItem.taskId,
          taskItem.itemId
          taskItem.itemValue = {...}
      */
      Vue.set(state.designerTasks[taskItem.taskId].items, taskItem.itemId, { ...taskItem.itemValue }) // Recreate it
    },
    designerTaskItemMove (state, taskItem) {
      // Move up or down the task component. Basically we have to switch the displayOrder of two elements
      const taskDisplayOrder = taskItem.itemValue.displayOrder
      let taskItemNearest = null

      // Find the smallest greater or the bigger smallest compared to this
      for (const k in state.designerTasks[taskItem.taskId].items) {
        const displayOrder = state.designerTasks[taskItem.taskId].items[k].displayOrder

        if (taskItem.moveUp) { // Move up
          if (displayOrder < taskDisplayOrder) { // It's before?
            if (taskItemNearest != null) {
              if (state.designerTasks[taskItem.taskId].items[taskItemNearest].displayOrder < displayOrder) {
                taskItemNearest = k // Replace task with this, because is bigger
              }
            } else {
              taskItemNearest = k
            }
          }
        } else { // Move down
          if (displayOrder > taskDisplayOrder) { // It's after?
            if (taskItemNearest != null) {
              if (state.designerTasks[taskItem.taskId].items[taskItemNearest].displayOrder > displayOrder) {
                taskItemNearest = k // Replace task with this, because is smaller
              }
            } else {
              taskItemNearest = k
            }
          }
        }
      }

      // Switch them
      if (taskItemNearest != null) {
        const currentTask = { ...taskItem.itemValue }
        currentTask.displayOrder = state.designerTasks[taskItem.taskId].items[taskItemNearest].displayOrder

        const otherTask = { ...state.designerTasks[taskItem.taskId].items[taskItemNearest] }
        otherTask.displayOrder = taskDisplayOrder

        Vue.set(state.designerTasks[taskItem.taskId].items, taskItem.itemId, currentTask)
        Vue.set(state.designerTasks[taskItem.taskId].items, taskItemNearest, otherTask)
      }
    },
    designerTaskItemRemove (state, taskItem) {
      Vue.delete(state.designerTasks[taskItem.taskId].items, taskItem.itemId)
    },
    designerDecisionSet (state, decision) {
      Vue.set(state.designerDecisions, decision.id, { ...decision }) // Replace single decision, id is the key
    },
    designerDecisionRemove (state, id) {
      Vue.delete(state.designerDecisions, id) // Remove decision
    },
    designerDecisionRuleSet (state, decisionRule) {
      Vue.set(state.designerDecisions[decisionRule.decisionId].rules, decisionRule.ruleId, { ...decisionRule.ruleValue }) // Recreate it
    },
    designerDecisionRuleProcessSet (state, decisionRule) {
      Vue.set(state.designerDecisions[decisionRule.decisionId].processes, decisionRule.processId, { ...decisionRule.ruleValue }) // Recreate it
    },
    designerApprovalSet (state, approval) {
      Vue.set(state.designerApprovals, approval.id, { ...approval }) // Replace single approval, id is the key
    },
    designerApprovalRemove (state, id) {
      Vue.delete(state.designerApprovals, id) // Remove approval
    },
    designerProcessSet (state, process) {
      Vue.set(state.designerProcesses, process.id, { ...process }) // Replace process
    },
    designerProcessRemove (state, id) {
      Vue.delete(state.designerProcesses, id) // Remove process
    },
    designerEmailSet (state, email) {
      Vue.set(state.designerEmails, email.id, { ...email }) // Replace single email, id is the key
    },
    designerEmailRemove (state, id) {
      Vue.delete(state.designerEmails, id) // Remove mail
    },
    designerReportSet (state, report) {
      Vue.set(state.designerReports, report.id, { ...report }) // Replace single report, id is the key
    },
    designerReportRemove (state, id) {
      Vue.delete(state.designerReports, id) // Remove report
    },
    designerAttachmentsSet (state, attachment) {
      Vue.set(state.designerAttachments, attachment.id, { ...attachment }) // Replace single attachment, id is the key
    },
    designerAttachmentsRemove (state, id) {
      Vue.delete(state.designerAttachments, id) // Remove attachment
    },
    /* *********************     Task edit mutations     **********************/
    currentTaskSet (state, currentTask) {
      state.currentTask = {} // Reset first workflow, task, process, fields
      Vue.set(state.currentTask, 'workflow', { ...currentTask.workflow })
      Vue.set(state.currentTask, 'task', { ...currentTask.task })
      Vue.set(state.currentTask, 'process', { ...currentTask.process })
      Vue.set(state.currentTask, 'fields', [...currentTask.fields])
      Vue.set(state.currentTask, 'attachments', [...currentTask.attachments])
    },
    currentApprovalSet (state, currentApproval) {
      state.currentApproval = { ...currentApproval } // Current approval
    },
    updateProcessPageParameters (state, newParameters) {
      state.processPageParameters = { ...newParameters }
    },
    updateMytaskspageParameters (state, newParameters) {
      state.mytaskspageParameters = { ...newParameters }
    },
    updateSavedWorkflowsPageParameters (state, newParameters) {
      state.savedWorkflowsPageParameters = { ...newParameters }
    },
    setNextTasksData (state, nextTasksData) {
      state.nextTasksData = { ...nextTasksData } // { processId: null, rootProcessId: null, sourceObject: '', sourcePage: '' }
    }
  },
  actions: {
  },
  modules: {
  }
})
