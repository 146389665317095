<template>
  <div class="decisionEditorItem">
    <b-row>
      <b-col align="right"><b-icon icon="square-fill" v-bind:style="{ color: ruleGroupColors[ruleCopy.ruleGroup] }"></b-icon>&nbsp;
      Rule <b-icon icon="arrow-right" style="color:green"></b-icon></b-col>
      <b-col>
        <!-- Variables -->
        <b-form-select v-model="ruleVariable" :options="listOfAllVariables" @input="fillFunctions"></b-form-select>
      </b-col>
      <b-col>
        <!-- Functions -->
        <b-form-select v-model="ruleFunction" :options="availableFunctions" ></b-form-select>
      </b-col>
      <b-col>
        <!-- Value to test -->
        <b-form-group v-if="currentRuleType.length > 0" label="">
           <!-- With a manual entry -->
          <b-form-radio v-if="['number', 'text', 'date','list','boolean'].includes(currentRuleType)"
                        v-model="ruleVariableType"
                        :name="'valuetype' + rule.id" value="M">
            <div>
              <b><i>{{ $t('DESIGNER.decision.manualValue') }}</i></b>
              <b-form-input type="number" v-model="ruleValueManual" v-if="currentRuleType === 'number'" step="0.0001"></b-form-input>
              <b-form-input type="text" v-model="ruleValueManual" v-if="currentRuleType === 'text'" trim></b-form-input>
              <b-form-input type="text" v-model="ruleValueManual" v-if="currentRuleType === 'list'" trim></b-form-input>
              <b-form-datepicker v-if="currentRuleType === 'date'"
                v-model="ruleValueManual" class="mb-2"
                today-button
                reset-button
                close-button
                :show-decade-nav="true"
                :locale="$i18n.locale">
              </b-form-datepicker>
              <b-form-group v-if="currentRuleType === 'boolean'" label="">
                <b-form-radio v-model="ruleValueManual" name="some-radios" value="0">{{ $t('DESIGNER.decision.false') }}</b-form-radio>
                <b-form-radio v-model="ruleValueManual" name="some-radios" value="1">{{ $t('DESIGNER.decision.true') }}</b-form-radio>
              </b-form-group>
            </div>
          </b-form-radio>
          <!-- or usign another variable of the same type -->
          <b-form-radio v-if="Object.keys(listOfAllVariablesByType).length > 0"
                        v-model="ruleVariableType"
                        :name="'valuetype' + rule.id" value="V">
            <div>
              <b><i>{{ $t('DESIGNER.decision.anotherVariable') }}</i></b>
              <b-form-select v-model="ruleValueVariable" :options="listOfAllVariablesByType"></b-form-select>
            </div>
          </b-form-radio>
        </b-form-group>
      </b-col>
       <b-col>
        <!-- Group -->
        <b-row>
          <b-col>
            <b-form-select v-bind:style="{ borderColor: ruleGroupColors[ruleCopy.ruleGroup] }" v-model="ruleGroup" class="mb-3">
              <b-form-select-option :value="null">{{ $t('DESIGNER.decision.selectGroup') }}</b-form-select-option>
              <b-form-select-option value="1">1</b-form-select-option>
              <b-form-select-option value="2">2</b-form-select-option>
              <b-form-select-option value="3">3</b-form-select-option>
              <b-form-select-option value="4">4</b-form-select-option>
              <b-form-select-option value="5">5</b-form-select-option>
              <b-form-select-option value="6">6</b-form-select-option>
              <b-form-select-option value="7">7</b-form-select-option>
              <b-form-select-option value="8">8</b-form-select-option>
              <b-form-select-option value="9">9</b-form-select-option>
              <b-form-select-option value="10">10</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
          <!-- Delete -->
        <b-button href="#" variant="primary" @click="removeRule()"><b-icon icon="trash"></b-icon></b-button>
      </b-col>
    </b-row>
  </div>

</template>

<script>
// Decision editor item (one for each condition).

export default {
  name: 'DecisionEditorItem',
  props: {
    decisionId: String,
    rule: Object, // Task component
    removeCallback: Function
  },
  components: {
  },
  data () {
    return {
      availableFunctions: [],
      ruleCopy: { ...this.rule },
      currentRuleType: '',
      ruleGroupColors: {
        1: '#0078d7',
        2: '#ffb900',
        3: '#e81123',
        4: '#f7630c',
        5: '#8e8cd8',
        6: '#00b7c3',
        7: '#00cc6a',
        8: '#847545',
        9: '#498205',
        10: '#9a0089'
      }
    }
  },
  methods: {
    updateRuleField (name, value) {
      const copyOfData = { ...this.ruleCopy }
      copyOfData[name] = value

      const obj = {
        decisionId: this.decisionId,
        ruleId: this.rule.id,
        ruleValue: copyOfData
      }
      this.$store.commit('designerDecisionRuleSet', obj)
    },
    fillFunctions () {
      // alert('changeVariable: ')
      // Reload the list of available functions according to variable type
      var selectedValue = null
      var newAvailableFunctions = []
      newAvailableFunctions.push({ value: null, text: this.$t('DESIGNER.decision.selectFunction'), disabled: true })

      this.listOfAllVariables.forEach((value, index, array) => {
        if (value.value === this.ruleCopy.ruleVariable) {
          selectedValue = value
        }
      })

      this.currentRuleType = ''
      if (selectedValue) {
        this.currentRuleType = selectedValue.type

        switch (selectedValue.type) {
          case 'text':
            newAvailableFunctions.push({ value: 'eq', text: this.$t('DESIGNER.decision.function.text_eq') })
            newAvailableFunctions.push({ value: 'ne', text: this.$t('DESIGNER.decision.function.text_ne') })
            newAvailableFunctions.push({ value: 'contain', text: this.$t('DESIGNER.decision.function.text_contain') })
            newAvailableFunctions.push({ value: 'notcontain', text: this.$t('DESIGNER.decision.function.text_notcontain') })
            newAvailableFunctions.push({ value: 'start', text: this.$t('DESIGNER.decision.function.text_start') })
            newAvailableFunctions.push({ value: 'end', text: this.$t('DESIGNER.decision.function.text_end') })
            break
          case 'list':
            newAvailableFunctions.push({ value: 'eq', text: '=' })
            newAvailableFunctions.push({ value: 'ne', text: '<>' })
            break
          case 'number':
            newAvailableFunctions.push({ value: 'eq', text: '=' })
            newAvailableFunctions.push({ value: 'ne', text: '<>' })
            newAvailableFunctions.push({ value: 'lt', text: '<' })
            newAvailableFunctions.push({ value: 'lte', text: '<=' })
            newAvailableFunctions.push({ value: 'gt', text: '>' })
            newAvailableFunctions.push({ value: 'gte', text: '>=' })
            break
          case 'boolean':
            newAvailableFunctions.push({ value: 'eq', text: '=' })
            newAvailableFunctions.push({ value: 'ne', text: '<>' })
            break
          case 'date':
            newAvailableFunctions.push({ value: 'eq', text: '=' })
            newAvailableFunctions.push({ value: 'ne', text: '<>' })
            newAvailableFunctions.push({ value: 'lt', text: '<' })
            newAvailableFunctions.push({ value: 'lte', text: '<=' })
            newAvailableFunctions.push({ value: 'gt', text: '>' })
            newAvailableFunctions.push({ value: 'gte', text: '>=' })
            break
          default:
            // code block
        }
      }

      // Replace list of functions
      this.$nextTick(() => {
        this.availableFunctions = newAvailableFunctions
      })

      // Reset the selection of the function if not existing
      this.$nextTick(() => {
        if (newAvailableFunctions.filter((e) => {
          return e.value === this.ruleCopy.ruleFunction
        }).length === 0) {
          this.ruleFunction = null
        }
      })
    },
    removeRule () {
      this.removeCallback(this.rule.id) // Remove this rule (remove me)
    }
  },
  computed: {
    listOfAllVariables: {
      get () {
        // Retrieve the list of all the variables used in all tasks
        var allVariables = []
        for (const task in this.$store.state.designerTasks) {
          const taskName = this.$store.state.designerTasks[task].name
          for (const item in this.$store.state.designerTasks[task].items) {
            const myVar = this.$store.state.designerTasks[task].items[item]
            // Display only if we have form name and variable name set and a valid type
            if (taskName.length > 0 && myVar.name.length > 0 && ['number', 'text', 'date', 'boolean', 'list'].includes(myVar.type)) {
              allVariables.push({ value: myVar.id, text: '[' + [taskName] + '].' + myVar.name + ' (' + myVar.type + ')', type: myVar.type })
            }
          }
        }
        return allVariables
      }
    },
    listOfAllVariablesByType: {
      // Return the list of variables with the same "current type" (e.g. "number")
      get () {
        return this.listOfAllVariables.filter((e) => {
          return e.type === this.currentRuleType && e.value !== this.ruleCopy.ruleVariable
        })
      }
    },
    ruleVariable: {
      get () {
        return this.rule.ruleVariable
      },
      set (value) {
        this.updateRuleField('ruleVariable', value)
        this.ruleCopy.ruleVariable = value
      }
    },
    ruleFunction: {
      get () {
        return this.rule.ruleFunction
      },
      set (value) {
        this.updateRuleField('ruleFunction', value)
        this.ruleCopy.ruleFunction = value
      }
    },
    ruleVariableType: {
      get () {
        return this.rule.ruleVariableType
      },
      set (value) {
        this.updateRuleField('ruleVariableType', value)
        this.ruleCopy.ruleVariableType = value
      }
    },
    ruleValueManual: {
      get () {
        return this.rule.ruleValueManual
      },
      set (value) {
        this.updateRuleField('ruleValueManual', value)
        this.ruleCopy.ruleValueManual = value
      }
    },
    ruleValueVariable: {
      get () {
        return this.rule.ruleValueVariable
      },
      set (value) {
        this.updateRuleField('ruleValueVariable', value)
        this.ruleCopy.ruleValueVariable = value
      }
    },
    ruleGroup: {
      get () {
        return this.rule.ruleGroup
      },
      set (value) {
        this.updateRuleField('ruleGroup', value)
        this.ruleCopy.ruleGroup = value
      }
    }
  },
  mounted () {
    // Force an initial load of the functions
    this.fillFunctions()
  },
  beforeDestroy () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.decisionEditorItem {
  padding: 10px 5px;
  border-top: 1px solid rgb(231, 222, 222);
  border-bottom: 1px solid rgb(231, 222, 222);
}
</style>
