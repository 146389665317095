<template>
    <b-container class="myContainer">
    <b-row>
        <b-col style="text-align:center">
            <br><b-img :src="require('../assets/LogoLG.png')" fluid alt=""></b-img>
        </b-col>
    </b-row>
    <b-row><b-col style="text-align:center"><h2 class="bg-secondary text-primary">{{ $t('REGISTER.signUpTitle') }}</h2><br></b-col></b-row>
    <b-row>
      <b-col sm="12" md="2"></b-col>
      <b-col sm="12" md="8" style="text-align:left">
        <br>
        <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger" dismissible fade>
            {{ $t('REGISTER.errorSignUp') }} <br> {{ errorDescription }}</b-alert>
        </div>
        <div v-if="displayMessage.length > 0"><br><b-alert show variant="success" dismissible fade>{{ displayMessage }}</b-alert>
          <div v-if="displayMessage !== this.$t('REGISTER.verificationCodeSuccess')" class="text-right"><b-link :to="'login'"><b>{{ $t('REGISTER.returnToLogin.2') }}</b></b-link></div>
        </div>
      </b-col>
    </b-row>
    <b-row>
        <b-col sm="12" md="3"></b-col>
        <b-col sm="12" md="6" style="text-align:left">
          <div><LanguageSelector></LanguageSelector></div>
          <b-form @submit="doRegister">
            <b-form-group>
              <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.Email') }}</b></div>
              <b-form-input v-model="form.email" type="email" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.firstName') }}</b></div>
              <b-form-input v-model="form.firstName" type="text" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.lastName') }}</b></div>
              <b-form-input v-model="form.lastName" type="text" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.tenantName') }}</b></div>
              <b-form-input v-model="form.accountName" type="text" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.phoneNumber') }}</b></div>
              <b-form-input v-model="form.phone" type="text" placeholder="" trim></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.newPassword') }}</b></div>
              <b-form-input v-model="form.password" type="password" placeholder="" trim required></b-form-input>
              <br>
              <b-row>
                <b-col>
                  <div class="text-primary my-0 p-0"><b>{{ $t('REGISTER.verificationCode') }}</b></div>
                  <b-form-input v-model="form.verificationCode" type="text" placeholder="" class="bg-light" trim required></b-form-input>
                </b-col>
                <b-col>
                  <br>
                  <b-button fluid v-if="form.email.length > 0" type="button" @click="sendVerificationCode" variant="secondary" block >
                    <div><b-icon icon="envelope-fill"></b-icon> {{ $t('REGISTER.btnVerificationCode') }}</div>
                  </b-button>
                </b-col>
              </b-row>
              <br>
              <br>
              <div>{{ $t('REGISTER.registerNote.1') }} <b-link href="#">{{ $t('REGISTER.registerNote.2') }}</b-link> {{ $t('REGISTER.registerNote.3') }} <b-link href="#">{{ $t('REGISTER.registerNote.4') }}</b-link>.</div>
            </b-form-group>
             <b-button fluid v-if="!loading" type="submit" variant="primary" block >{{ $t('REGISTER.btnSignUp') }}</b-button>
             <div v-if="loading" style="text-align: center"><br><b-spinner label="Spinning"></b-spinner> {{ $t('REGISTER.signingUp') }}</div>
          </b-form>
        </b-col>
        <b-col sm="12" md="3"></b-col>
    </b-row>
    <b-row>
      <b-col><br>{{ $t('REGISTER.returnToLogin.1') }} <b-link :to="'login'"><b>{{ $t('REGISTER.returnToLogin.2') }}</b></b-link><br><br></b-col>
    </b-row>
    </b-container>
</template>

<script>
import LanguageSelector from './common/LanguageSelector'

export default {
  name: 'RegisterPage',
  data () {
    return {
      form: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        accountName: '',
        phone: '',
        verificationCode: ''
      },
      errorDescription: '',
      displayMessage: '',
      status: 'not_accepted',
      loading: false
    }
  },
  components: {
    LanguageSelector
  },
  mounted () {
    // Ping the service
    fetch(this.$store.state.serviceAPIUrl + '/ping?fromPage=RegisterPage', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Accept-Language': this.$i18n.locale
      }
    })
  },
  methods: {
    async sendVerificationCode () {
      this.errorDescription = ''
      this.displayMessage = ''
      await this.$nextTick()
      try {
        // Send verification code (for email validation)
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'tenant/sendverificationcode', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ email: this.form.email })
        })
        // Get the json contents
        const content = await rawResponse.json()
        if (content.errorCode === 0) {
          // successful
          this.displayMessage = this.$t('REGISTER.verificationCodeSuccess')
        } else {
          // failure (invalid data)
          // this.errorDescription = content.errorDescription
        }
      } catch (err) {
        // connection problems
        this.errorDescription = err
      }
    },
    async doRegister (evt) {
      // Do the authentication
      evt.preventDefault()
      this.errorDescription = ''
      this.displayMessage = ''
      this.loading = true
      await this.$nextTick()

      try {
        // Login with user and password
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'tenant/register', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify(this.form)
        })

        // Get the json contents
        const content = await rawResponse.json()
        // Complete the credentials with the token if login successful
        if (content.errorCode === 0) {
          // Registration successful
          this.displayMessage = this.$t('REGISTER.signUpSuccess')
          // Reset fields (all except email)
          this.form.firstName = ''
          this.form.lastName = ''
          this.form.accountName = ''
          this.form.phone = ''
          this.form.verificationCode = ''
        } else {
          // Registration failure (invalid data)
          this.errorDescription = content.errorDescription
        }
      } catch (err) {
        // connection problems
        this.errorDescription = err
      }

      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.myContainer {
  border: 1px solid var(--primary);
  margin-top:1em;
  margin-bottom:1em;
}
h1, h2 {
  font-weight: normal;
  color: var(--primary);
}
.imageTitle {
  font-size: 30px;
  font-weight: bold;
  color: var(--primary);
}
</style>
