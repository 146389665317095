<template>
  <div style="text-align: left;">
    <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger" dismissible fade>{{ errorDescription }}</b-alert></div>
    <b-row>
      <b-col md="6">
        <h1 style="display:inline"><b-icon icon="person-fill" style="color: #ff7725;"></b-icon> {{ $t('USERS.title') }}
        </h1>
        <h4 class="ml-2" style="display:inline">{{ $t('USERS.actives') }}: {{ activeUsers }} - {{ $t('USERS.inactive') }}: {{ inactiveUsers }} ({{ $t('USERS.max') }}: {{ this.$store.state.userData.max_users }})
        </h4>
        <b-icon class="ml-4 my-1"
            :icon="showNewUserIcon"
            variant="success"
            scale="2"
            @click="showNewUser = !showNewUser;showNewUser ? showNewUserIcon='dash-circle-fill' : showNewUserIcon='plus-circle-fill';">
        </b-icon>
      </b-col>
      <b-col md="6" class="text-right">
        <!-- Table filter -->
        <b-form-group
          :label="$t('GLOBAL.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-2 mt-4 text-primary font-weight-bold"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder=""
              style="background-color: #f9e49d"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{ $t('GLOBAL.clear') }}</b-button>
            </b-input-group-append>
            <b-button class="ml-2 py-0" variant="primary" v-on:click="getUsers()"><b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('USERS.reload') }}</b-button>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="showNewUser"><!-- New user -->
      <User :getUsers="getUsers"></User>
      <br>
    </div>
    <div v-if="!loading">
      <div v-if="userList.length > 0" class="mt-2">
        <!-- User list -->
        <b-table striped hover stacked='md'
          outlined bordered
          head-variant="dark"
          :items="userList"
          :fields="userFields"
          :filter="filter"
        >
          <!-- Headers -->
          <template #head(email)>{{ $t('USERS.email') }}</template>
          <template #head(firstName)>{{ $t('USERS.firstName') }}</template>
          <template #head(lastName)>{{ $t('USERS.lastName') }}</template>
          <template #head(isAdmin)>{{ $t('USERS.isAdmin') }}</template>
          <template #head(active)>{{ $t('USERS.active') }}</template>
          <template #head(tableActions)>{{ $t('GLOBAL.tableActions') }}</template>

          <!-- Values -->
          <template v-slot:cell(email)="data">
            <p class="text-primary font-weight-bold text-break">{{ data.item.email }}</p>
          </template>
          <template v-slot:cell(isAdmin)="data">
            <b-form-checkbox
                v-model="data.item.isAdmin"
                value=1
                unchecked-value=0
                inline
                disabled
                >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(active)="data">
            <b-form-checkbox
                v-model="data.item.active"
                value=1
                unchecked-value=0
                inline
                disabled
                >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(actions)="data"><!-- Show / hide version list -->
            <div class="text-right">
              <b-button size="sm" @click="data.toggleDetails" class="mr-2" variant="primary">
                <b-icon icon="pencil-square"></b-icon> {{ data.detailsShowing ? $t('GLOBAL.hideDetails') : $t('GLOBAL.showDetails') }}
              </b-button>
            </div>
          </template>
          <template #row-details="data"><!-- Template for details -->
            <User :userData="data.item" :getUsers="getUsers"></User>
          </template>
        </b-table>
      </div>
      <div v-else><br><h2>-- {{ $t('USERS.notFound') }} --</h2></div>
    </div>
    <div v-else><br><b-spinner label="Spinning"></b-spinner> {{ $t('GLOBAL.loading') }}</div>
  </div>
</template>

<script>
import User from './UserComponent.vue'

export default {
  name: 'ManageUsersPage',
  components: {
    User
  },
  data () {
    return {
      userList: [],
      errorDescription: '',
      loading: true,
      showNewUser: false,
      showNewUserIcon: 'plus-circle-fill',
      userFields: [{
        key: 'email',
        label: this.$t('USERS.email'),
        sortable: true
      },
      {
        key: 'firstName',
        label: this.$t('USERS.firstName'),
        sortable: true
      },
      {
        key: 'lastName',
        label: this.$t('USERS.lastName'),
        sortable: true
      },
      {
        key: 'isAdmin',
        label: this.$t('USERS.isAdmin'),
        sortable: true
      },
      {
        key: 'active',
        label: this.$t('USERS.active'),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('GLOBAL.tableActions'),
        sortable: false
      }],
      filter: null
    }
  },
  computed: {
    activeUsers: function () {
      // Retrieve the number of active users
      const initialValue = 0
      const res = this.userList.reduce((count, currentValue) => {
        return count + currentValue.active
      }, initialValue)

      return res
    },
    inactiveUsers: function () {
      // Retrieve the number of inactive users
      return this.userList.length - this.activeUsers
    }
  },
  mounted () {
    // Force a load of the users
    this.loading = true
    this.getUsers()
  },
  methods: {
    async getUsers () {
      // Retrieve the list of users
      this.errorDescription = ''
      this.showNewUser = false
      this.showNewUserIcon = 'plus-circle-fill'
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/data/', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.userList = content
        } else {
          // this.errorDescription = this.$t('USERS.error')
          this.$bvToast.toast(this.$t('USERS.error'), {
            title: this.$t('USERS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
          this.userList = []
        }
      } catch (err) {
        this.errorDescription = err
        this.userList = []
      }

      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
