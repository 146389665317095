<template>
  <div>
    <h4>{{ $t('DESIGNER.email.task') }}: {{ theData.name }}</h4>
    <br>
    <!-- Send to -->
    <div id="mailbodyplaceholder">
      <b-row>
        <!-- Swimlane users -->
        <b-col sm="12" md="12" lg="6">
          <h4><b-icon icon="people-fill" style="color:blue"></b-icon> {{ $t('DESIGNER.email.sendTo') }}</h4>
          <b-form-group
                label-cols-sm="4"
                :label="$t('DESIGNER.email.swimlane')"
                label-align-sm="left"
            >
            <b-form-checkbox
              v-model="sendToSwimlane"
              value=1
              unchecked-value=0
            >
              {{ $t('DESIGNER.email.sendToAll') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Task user -->
        <b-col sm="12" md="12" lg="8">
          <b-form-group
                label-cols-sm="3"
                :label="$t('DESIGNER.email.sendToUserOfTask')"
                label-align-sm="left"
            >
              <b-form-select v-model="sendToUserTask" :options="listOfAllTasks"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="8">
          <b-form-group
                label-cols-sm="3"
                :label="$t('DESIGNER.email.addressList')"
                label-align-sm="left"
            >
                <b-form-input v-model="sendToAddressList" type="text" :placeholder="$t('DESIGNER.email.addressListPlaceholder')" trim></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <br>
    </div>
    <br>
    <hr>
    <br>
    <!-- Fields -->
    <b-row>
      <!-- Task user -->
      <b-col sm="12" md="12" lg="8">
        <b-form-group
              label-cols-sm="3"
              :label="$t('DESIGNER.email.availableFields')"
              label-align-sm="left"
          >
              <b-form-select v-model="currentSelectedField" :options="listOfAllFields"></b-form-select>
        </b-form-group>
        <br>
      </b-col>
      <b-col sm="12" md="12" lg="4">
        <b-button v-if="currentSelectedField" variant="primary" v-on:click="addFieldToSubject()">{{ $t('DESIGNER.email.btnAddSubject') }} <b-icon icon="forward-fill"></b-icon></b-button>
        <br><br>
        <b-button v-if="currentSelectedField" variant="primary" v-on:click="addFieldToBody()">{{ $t('DESIGNER.email.btnAddBody') }} <b-icon icon="forward-fill"></b-icon></b-button>
      </b-col>
    </b-row>
    <!-- Mail text -->
    <br>
    <b-row>
      <b-col sm="12" md="12" lg="8">
        <div id="mailbodyplaceholder">
          <h4><b-icon icon="envelope-fill" style="color:brown"></b-icon> {{ $t('DESIGNER.email.subject') }}</h4>
          <b-form-input v-model="subject" type="text" placeholder="" trim required></b-form-input>
          <br>
          <h4><b-icon icon="file-text"></b-icon> {{ $t('DESIGNER.email.body') }}</h4>
          <EditorHtml v-if="forceRenderComponentBody" v-model="body"></EditorHtml>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EditorHtml from '../common/EditorHtml'

// Form editor
export default {
  name: 'EmailEditor',
  props: {
    theData: Object
  },
  components: {
    EditorHtml
  },
  data () {
    return {
      currentSelectedField: null,
      forceRenderComponentBody: true // To force a reload of the body component after adding a field component.
    }
  },
  methods: {
    addFieldToSubject () {
      if (this.currentSelectedField) {
        this.subject = this.subject + ' ' + this.currentSelectedField
      }
    },
    addFieldToBody () {
      if (this.currentSelectedField) {
        this.body = this.body + ' ' + this.currentSelectedField

        this.forceRenderComponentBody = false
        this.$nextTick().then(() => {
          // Add the component back in (it force the re-rendering)
          this.forceRenderComponentBody = true
        })
      }
    },
    adjustFieldNames (inputValue) {
      // Try to adapt the name of task and field (only for display purposes)
      let val = inputValue
      for (const task in this.$store.state.designerTasks) {
        for (const item in this.$store.state.designerTasks[task].items) {
          let finalValue = '{{ [' + task
          finalValue += '|' + this.$store.state.designerTasks[task].items[item].id + ']'
          finalValue += '[' + this.$store.state.designerTasks[task].name
          finalValue += '|' + this.$store.state.designerTasks[task].items[item].name + '] }}'

          let beginWith = '{{ [' + task
          beginWith += '|' + this.$store.state.designerTasks[task].items[item].id + ']'

          let nextStartIdx = 0
          while (val.indexOf(beginWith, nextStartIdx) !== -1) {
            const startIdx = val.indexOf(beginWith, nextStartIdx)
            let endIdx = -1
            endIdx = val.indexOf('}}', startIdx)
            if (endIdx !== -1) {
              // Replace in the middle with the new value
              const newVal = val.substring(0, startIdx) + finalValue + val.substring(endIdx + 2)
              val = newVal
            } else {
              break // Stop processing, there is no end tag
            }
            nextStartIdx = startIdx + 1 // Move forward to prevent looping
          }
        }
      }
      return val
    }
  },
  mounted () {
    this.body = this.adjustFieldNames(this.body)
    // Force an update to the body
    this.forceRenderComponentBody = false
    this.$nextTick().then(() => {
      // Add the component back in (it force the re-rendering)
      this.forceRenderComponentBody = true
      this.subject = this.adjustFieldNames(this.subject)
    })
  },
  computed: {
    name: {
      get () {
        return this.theData.name
      },
      set (value) {
        const copyOfData = { ...this.theData, name: value }
        this.$store.commit('designerEmailSet', copyOfData)
      }
    },
    subject: {
      get () {
        return this.theData.subject
      },
      set (value) {
        const copyOfData = { ...this.theData, subject: value }
        this.$store.commit('designerEmailSet', copyOfData)
      }
    },
    body: {
      get () {
        return this.theData.body
      },
      set (value) {
        const copyOfData = { ...this.theData, body: value }
        this.$store.commit('designerEmailSet', copyOfData)
      }
    },
    sendToSwimlane: {
      get () {
        return this.theData.sendToSwimlane
      },
      set (value) {
        const copyOfData = { ...this.theData, sendToSwimlane: value }
        this.$store.commit('designerEmailSet', copyOfData)
      }
    },
    sendToUserTask: {
      get () {
        return this.theData.sendToUserTask
      },
      set (value) {
        const copyOfData = { ...this.theData, sendToUserTask: value }
        this.$store.commit('designerEmailSet', copyOfData)
      }
    },
    sendToAddressList: {
      get () {
        return this.theData.sendToAddressList
      },
      set (value) {
        const copyOfData = { ...this.theData, sendToAddressList: value }
        this.$store.commit('designerEmailSet', copyOfData)
      }
    },
    listOfAllTasks: {
      get () {
        // Return the list of all available tasks
        var allTasks = []
        allTasks.push({ value: null, text: '-' })
        for (const task in this.$store.state.designerTasks) {
          allTasks.push({ value: this.$store.state.designerTasks[task].id, text: this.$store.state.designerTasks[task].name })
        }
        return allTasks
      }
    },
    listOfAllFields: {
      get () {
        // Return the list of all available fields, to be used in the body or in the subject
        // Allowed types: text, number, list, boolean, date
        var allFields = []
        for (const task in this.$store.state.designerTasks) {
          for (const item in this.$store.state.designerTasks[task].items) {
            if (['text', 'number', 'list', 'boolean', 'date', 'file'].includes(this.$store.state.designerTasks[task].items[item].type)) {
              // Internal identifier
              let theValue = '{{ [' + task
              theValue += '|' + this.$store.state.designerTasks[task].items[item].id + ']'
              theValue += '[' + this.$store.state.designerTasks[task].name
              theValue += '|' + this.$store.state.designerTasks[task].items[item].name + '] }}'

              // Display value
              let theText = this.$store.state.designerTasks[task].name
              theText += ' -> ' + this.$store.state.designerTasks[task].items[item].name

              // Add to the list (value + text)
              allFields.push({ value: theValue, text: theText })
            }
          }
        }
        return allFields
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mailbodyplaceholder {
  background-color: rgb(235, 224, 235);
  padding: 10px;
  min-height: 100%;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
}
</style>
