<template>
    <b-container>
    <b-row>
        <b-col style="text-align:center">
            <b-img :src="require('../assets/LogoLG.png')" fluid alt=""></b-img>
        </b-col>
    </b-row>
    <b-row><b-col style="text-align:center"><h1>DigiWi</h1></b-col></b-row>
    <b-row><b-col style="text-align:center"><h2>{{ $t('ACTIVATE.title') }}</h2><br></b-col></b-row>
    <b-row>
      <b-col sm="12" md="2"></b-col>
      <b-col style="text-align:left">
        <br>
        <div v-if="errorDescription.length > 0" style="text-align: center"><b-alert show variant="danger" >{{ errorDescription }}</b-alert>
            {{ $t('ACTIVATE.errorGoTo.1') }} <b-link :to="{ name: 'LoginPage' }"><b>{{ $t('ACTIVATE.errorGoTo.2') }}</b></b-link>
        </div>
        <div v-if="displayMessage.length > 0" style="text-align: center"><b-alert show variant="success" >{{ displayMessage }}</b-alert>
            {{ $t('ACTIVATE.successGoTo.1') }} <b-link :to="{ name: 'LoginPage' }"><b>{{ $t('ACTIVATE.successGoTo.2') }}</b></b-link> {{ $t('ACTIVATE.successGoTo.3') }}
        </div>
      </b-col>
      <b-col sm="12" md="2"></b-col>
    </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'ResetPasswordPage',
  data () {
    return {
      errorDescription: this.$t('ACTIVATE.errorActivation'),
      displayMessage: this.$t('ACTIVATE.successActivation')
    }
  },
  async mounted () {
    // Activate account
    this.errorDescription = ''
    this.displayMessage = ''

    try {
      // Call the service to activate this account
      const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'tenant/activate/' + this.$route.params.tenant + '/' + this.$route.params.token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Accept-Language': this.$i18n.locale
        }
      })

      // Get the json contents
      const content = await rawResponse.json()
      if (content.errorCode === 0) {
        // Activation ok
        this.errorDescription = ''
        this.displayMessage = this.$t('ACTIVATE.successActivation')
      } else {
        // Activation failure. It's an error, or the account is already active
        this.errorDescription = this.$t('ACTIVATE.errorActivation')
      }
    } catch (err) {
      // Connection problems
      this.errorDescription = err
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
