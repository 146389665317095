<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
      <div class="menubar"> <!-- Menu bar with all the actions -->

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.bold() }"
          @click="commands.bold"
        >
          <icon name="type-bold" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.italic() }"
          @click="commands.italic"
        >
          <icon name="type-italic" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.strike() }"
          @click="commands.strike"
        >
          <icon name="type-strikethrough" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.underline() }"
          @click="commands.underline"
        >
          <icon name="type-underline" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.code() }"
          @click="commands.code"
        >
          <icon name="code" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          &para;
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          <icon name="type-h1" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          <icon name="type-h2" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          <icon name="type-h3" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <icon name="list-ul" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <icon name="list-ol" />
        </b-button>

        <!--
        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <icon name="chat-square-quote" />
        </b-button>
        -->
        <!--
        <b-button
          size="sm"
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <icon name="code" />
        </b-button>
        -->
        <b-button
          size="sm"
          class="menubar__button"
          @click="commands.horizontal_rule"
        >
          <icon name="hr" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          @click="commands.undo"
        >
          <icon name="arrow-counterclockwise" />
        </b-button>

        <b-button
          size="sm"
          class="menubar__button"
          @click="commands.redo"
        >
          <icon name="arrow-clockwise" />
        </b-button>

        <!-- Link -->
        <!-- Link edit form -->
        <b-form class="mt-1" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
          <button class="menubar__button" @click="setLinkUrl(commands.link, null)" type="button">
            <icon name="x-circle-fill" />
          </button>
        </b-form>
        <template v-else>
          <!-- Link edit button -->
          <button
            class="menubar__button ml-1"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }"
          >
            <b-icon icon="link" class="mr-1"></b-icon>
            <span>{{ isActive.link() ? $t('EDITORHTML.updateLink') : $t('EDITORHTML.addLink')}}</span>
          </button>
        </template>

      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import Icon from './EditorHtmlIcon'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  // Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'
export default {
  name: 'EditorHtml',
  components: {
    EditorContent,
    EditorMenuBar,
    Icon
  },
  props: ['value'], // v-model variable
  data () {
    return {
      editor: new Editor({
        extensions: [
          // new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: this.value, // Initialize
        onUpdate: ({ getHTML }) => {
          // Update events, when something change
          let newContent = getHTML()
          // Add to all links target="_blank". All the links must open in a new page.
          newContent = newContent.replace(/rel="noopener noreferrer nofollow"/g, 'rel="noopener noreferrer nofollow" target="_blank"')
          this.$emit('input', newContent) // Send back the new value (implement v-model)
        }
      }),
      linkUrl: null,
      linkMenuIsActive: false
    }
  },
  methods: {
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkMenu()
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style scoped>
.editor {
  border: 1px solid black;
  padding: 5px;
  background-color:rgb(128, 122, 122);
}
.menubar__button {
  color:#000000;
  background-color:#ffffff;
}
.editor__content {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
}
</style>
