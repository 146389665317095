<template>
  <div id="taskeditplaceholder">
    <b-modal id="viewProcessModal" size="xl">
      <div id="viewProcessPlaceholder">
        <ViewProcess :processId="$store.state.currentTask.process.id" :hideActions=true></ViewProcess>
      </div>
    </b-modal>
    <b-row><!-- Actions -->
      <b-col sm="12" md="6">
        <b-button
            variant="secondary"
            size="sm"
            @click="$router.push({ name: 'MyTasks' })"
          >
            <b-icon icon="backspace"></b-icon> {{ $t('TASK.btnBack') }}
          </b-button>
      </b-col>
      <b-col sm="12" md="6">
        <div id="taskactions">
          <b-button
            variant="primary"
            size="sm"
            class="m-1"
            @click="saveTask(0)"
          >
            <b-icon icon="pause-fill"></b-icon> {{ $t('TASK.btnSave') }}
          </b-button>
          <b-button
            variant="info"
            size="sm"
            class="m-1"
            @click="saveTask(1)"
          >
            <b-icon icon="check-circle-fill"></b-icon> {{ $t('TASK.btnVerify') }}
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="m-1"
            @click="saveTask(2)"
          >
            <b-icon icon="cloud-arrow-up-fill"></b-icon> {{ $t('TASK.btnComplete') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" lg="3">
        <div id="taskeditinfos" ><!-- Main task infos -->
          <div class="text-primary"><b>{{ $t('TASK.process') }}</b>
          </div>
          <h3><b-button variant="primary" size="sm" v-on:click="viewProcess()"><b-icon icon="search"></b-icon></b-button>
          {{ $store.state.currentTask.process.name }}
          </h3>
          <hr>
          <div class="text-primary"><b>{{ $t('TASK.workflow') }}</b></div>
          <b-icon v-if="$store.state.currentTask.workflow.categoryName && $store.state.currentTask.workflow.categoryName.length > 0"
                    icon="square-fill"
                    :style="{ color: $store.state.currentTask.workflow.categoryColor }"></b-icon>
                {{ $store.state.currentTask.workflow.categoryName }}
          <h5>{{ $store.state.currentTask.workflow.name }}</h5>
          <hr>
          <div v-for="attachment in $store.state.currentTask.attachments" v-bind:key="attachment.id" class="text-break">
            <!-- Direct download - workflow files -->
            <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + attachment.downloadToken" target="_blank" class="font-weight-bold">
              <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{attachment.originalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( attachment.fileSize / 1024) }} KB )
            </a>
            <hr>
          </div>
          <div class="text-primary"><b>{{ $t('TASK.description') }}</b></div>
          <div v-html="$store.state.currentTask.workflow.description" class="text-break"></div>
          <div v-if="!$store.state.currentTask.workflow.description">-</div>
        </div>
      </b-col>
      <b-col xs="12" lg="9">
        <div id="taskeditinputform" class="p-2 p-md-4" >
          <!-- Validation errors -->
          <b-alert show variant="warning" v-if="validationErrors.length > 0">
            <b-icon icon="exclamation-triangle"></b-icon>&nbsp;&nbsp; {{ lastValidationTime }}: {{ $t('TASK.formInvalid') }}
          </b-alert>
          <b-alert show variant="success" v-if="validationErrors.length == 0 && lastValidationTime!=null">
            <b-icon icon="info-circle-fill"></b-icon>&nbsp;&nbsp; {{ lastValidationTime }}: {{ $t('TASK.formValid') }}
          </b-alert>

          <!-- Form input -->
          <b-icon icon="puzzle-fill" font-scale="1" style="color: #fad145"></b-icon> {{ $t('TASK.task') }}<h1>{{ $store.state.currentTask.task.name }}</h1>
          <div v-for="field in $store.state.currentTask.fields" v-bind:key="field.id"><!-- Fields -->
            <b-row>
              <b-col xs="12" :md="field.type =='form_text'? '12': '6'"><!-- Field description -->
                <span v-if="field.description !== null" v-html="field.description" ></span>
                <span v-else >{{ field.name }}</span><!-- Use field name -->
              </b-col>
              <b-col  xs="12" md="6"><!-- Field input, depending from its type -->
                <span v-if="field.mandatory===1" style="color:red">* <span class="text-info"><i>{{ $t('TASK.mandatory') }}</i></span></span>
                <div v-if="field.type=='number'"><!-- we use here a text instead of number to prevent html5 check client side -->
                  <div v-if="field.isReference===0" class="user_input">
                  <b-form-input v-model="fieldValues[field.id]" :placeholder="$t('TASK.enterNumber')" type="text"></b-form-input>
                  </div>
                  <span v-else>{{ fieldValues[field.id] }}</span><!-- reference -->
                </div>
                <div v-if="field.type=='text' && field.isMultiline === 1"><!-- multiline text -->
                  <div v-if="field.isReference===0" class="user_input">
                    <EditorHtml v-model="fieldValues[field.id]"></EditorHtml>
                  </div>
                  <span v-else v-html="fieldValues[field.id]"></span><!-- reference -->
                </div>
                <div v-if="field.type=='text' && field.isMultiline !== 1"><!-- normal text -->
                  <div v-if="field.isReference===0" class="user_input">
                    <b-form-input v-model="fieldValues[field.id]" :placeholder="$t('TASK.enterValue')" type="text" trim></b-form-input>
                  </div>
                  <span v-else>{{ fieldValues[field.id] }}</span><!-- reference -->
                </div>
                <div v-if="field.type=='date'"><!-- date -->
                  <div v-if="field.isReference===0" class="user_input">
                    <b-form-datepicker v-model="fieldValues[field.id]" class="mb-2"
                      today-button
                      reset-button
                      close-button
                      :show-decade-nav="true"
                      :locale="$i18n.locale">
                    </b-form-datepicker>
                  </div>
                  <span v-else>{{ fieldValues[field.id] }}</span><!-- reference -->
                </div>
                <div v-if="field.type=='boolean'"><!-- boolean -->
                  <div v-if="field.isReference===0" class="user_input">
                    <b-form-checkbox v-model="fieldValues[field.id]"
                      value="1"
                      unchecked-value="0"
                      size="lg"
                    >
                    </b-form-checkbox>
                  </div>
                  <div v-else><!-- reference -->
                    <b-form-checkbox v-model="fieldValues[field.id]"
                        value="1"
                        unchecked-value="0"
                        :disabled="true"
                        size="lg"
                    >
                    </b-form-checkbox>
                  </div>
                </div>
                <div v-if="field.type=='list'"><!-- list -->
                  <div v-if="field.isReference===0" class="user_input">
                    <b-form-select v-model="fieldValues[field.id]"
                      :options="field.listValues"
                      value-field="code"
                      text-field="display">
                    </b-form-select>
                  </div>
                  <span v-else>{{ fieldValues[field.id] }}</span><!-- reference -->
                </div>
                <div v-if="field.type=='file'" class="user_input"><!-- file upload -->
                    <div style="background-color:white">
                      <FileUpload :workflowId="$store.state.currentTask.workflow.id"
                        :isReference="field.isReference"
                        :taskId="field.taskId"
                        :processId="$store.state.currentTask.process.id"
                        v-model="fieldValues[field.id]"
                        :downloadToken="field.downloadToken"
                      >
                      </FileUpload>
                    </div>
                </div>
                <div v-if="field.type=='form_video'"><!-- video -->
                  <b-row>
                    <b-col>
                      <div v-if="field.defaultValue != null && field.defaultValue.length > 5" class="p-4">
                        <video-embed :src="field.defaultValue"></video-embed>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="['form_file','form_image'].includes(field.type) && field.fileIdentifier != null"><!-- files from form definition files -->
                  <b-row>
                    <b-col>
                      <b-container fluid class="p-4 bg-light text-center">
                        <!-- Render form_image as image if possible -->
                        <b-img v-if="field.type === 'form_image'" center thumbnail fluid :src="$store.state.serviceAPIUrl + 'workflow/image/preview/' + field.downloadToken" alt=""></b-img>
                        <!-- Direct download - task files -->
                        <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + field.downloadToken" target="_blank" class="font-weight-bold">
                          <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{field.fileOriginalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( field.fileSize / 1024) }} KB )
                        </a>
                      </b-container>
                    </b-col>
                  </b-row>
                </div>
                <!-- Validation errors, display the message if the error is related to this item -->
                <span v-for="(validationError,index) in validationErrors" v-bind:key="index">
                  <b-alert v-if="validationError[0] === field.id" show variant="warning">
                    <b-icon icon="exclamation-triangle"></b-icon>&nbsp;&nbsp;{{ lastValidationTime }} : {{ validationError[1] }}
                    &nbsp;&nbsp;<b-button
                      variant="info"
                      size="sm"
                      @click="saveTask(1)"
                    >
                      <b-icon icon="cloud-check-fill"></b-icon> {{ $t('TASK.btnRecheck') }}
                    </b-button>
                  </b-alert>
                </span>
              </b-col>
            </b-row>
            <hr>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EditorHtml from '../common/EditorHtml'
import FileUpload from './FileUpload'
import ViewProcess from '../common/ViewProcess' // View the whole process data

export default {
  name: 'TaskEditPage',
  components: {
    EditorHtml,
    FileUpload,
    ViewProcess
  },
  data () {
    return {
      fieldValues: this.getFieldValues(),
      // fieldStates: this.getFieldStates(),
      selectedInputFile: null,
      validationErrors: [],
      lastValidationTime: null
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    getFieldValues () {
      /* Return an dictionary containing only the id/values.
         This is used as v-model for each input control to store user data. */
      var fields = {}
      for (const f of this.$store.state.currentTask.fields) {
        this.$set(fields, f.id, f.fieldValue)
      }
      return fields
    },
    async viewProcess () {
      this.$bvModal.show('viewProcessModal') // Open modal to display single process
    },
    async saveTask (action) {
      // 1) Save the values only or
      // 2) validate, save and complete.
      const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/saveorcomplete', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.$store.state.userToken,
          'Accept-Language': this.$i18n.locale
        },
        body: JSON.stringify({
          workflowId: this.$store.state.currentTask.workflow.id,
          taskId: this.$store.state.currentTask.task.id,
          processId: this.$store.state.currentTask.process.id,
          action: action,
          fieldValues: this.fieldValues
        })
      })
      if (rawResponse.ok) {
        // Get the json contents
        const content = await rawResponse.json()
        this.lastValidationTime = (new Date()).toLocaleString()

        if (content.errorCode === 0 && action !== 1) {
          // this.$router.push({ name: 'MyTasks' }) // Return to my task page
          this.$store.commit('setNextTasksData', { processId: this.$store.state.currentTask.process.id, rootProcessId: this.$store.state.currentTask.process.rootId, sourceObject: this.$store.state.currentTask.task.name, sourcePage: 'task' })
          // alert(JSON.stringify(this.$store.state.nextTasksData))
          this.$router.push({ name: 'NextTasks' })
        } else if (content.errorCode === 2 || action === 1) {
          // Validation errors
          this.validationErrors = content.validationErrors
        } else {
          this.$bvToast.toast(this.$t('TASK.error') + ': ' + content.errorDescription, {
            title: this.$t('TASK.errorTitle'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } else {
        this.$bvToast.toast(this.$t('TASK.error'), {
          title: this.$t('TASK.errorTitle'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.user_input {
  padding:0.3em;
  background-color:rgb(219, 250, 240);
  border: 1px solid rgb(125, 221, 191);
}
#taskeditplaceholder {
  text-align: left;
}
#taskeditinfos {
  background-color: rgb(240, 240, 240);
  padding:10px;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  border:10px solid #5f5e5e;
}
#taskeditinputform {
  background-color:#ffffff;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  border:10px solid #5f5e5e;
}
#taskactions {
  background-color:#5f5e5e;
  background: linear-gradient(0deg, #5f5e5e 0%, #5f5e5e 50%, #918f8f 100%);
  border-radius: 30px 10px 0px 0px;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  text-align:right;
  padding: 10px 10px 5px 10px;
}
#viewProcessPlaceholder {
  margin-top:10px;
  xxpadding:10px;
  xxbox-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  xxborder: 10px solid rgb(141, 132, 132);
}
</style>
