<template>
  <div style="text-align: left">
    <!-- Modal frame for group composition -->
    <b-modal id="userGroupModal" size="xl"
      @hidden="closeModal"
    >
      <span class="text-primary"><b>{{ $t('GROUPS.group') }}</b></span>
      <h1>{{ currentGroupName }}</h1>
      <b-row>
        <b-col sm="12" lg="6">
          <b-card>
            <h3>{{ $t('GROUPS.usersInGroup') }}</h3>
            <hr>
            <b-row v-for="user in currentUserList" v-bind:key="user.id" class="m-1">
              <b-col class="text-left">{{ user.lastName }} {{ user.firstName }}</b-col>
              <b-col class="text-right"><b-button class="mb-1" variant="danger" v-on:click="removeUser(user.id)"><b-icon icon="x-square-fill"></b-icon></b-button></b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" lg="6">
          <b-card>
            <h3>{{ $t('GROUPS.availableUsers') }}</h3>
            <hr>
            <b-row v-for="user in userList" v-bind:key="user.id" class="m-1">
              <b-col class="text-left">{{ user.lastName }} {{ user.firstName }}</b-col>
              <b-col class="text-right"><b-button class="mb-1" variant="success" v-on:click="addUser(user.id)"><b-icon icon="plus-circle-fill"></b-icon></b-button></b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-col md="6">
        <h1 style="display:inline"><b-icon icon="people-fill" style="color: #ff7725;"></b-icon> {{ $t('GROUPS.title') }}
        </h1>
        <b-icon class="ml-4 my-1"
            :icon="showNewGroupIcon"
            variant="success"
            scale="2"
            @click="showNewGroup = !showNewGroup;showNewGroup ? showNewGroupIcon='dash-circle-fill' : showNewGroupIcon='plus-circle-fill';">
          </b-icon>
      </b-col>
      <b-col md="6" class="text-right">
        <!-- Table filter -->
        <b-form-group
          :label="$t('GLOBAL.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-2 mt-4 text-primary font-weight-bold"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder=""
              style="background-color: #f9e49d"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{ $t('GLOBAL.clear') }}</b-button>
            </b-input-group-append>
            <b-button class="ml-2 py-0" variant="primary" v-on:click="getGroups()"><b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GROUPS.reload') }}</b-button>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="showNewGroup">
      <b-col sm="12"><!-- New Group -->
        <div class="bg-primary p-1 p-md-3">
          <div class="bg-secondary p-3">
            <span>{{ $t('GROUPS.name') }}</span>
            <b-form-input class="mb-2" v-model="newGroupName" placeholder="" type="text" trim></b-form-input>
            <span>{{ $t('GROUPS.description') }}</span>
            <b-form-input class="mb-2" v-model="newGroupDescription" placeholder="" type="text" trim></b-form-input>
            <div class="text-right">
              <b-button :disabled="!(newGroupName != null && newGroupName.length > 0)" variant="success" v-on:click="createGroup()"><b-icon icon="plus"></b-icon> {{ $t('GLOBAL.insert') }}</b-button>
            </div>
          </div>
        </div>
        <br>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <!-- Group list -->
        <div v-if="groupList.length > 0" class="mt-2">
          <b-table striped hover stacked='md'
            outlined bordered
            head-variant="dark"
            :items="groupList"
            :fields="groupFields"
            :filter="filter"
          >
            <!-- Headers -->
            <template #head(name)>{{ $t('GROUPS.name') }}</template>
            <template #head(description)>{{ $t('GROUPS.description') }}</template>
            <template #head(userCount)>{{ $t('GROUPS.users') }}</template>
            <template #head(actions)>{{ $t('GLOBAL.tableActions') }}</template>

            <!-- Values -->
            <template v-slot:cell(name)="data">
              <p class="text-primary font-weight-bold text-break">{{ data.item.name }}</p>
            </template>
            <template v-slot:cell(userCount)="data">
              <b-badge variant="warning">{{ data.item.userCount }}</b-badge>
            </template>
            <template v-slot:cell(actions)="data"><!-- Show / hide version list -->
              <div class="text-right">
                <b-button size="sm" @click="data.toggleDetails" class="mr-2" variant="primary">
                  <b-icon icon="pencil-square"></b-icon> {{ data.detailsShowing ? $t('GLOBAL.hideDetails') : $t('GLOBAL.showDetails') }}
                </b-button>
              </div>
            </template>
            <template #row-details="data"><!-- Template for details -->
              <div class="bg-primary p-1 p-md-3">
                <div class="bg-secondary p-3">
                  <span>{{ $t('GROUPS.name') }}</span> <b-form-input class="mb-2" v-model="data.item.name" placeholder="" type="text" trim></b-form-input>
                  <span>{{ $t('GROUPS.description') }}</span> <b-form-input class="mb-2" v-model="data.item.description" placeholder="" type="text" trim></b-form-input>
                  <div class="text-right">
                    <b-button variant="primary" class="m-1" v-on:click="editUsers(data.item.id, data.item.name)"><b-icon icon="people"></b-icon> {{ $t('GROUPS.btnEditUsers') }}</b-button>
                    <b-button variant="danger" class="m-1" v-on:click="deleteGroup(data.item.id)"><b-icon icon="trash"></b-icon> {{ $t('GLOBAL.delete') }}</b-button>
                    <b-button variant="success" class="m-1" v-on:click="updateGroup(data.item.id, data.item.name, data.item.description)"><b-icon icon="pencil"></b-icon> {{ $t('GLOBAL.update') }}</b-button>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'ManageGroupsPage',
  components: {
  },
  data () {
    return {
      loading: true, // TBD
      errorDescription: '', // TBD
      userList: [],
      groupList: [],
      newGroupName: null,
      newGroupDescription: null,
      currentGroupId: null,
      currentGroupName: null,
      currentUserList: [],
      showNewGroup: false,
      showNewGroupIcon: 'plus-circle-fill',
      groupFields: [{
        key: 'name',
        label: this.$t('GROUPS.name'),
        sortable: true
      },
      {
        key: 'description',
        label: this.$t('GROUPS.description'),
        sortable: true
      },
      {
        key: 'userCount',
        label: this.$t('GROUPS.users'),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('GLOBAL.tableActions'),
        sortable: false
      }],
      filter: null
    }
  },
  mounted () {
    // Force a load of the users
    this.getGroups()
  },
  methods: {
    closeModal () {
      // Don't take any actions nor reloads
      // this.getGroups()
    },
    async getGroupUsers (insideGroup) {
      // Get user for current group
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groupusers/' + this.currentGroupId + '/' + insideGroup, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (insideGroup === '1') {
            this.currentUserList = content.userList
          } else {
            this.userList = content.userList
          }
        } else {
          if (insideGroup === '1') {
            this.currentUserList = []
          } else {
            this.userList = []
          }
        }
      } catch (err) {
        if (insideGroup === '1') {
          this.currentUserList = []
        } else {
          this.userList = []
        }
      }
    },
    async getGroups () {
      // Retrieve the list of all groups
      this.showNewGroup = false
      this.showNewGroupIcon = 'plus-circle-fill'
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groups', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.groupList = content.groupList
        } else {
          this.groupList = []
          this.$bvToast.toast(this.$t('GROUPS.error'), {
            title: this.$t('GROUPS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.groupList = []
        this.$bvToast.toast(this.$t('GROUPS.error'), {
          title: this.$t('GROUPS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }

      this.loading = false
    },
    async updateGroup (groupId, groupName, groupDescription) {
      // Update group
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groups', {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ id: groupId, name: groupName, description: groupDescription })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            // Reload list of groups
            this.getGroups()
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('GROUPS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('GROUPS.errorUpdate'), {
            title: this.$t('GROUPS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('GROUPS.errorUpdate'), {
          title: this.$t('GROUPS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async deleteGroup (groupId) {
      // Delete group
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groups', {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ id: groupId })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            // Reload list of groups
            this.getGroups()
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('GROUPS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('GROUPS.errorDelete'), {
            title: this.$t('GROUPS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('GROUPS.errorDelete'), {
          title: this.$t('GROUPS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async editUsers (groupId, groupName) {
      // Open the dialog with the group composition
      this.currentGroupId = groupId
      this.currentGroupName = groupName
      await this.$nextTick()
      await this.getGroupUsers('0') // All users except the one in group
      await this.getGroupUsers('1') // All users in group
      await this.$nextTick()
      this.$bvModal.show('userGroupModal')
    },
    async addUser (userId) {
      // Add a user to the group
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groupuser', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ userId: userId, groupId: this.currentGroupId })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            // OK
            await this.getGroupUsers('0') // Reload group list
            await this.getGroupUsers('1') // Reload group list
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('GROUPS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('GROUPS.errorAdd'), {
            title: this.$t('GROUPS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('GROUPS.errorAdd'), {
          title: this.$t('GROUPS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async removeUser (userId) {
      // Remove a user from the group
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groupuser', {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ userId: userId, groupId: this.currentGroupId })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            // OK
            await this.getGroupUsers('0') // Reload group list
            await this.getGroupUsers('1') // Reload group list
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('GROUPS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('GROUPS.errorRemove'), {
            title: this.$t('GROUPS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('GROUPS.errorRemove'), {
          title: this.$t('GROUPS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async createGroup () {
      // Create a new group
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groups', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ name: this.newGroupName, description: this.newGroupDescription })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            // Reload list of groups
            this.getGroups()
            this.newGroupName = null
            this.newGroupDescription = null
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('GROUPS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('GROUPS.errorInsert'), {
            title: this.$t('GROUPS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('GROUPS.errorInsert'), {
          title: this.$t('GROUPS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
