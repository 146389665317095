<template>
  <div>
    <b-row class="mt-3">
      <b-col>{{ $t('DESIGNER.report.task') }}
        <h4 class="text-primary mt-0">{{ theData.name }}</h4></b-col>
    </b-row>
    <b-row><b-col><b-alert variant="info" show v-html="$t('DESIGNER.report.usage')"></b-alert></b-col></b-row>
    <b-row>
      <b-col md="6">
        <br><h4>{{ $t('DESIGNER.report.description') }}</h4>
        <EditorHtml v-model="description"></EditorHtml>
        <br>
      </b-col>
      <b-col md="6"><!-- Configurations / formats -->
        <br>
        <div class="my-2"><b-icon icon="check-square" scale="1.2" class="mr-2"></b-icon><b>{{ $t('DESIGNER.report.formatBooleanTrue') }}</b></div>
        <b-form-input v-model="formatBooleanTrue" placeholder=""></b-form-input>
        <br>
        <div class="my-2"><b-icon icon="square" scale="1.2" class="mr-2"></b-icon><b>{{ $t('DESIGNER.report.formatBooleanFalse') }}</b></div>
        <b-form-input v-model="formatBooleanFalse" placeholder=""></b-form-input>
        <br>
        <div class="my-2"><b-icon icon="calendar" scale="1.2" class="mr-2"></b-icon><b>{{ $t('DESIGNER.report.formatDate') }}</b></div>
        <b-form-select v-model="formatDate" :options="dateFormats"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Attachment (Word document template) -->
        <br><h4><b-icon icon="file-text-fill" variant="info"></b-icon> {{ $t('DESIGNER.report.documentTemplate') }}</h4>
        <b-form-file
                v-model="selectedInputFile"
                :state="Boolean(selectedInputFile)"
                ref="file-input"
                :placeholder="$t('UPLOAD.placeHolder')"
                :drop-placeholder="$t('UPLOAD.dropPlaceholder')"
        >
        </b-form-file>
        <div align="right" class="m-1">
          <b-button variant="outline-primary" @click="uploadFile()">
            <b-icon icon="cloud-upload"></b-icon> {{ $t('UPLOAD.btnUpload') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="fileOriginalName" class="mb-3"><!-- Template name -->
      <b-col>
        <hr>{{ $t('DESIGNER.report.template') }}:
        <!-- Direct download - workflow files -->
        <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + downloadToken" target="_blank" class="font-weight-bold">
          <b-icon icon="file-earmark-fill" variant="primary"></b-icon> {{fileOriginalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( fileSize / 1024) }} KB )
        </a>
      </b-col>
    </b-row>
    <b-row v-if="fileOriginalName"><!-- Mapping -->
      <b-col><h4><b-icon icon="bezier2" style="color: var(--pink)"></b-icon> {{ $t('DESIGNER.report.mapping') }}:</h4></b-col>
    </b-row>
    <b-row v-for="(val, key) in mapping" v-bind:key="key" class="my-3 p-1">
      <b-col md="6">
        <b-form-select
          v-model="mapping[key]"
          value-field="value"
          text-field="text"
          :options="listOfAllFields"></b-form-select>
      </b-col>
      <b-col md="2" class="text-center p-1"><b-icon icon="arrow-right-square" style="color: var(--pink)"></b-icon></b-col>
      <b-col md="4" class="text-left p-1 pl-3 font-weight-bold text-primary bg-secondary">{{ key }}</b-col>
      <hr>
    </b-row>
  </div>
</template>

<script>

import { arrayBufferToBase64 } from '../common/Utility'
import EditorHtml from '../common/EditorHtml'

export default {
  name: 'ReportEditor',
  props: {
    theData: Object
  },
  data () {
    return {
      selectedInputFile: null,
      // Initialize structure from the store (save before exiting)
      description: this.$store.state.designerReports[this.theData.id].description,
      fileIdentifier: this.$store.state.designerReports[this.theData.id].fileIdentifier,
      fileOriginalName: this.$store.state.designerReports[this.theData.id].fileOriginalName,
      fileSize: this.$store.state.designerReports[this.theData.id].fileSize,
      formatBooleanTrue: this.$store.state.designerReports[this.theData.id].formatBooleanTrue,
      formatBooleanFalse: this.$store.state.designerReports[this.theData.id].formatBooleanFalse,
      formatDate: this.$store.state.designerReports[this.theData.id].formatDate,
      mapping: { ...this.$store.state.designerReports[this.theData.id].mapping }, // mergeFieldName = uuid of process fields
      downloadToken: this.$store.state.designerReports[this.theData.id].downloadToken,
      dateFormats: [
        { value: '%d.%m.%Y', text: '%d.%m.%Y (e.g. 31.12.2020)' },
        { value: '%d/%m/%Y', text: '%d/%m/%Y (e.g. 31/12/2020)' },
        { value: '%m-%d-%Y', text: '%m-%d-%Y (e.g. 12-31-2020)' },
        { value: '%Y-%m-%d', text: '%Y-%m-%d (e.g. 2020-12-31)' }
      ]
    }
  },
  components: {
    EditorHtml
  },
  beforeDestroy () {
    // Save back to the store, before exiting
    const newReport = {
      id: this.theData.id, // Fixed
      name: this.theData.name, // Fixed
      description: this.description,
      formatBooleanTrue: this.formatBooleanTrue,
      formatBooleanFalse: this.formatBooleanFalse,
      formatDate: this.formatDate,
      fileIdentifier: this.fileIdentifier,
      fileOriginalName: this.fileOriginalName,
      fileSize: this.fileSize,
      mapping: { ...this.mapping }
    }
    this.$store.commit('designerReportSet', newReport)
  },
  methods: {
    async uploadFile () {
      if (this.selectedInputFile != null) {
        var reader = new FileReader()

        reader.onloadend = async (e) => {
          // Callback that is called when finished the reading of the file
          try {
            // Call the backend service
            const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/upload', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.$store.state.userToken,
                'Accept-Language': this.$i18n.locale
              },
              body: JSON.stringify({
                type: 'workflow_report',
                workflowId: this.$store.state.designerBaseData.id,
                fileName: this.selectedInputFile.name,
                fileContents: arrayBufferToBase64(reader.result)
              })
            })
            if (rawResponse.ok) {
              const resContent = await rawResponse.json()
              if (resContent.errorCode === 0) {
                // Upload ok
                // Try to maintain previous mapping with same name
                const copyOfMapping = { ...this.mapping }
                this.mapping = {}
                this.fileIdentifier = resContent.fileId
                this.fileOriginalName = resContent.fileName
                this.fileSize = resContent.fileSize
                for (const mergeField in resContent.mergeFields) {
                  if (resContent.mergeFields[mergeField] in copyOfMapping) {
                    this.mapping[resContent.mergeFields[mergeField]] = copyOfMapping[resContent.mergeFields[mergeField]] // Reuse old mapping
                  } else {
                    this.mapping[resContent.mergeFields[mergeField]] = '' // Initialize
                  }
                }
                this.downloadToken = resContent.downloadToken
              } else {
                this.fileIdentifier = null
                this.fileOriginalName = ''
                this.fileSize = 0
                this.mapping = {}
                this.downloadToken = null

                // An error occurred
                this.$bvToast.toast(resContent.errorDescription, {
                  title: this.$t('UPLOAD.btnUpload'),
                  autoHideDelay: 5000,
                  appendToast: false,
                  variant: 'danger',
                  solid: true,
                  toaster: 'b-toaster-top-full'
                })
              }
            }
            this.selectedInputFile = null
          } catch (err) {
            this.$bvToast.toast(err, {
              title: this.$t('UPLOAD.btnUpload'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        }
        reader.readAsArrayBuffer(this.selectedInputFile) // Start reading the local file
      }
    }
  },
  computed: {
    listOfAllFields: {
      get () {
        // Return the list of all available fields, to be used for report mappings (mail merge)
        var allFields = [{ value: '', text: '-' }]
        for (const task in this.$store.state.designerTasks) {
          for (const item in this.$store.state.designerTasks[task].items) {
            allFields[''] = '-'
            if (['text', 'number', 'list', 'boolean', 'date'].includes(this.$store.state.designerTasks[task].items[item].type)) {
              allFields.push({ value: this.$store.state.designerTasks[task].items[item].id, text: this.$store.state.designerTasks[task].name + '.' + this.$store.state.designerTasks[task].items[item].name })
              // allFields[this.$store.state.designerTasks[task].items[item].id] = this.$store.state.designerTasks[task].name + '.' + this.$store.state.designerTasks[task].items[item].name
            }
          }
        }
        return allFields
      }
    }
  }
}
</script>

<style scoped>
</style>
