<template>
  <div id="workflowplaceholder">
    <b-row>
      <b-col sm="12" md="8">
        <h3 class="d-inline py-1 px-1 text-primary">
          <!-- Category + title -->
          <span class="text-primary">
            <b-icon icon="stickies" class=""></b-icon>
            {{ category }}</span>
            <b-icon icon="arrow-right" class="mx-2 text-secondary"></b-icon>
            <span v-if="$store.state.designerBaseData.name.length > 0" class="px-2 bg-secondary text-primary">
              {{ $store.state.designerBaseData.name }}
            </span>
            <span v-else class="px-2 bg-secondary text-primary">
              <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon> {{ $t('DESIGNER.main.noName') }}
            </span>
            <!-- description and attachments -->
            <b-button v-if="displayGraphEditor" type="button" variant="info" size="sm" class="ml-4"
              @click="displayGraphEditor=false;displayMainDataEditor=true;displaySwimlaneEditor=false;displayTaskEditor=false;displayDecisionEditor=false;displayApproveEditor=false;displayEmailEditor=false;displayReportEditor=false;displayProcessEditor=false" ><b-icon icon="pencil-square" scale="1"></b-icon>
              {{ $t('DESIGNER.main.btnDescriptionAttachment') }}
            </b-button>
        </h3>
      </b-col>
      <b-col sm="12" md="4" class="text-right">
        <b-button v-show="displayGraphEditor" type="button" variant="success" size="sm" @click="saveWorkflow()" >
          <b-icon icon="cloud-upload" scale="1"></b-icon> {{ $t('DESIGNER.main.btnSave') }}
        </b-button>
        <!-- designer -->
        <b-button v-if="!displayGraphEditor" type="button" variant="primary" size="sm" class="ml-4"
          @click="displayGraphEditor=true;displayMainDataEditor=false;displaySwimlaneEditor=false;displayTaskEditor=false;displayDecisionEditor=false;displayApproveEditor=false;displayEmailEditor=false;displayReportEditor=false;displayProcessEditor=false">
          <b-icon icon="backspace-fill" class="mr-2"></b-icon>{{ $t('DESIGNER.main.btnReturnToWorkflow') }}
        </b-button>
        <!--<b-button class="m-1" v-show="displayGraphEditor" type="button" variant="primary" size="sm" @click="showXmlModel()" >
          XML
        </b-button>-->
      </b-col>
    </b-row>
    <hr>
    <!-- Graph editor. Use v-show because we don't want the component to reinitialize -->
    <b-row v-show="displayGraphEditor">
      <b-col>
        <b-row>
          <b-col sm="12" md="12" class="text-left">
            <div ref="toolbar_container" class="mxtoolbarplaceholder"></div>
            <!-- <b-button type="button" variant="primary" @click="showJsonModel" >Json model</b-button>
            <b-button type="button" variant="primary" @click="showXmlModel" >Xml model</b-button> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <div ref="graph_container" class="mxplaceholder"></div><br>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Main data editor -->
    <b-row v-if="displayMainDataEditor">
      <b-col sm="12">
        <MainDataEditor :categoryList="categoryList"></MainDataEditor>
      </b-col>
    </b-row>

    <!-- Swimlane editor -->
    <b-row v-if="displaySwimlaneEditor">
      <b-col sm="12">
        <SwimlaneEditor :theData="this.$store.state.designerSwimlanes[this.currentSwimlaneId]"></SwimlaneEditor>
      </b-col>
    </b-row>

    <!-- Task editor -->
    <b-row v-if="displayTaskEditor">
      <b-col sm="12">
        <TaskEditor :theData="this.$store.state.designerTasks[this.currentTaskId]"></TaskEditor>
      </b-col>
    </b-row>

    <!-- Decision editor -->
    <b-row v-if="displayDecisionEditor">
      <b-col sm="12">
        <DecisionEditor :theData="this.$store.state.designerDecisions[this.currentDecisionId]"></DecisionEditor>
      </b-col>
    </b-row>

    <!-- Approval editor -->
    <b-row v-if="displayApproveEditor">
      <b-col sm="12">
        <ApproveEditor :theData="this.$store.state.designerApprovals[this.currentApproveId]"></ApproveEditor>
      </b-col>
    </b-row>

    <!-- Email editor -->
    <b-row v-if="displayEmailEditor">
      <b-col sm="12">
        <EmailEditor :theData="this.$store.state.designerEmails[this.currentEmailId]"></EmailEditor>
      </b-col>
    </b-row>

    <!-- Report editor -->
    <b-row v-if="displayReportEditor">
      <b-col sm="12">
        <ReportEditor :theData="this.$store.state.designerReports[this.currentReportId]"></ReportEditor>
      </b-col>
    </b-row>

    <!-- Process editor -->
    <b-row v-if="displayProcessEditor">
      <b-col sm="12">
        <ProcessEditor :theData="this.$store.state.designerProcesses[this.currentProcessId]"></ProcessEditor>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import mxgraph from '../../assets/mxgraph' // Import the wrapper for mxgraph
import MainDataEditor from './MainDataEditor' // Import the main data editor
import SwimlaneEditor from './SwimlaneEditor' // Import the swimlane editor
import TaskEditor from './TaskEditor' // Import the task editor
import EmailEditor from './EmailEditor' // Import the email editor
import ReportEditor from './ReportEditor' // Import the report editor
import DecisionEditor from './DecisionEditor' // Import the decision editor
import ApproveEditor from './ApproveEditor' // Import the approval editor
import ProcessEditor from './ProcessEditor' // Import the process editor (to run another workflow)

// Workaround because window['mxGraphModel'] is not defined
window.mxEditor = mxgraph.mxEditor
window.mxGeometry = mxgraph.mxGeometry
window.mxDefaultKeyHandler = mxgraph.mxDefaultKeyHandler
window.mxDefaultPopupMenu = mxgraph.mxDefaultPopupMenu
window.mxGraph = mxgraph.mxGraph
window.mxCell = mxgraph.mxCell
window.mxCellPath = mxgraph.mxCellPath
window.mxGraph = mxgraph.mxGraph
window.mxStylesheet = mxgraph.mxStylesheet
window.mxDefaultToolbar = mxgraph.mxDefaultToolbar
window.mxGraphModel = mxgraph.mxGraphModel
window.mxPoint = mxgraph.mxPoint
window.mxRectangle = mxgraph.mxRectangle

// Import the library to convert xml to json
const { DOMParser } = require('xmldom')
const xmlToJSON = require('../../assets/xmlToJSON')
xmlToJSON.stringToXML = (string) => new DOMParser().parseFromString(string, 'text/xml')

// Defines a new class for all icons that will be displayed at the right of the vertex
class MxIconSet {
  constructor (state, editComponentCallback) {
    this.images = []
    var graph = state.view.graph

    // Icon for copy element
    // REMOVED AT THIS TIME
    /*
    var img = mxgraph.mxUtils.createImage(process.env.BASE_URL + 'mxGraph/custom/icons8-copy-96.png')
    img.setAttribute('title', 'Duplicate')
    img.style.position = 'absolute'
    img.style.cursor = 'pointer'
    img.style.width = '32px'
    img.style.height = '32px'
    img.style.left = (state.x + state.width + 5) + 'px'
    img.style.top = (state.y + (state.height / 3 * 2) + 32) + 'px'

    mxgraph.mxEvent.addGestureListeners(img,
      mxgraph.mxUtils.bind(this, function (evt) {
        var s = graph.gridSize
        graph.setSelectionCells(graph.moveCells([state.cell], s, s, true))
        mxgraph.mxEvent.consume(evt)
        this.destroy()
      })
    )

    state.view.graph.container.appendChild(img)
    this.images.push(img)
    */

    // Icon for deletion
    var img = mxgraph.mxUtils.createImage(process.env.BASE_URL + 'mxGraph/custom/icons8-delete-96.png')
    img.setAttribute('title', 'Delete')
    img.style.position = 'absolute'
    img.style.cursor = 'pointer'
    img.style.width = '32px'
    img.style.height = '32px'
    img.style.left = (state.x + state.width + 5) + 'px'
    img.style.top = (state.y + (state.height / 5)) + 'px'

    mxgraph.mxEvent.addGestureListeners(img,
      mxgraph.mxUtils.bind(this, function (evt) {
        // Disables dragging the image
        mxgraph.mxEvent.consume(evt)
      })
    )

    mxgraph.mxEvent.addListener(img, 'click',
      mxgraph.mxUtils.bind(this, function (evt) {
        graph.removeCells([state.cell])
        mxgraph.mxEvent.consume(evt)
        this.destroy()
      })
    )

    state.view.graph.container.appendChild(img)
    this.images.push(img)

    // Icon for modification
    img = mxgraph.mxUtils.createImage(process.env.BASE_URL + 'mxGraph/custom/icons8-compose-96.png')
    img.setAttribute('title', 'Edit')
    img.style.position = 'absolute'
    img.style.cursor = 'pointer'
    img.style.width = '32px'
    img.style.height = '32px'
    img.style.left = (state.x + state.width + 5) + 'px'
    img.style.top = (state.y + (state.height / 3 * 2)) + 'px'

    mxgraph.mxEvent.addGestureListeners(img,
      mxgraph.mxUtils.bind(this, function (evt) {
        // Disables dragging the image
        mxgraph.mxEvent.consume(evt)
      })
    )

    mxgraph.mxEvent.addListener(img, 'click',
      mxgraph.mxUtils.bind(this, function (evt) {
        // Click on edit icon on the component
        // We call the function editComponentCallback (which is a function pointer to vue component.editComponent(id,title))
        const id = state.cell.id
        const title = state.cell.getAttribute('label', '')
        let type = ''
        // Determine the type of the component
        // Check the existence of the attribute on the value, wkf_Task, wkf_Email, ...
        if (state.cell.getAttribute('wkf_Task', '') === '1') {
          type = 'wkf_Task'
        } else if (state.cell.getAttribute('wkf_Swimlane', '') === '1') {
          type = 'wkf_Swimlane'
        } else if (state.cell.getAttribute('wkf_Email', '') === '1') {
          type = 'wkf_Email'
        } else if (state.cell.getAttribute('wkf_Report', '') === '1') {
          type = 'wkf_Report'
        } else if (state.cell.getAttribute('wkf_Decision', '') === '1') {
          type = 'wkf_Decision'
        } else if (state.cell.getAttribute('wkf_Approve', '') === '1') {
          type = 'wkf_Approve'
        } else if (state.cell.getAttribute('wkf_Process', '') === '1') {
          type = 'wkf_Process'
        } else if (state.cell.getAttribute('wkf_Start', '') === '1') {
          type = 'wkf_Start'
        } else if (state.cell.getAttribute('wkf_End', '') === '1') {
          type = 'wkf_End'
        } else if (state.cell.getAttribute('wkf_Join', '') === '1') {
          type = 'wkf_Join'
        } else if (state.cell.getAttribute('wkf_Merge', '') === '1') {
          type = 'wkf_Merge'
        }

        editComponentCallback(id, title, type)
      })
    )

    state.view.graph.container.appendChild(img)
    this.images.push(img)
  }

  destroy () {
    if (this.images != null) {
      for (var i = 0; i < this.images.length; i++) {
        var img = this.images[i]
        img.parentNode.removeChild(img)
      }
    }

    this.images = null
  }
}

/* ---------------------- Vue js component starts here ---------------------- */
export default {
  name: 'WorkflowDesignerPage',
  data () {
    return {
      categoryList: [], // Workflow categories
      editor: null,
      graph: null,
      displayMainDataEditor: false, // Show main data, title and description, plus attachments
      displaySwimlaneEditor: false, // Show the swimlane editor (mainly for adding users)
      displayGraphEditor: true, // Show the graph editor
      displayTaskEditor: false, // Show the task editor
      displayDecisionEditor: false, // Show the decision editor
      displayEmailEditor: false, // Show the email editor
      displayReportEditor: false, // Shot the report editor
      displayProcessEditor: false, // Show the process editor
      displayApproveEditor: false, // Show the approve editor
      currentSwimlaneId: null,
      currentTaskId: null,
      currentDecisionId: null,
      currentEmailId: null,
      currentReportId: null,
      currentProcessId: null,
      currentApproveId: null,
      selectedInputFile: null // Placeholder for adding attachment (at workflow level)
    }
  },
  components: {
    MainDataEditor,
    SwimlaneEditor,
    TaskEditor,
    EmailEditor,
    ReportEditor,
    DecisionEditor,
    ApproveEditor,
    ProcessEditor
  },
  methods: {
    getXmlModel () {
      // Return the xml contents of the graph
      /* eslint-disable-next-line */
      var encoder = new mxgraph.mxCodec()
      var node = encoder.encode(this.graph.getModel())
      var myXml = mxgraph.mxUtils.getXml(node)

      return myXml
    },
    getJsonModel () {
      var myXml = this.getXmlModel()

      // Convert XML to JSON
      var options = {
        mergeCDATA: true, // extract cdata and merge with text nodes
        grokAttr: true, // convert truthy attributes to boolean, etc
        grokText: true, // convert truthy text/attr to boolean, etc
        normalize: true, // collapse multiple spaces to single space
        xmlns: true, // include namespaces as attributes in output
        namespaceKey: '_ns', // tag name for namespace objects
        textKey: '_text', // tag name for text nodes
        valueKey: '_value', // tag name for attribute values
        attrKey: '_attr', // tag for attr groups
        cdataKey: '_cdata', // tag for cdata nodes (ignored if mergeCDATA is true)
        attrsAsObject: true, // if false, key is used as prefix to name, set prefix to '' to merge children and attrs.
        stripAttrPrefix: true, // remove namespace prefixes from attributes
        stripElemPrefix: true, // for elements of same name in diff namespaces, you can enable namespaces and access the nskey property
        childrenAsArray: true // force children into arrays
      }
      var result = xmlToJSON.parseString(myXml, options)
      return result
    },
    showJsonModel () {
      alert(JSON.stringify(this.getJsonModel()))
    },
    showXmlModel () {
      alert(this.getXmlModel())
    },
    addToolbarItem (graph, toolbar, prototype, image, title) {
      // Function that is executed when the image is dropped on
      // the graph. The cell argument points to the cell under
      // the mousepointer if there is one.
      var funct = (graph, evt, cell) => {
        // A new component has been added.
        graph.stopEditing(false)

        var pt = graph.getPointForEvent(evt)
        var vertex = graph.getModel().cloneCell(prototype)
        vertex.geometry.x = pt.x
        vertex.geometry.y = pt.y
        // Vedi doc: https://jgraph.github.io/mxgraph/docs/js-api/files/model/mxCell-js.html

        // Set some property depending on the type of component
        switch (prototype.style) {
          case 'wkf_Swimlane':
            vertex.setValue(this.createNode('Swimlane', 'wkf_Swimlane'))
            vertex.setConnectable(false)
            break
          case 'wkf_Task':
            vertex.setValue(this.createNode('Task', 'wkf_Task'))
            break
          case 'wkf_Email':
            vertex.setValue(this.createNode('Email', 'wkf_Email'))
            break
          case 'wkf_Report':
            vertex.setValue(this.createNode('Report', 'wkf_Report')) // Report / Word template
            break
          case 'wkf_Decision':
            vertex.setValue(this.createNode('Decision', 'wkf_Decision'))
            break
          case 'wkf_Approve':
            vertex.setValue(this.createNode('Approve', 'wkf_Approve'))
            break
          case 'wkf_Process':
            vertex.setValue(this.createNode('Process', 'wkf_Process'))
            break
          case 'wkf_Start':
            vertex.setValue(this.createNode('Start', 'wkf_Start'))
            break
          case 'wkf_End':
            vertex.setValue(this.createNode('End', 'wkf_End'))
            break
          case 'wkf_Merge':
            vertex.setValue(this.createNode('Merge', 'wkf_Merge'))
            break
          case 'wkf_Join':
            vertex.setValue(this.createNode('Join', 'wkf_Join'))
            break
          default:
            // code block
            break
        }

        graph.setSelectionCells(graph.importCells([vertex], 0, 0, cell))
      }

      // Creates the image which is used as the drag icon (preview)
      var img = toolbar.addMode(title, image, funct)
      mxgraph.mxUtils.makeDraggable(img, graph, funct)
    },
    editComponent (id, title, type) {
      // Method called when the user click on the edit icon.
      // We "move" to the corresponding designer page according to selected component.

      var hideOtherEditors = () => {
        this.displayGraphEditor = false
        this.displaySwimlaneEditor = false
        this.displayMainDataEditor = false
        this.displayTaskEditor = false
        this.displayDecisionEditor = false
        this.displayApproveEditor = false
        this.displayEmailEditor = false
        this.displayReportEditor = false
        this.displayProcessEditor = false
      }

      this.purgeAndAlign()

      if (type === 'wkf_Swimlane') { // Show the swimlane editor
        if (!(id in this.$store.state.designerSwimlanes)) {
          // Create a new one in the store
          const newSwimlane = { id: id, name: title, description: '', isShared: 0, users: {}, groups: {} }
          this.$store.commit('designerSwimlaneSet', newSwimlane)
        }
        this.currentSwimlaneId = id // Change the id to be used by the SwimlaneEditor
        hideOtherEditors()
        this.displaySwimlaneEditor = true
        //
      } else if (type === 'wkf_Task') { // Show the task editor
        if (!(id in this.$store.state.designerTasks)) {
          // Create a new one in the store
          const newTask = { id: id, name: title, description: '', items: {} }
          this.$store.commit('designerTaskSet', newTask)
        }

        this.currentTaskId = id // Change the id to be used by the TaskEditor
        hideOtherEditors()
        this.displayTaskEditor = true
        //
      } else if (type === 'wkf_Decision') {
        if (!(id in this.$store.state.designerDecisions)) {
          // Create a new one in the store
          const newDecision = {
            id: id,
            name: title,
            description: '',
            rules: {},
            processes: {}
          }
          this.$store.commit('designerDecisionSet', newDecision)
        }

        this.currentDecisionId = id // Change the id to be used by the DecisionEditor
        hideOtherEditors()
        this.displayDecisionEditor = true
        //
      } else if (type === 'wkf_Approve') {
        if (!(id in this.$store.state.designerApprovals)) {
          // Create a new one in the store
          const newApproval = {
            id: id,
            name: title,
            description: '',
            selectedCompletionPolicy: 'N', // Default to Single approver
            approvePercentage: 50, // Percentage of approvers
            approveNumber: 1, // Number of approvers
            selectedTimeLimitType: 'N', // Default to No time limit
            timeUnit: 1 // Number of units of time
          }

          this.$store.commit('designerApprovalSet', newApproval)
        }

        this.currentApproveId = id // Change the id to be used by the ApproveEditor
        hideOtherEditors()
        this.displayApproveEditor = true
        //
      } else if (type === 'wkf_Process') {
        if (!(id in this.$store.state.designerProcesses)) {
          // Create a new one in the store
          const newProcess = {
            id: id,
            name: title,
            originatorId: null,
            mapping: {} // subprocessFieldId: thisProcessFieldId
          }
          this.$store.commit('designerProcessSet', newProcess)
        }

        this.currentProcessId = id // Change the id to be used by the ProcessEditor
        hideOtherEditors()
        this.displayProcessEditor = true
        //
      } else if (type === 'wkf_Email') {
        if (!(id in this.$store.state.designerEmails)) {
          // Create a new one in the store
          const newEmail = { id: id, name: title, subject: '', body: '', sendToSwimlane: 1, sendToUserTask: null, sendToAddressList: '' }
          this.$store.commit('designerEmailSet', newEmail)
        }

        this.currentEmailId = id // Change the id to be used by the EmailEditor
        hideOtherEditors()
        this.displayEmailEditor = true
        //
      } else if (type === 'wkf_Report') {
        if (!(id in this.$store.state.designerReports)) {
          // Create a new one in the store
          const newReport = {
            id: id,
            name: title,
            description: '',
            fileIdentifier: null,
            fileOriginalName: '',
            fileSize: 0,
            formatBooleanTrue: '',
            formatBooleanFalse: '',
            formatDate: '',
            mapping: {}
          } // mapping: merge field name : uuid of process field
          this.$store.commit('designerReportSet', newReport)
        }

        this.currentReportId = id // Change the id to be used by the ReportEditor
        hideOtherEditors()
        this.displayReportEditor = true
        //
      } else {
        // Do nothing
        // alert('Editing form with id: ' + id + ' - title: ' + title + ' - ' + type)
        alert(id + ' - ' + title)
      }
    },
    initGraph () {
      // Defines an icon for creating new connections in the connection handler.
      // This will automatically disable the highlighting of the source vertex.
      /* eslint-disable-next-line */
      mxgraph.mxConnectionHandler.prototype.connectImage = new mxgraph.mxImage(process.env.BASE_URL + 'mxGraph/connector.gif', 16, 16);

      // Creates the graph inside the given container
      var graphContainer = this.$refs.graph_container
      var toolbarContainer = this.$refs.toolbar_container

      // Creates the toolbar
      /* eslint-disable-next-line */
      var toolbar = new mxgraph.mxToolbar(toolbarContainer)
      toolbar.enabled = false // Only drag selection

      // Create the graph
      /* eslint-disable-next-line */
      this.editor = new mxgraph.mxEditor()
      this.editor.setGraphContainer(graphContainer)
      this.graph = this.editor.graph
      this.graph.dropEnabled = true
      this.configureStylesheet(this.graph)

      // Matches DnD inside the graph
      mxgraph.mxDragSource.prototype.getDropTarget = function (graph, x, y) {
        var cell = graph.getCellAt(x, y)

        if (!graph.isValidDropTarget(cell)) {
          cell = null
        }
        return cell
      }

      // Enables new connections in the graph
      this.graph.setConnectable(true)
      this.graph.setMultigraph(false)
      this.graph.setResizeContainer(false)

      /* eslint-disable-next-line */
      var keyHandler = new mxgraph.mxDefaultKeyHandler(this.editor)
      keyHandler.bindAction(46, 'delete')

      // Workaround to make deletion key to work
      this.graph.container.setAttribute('tabindex', '-1')
      this.graph.container.focus()
      if (mxgraph.mxClient.IS_NS) {
        mxgraph.mxEvent.addListener(this.graph.container, 'mousedown', () => {
          if (!this.graph.isEditing()) {
            this.graph.container.setAttribute('tabindex', '-1')
            this.graph.container.focus()
          }
        })
      }

      // Custom attributes: add functions to manage value of a cell as XML nodes. In this way is possible to add attributes.
      // Attributes are useful for multiplicity checks (constraints on connections).
      // Text value is set and retrieve through the label attribute.
      this.graph.convertValueToString = function (cell) {
        if (mxgraph.mxUtils.isNode(cell.value)) {
          return cell.getAttribute('label', '') // return the attribuel label
        } else {
          return cell.value // Simple string
        }
      }

      this.graph.cellLabelChanged = (cell, newValue, autoSize) => {
        // This function is called every time the text on the shapes is changed
        if (mxgraph.mxUtils.isNode(cell.value)) {
          // Clones the value for correct undo/redo
          var elt = cell.value.cloneNode(true)
          const model = this.graph.getModel()
          elt.setAttribute('label', newValue)
          newValue = elt
          model.setValue(cell, newValue) // Replace existing cell
        } else {
          const model = this.graph.getModel()
          model.setValue(cell, newValue) // Simple string (e.g. for edges)
        }
      }

      /* eslint-disable-next-line */
      var rubberband = new mxgraph.mxRubberband(this.graph)  

      // Add shape to the toolbar
      const addVertex = (icon, w, h, style, title) => {
        /* eslint-disable-next-line */
        var vertex = new mxgraph.mxCell(null, new mxgraph.mxGeometry(0, 0, w, h), style)
        vertex.setVertex(true)
        this.addToolbarItem(this.graph, toolbar, vertex, icon, title)
      }

      // Add to the toolbar, set default size and type
      addVertex(process.env.BASE_URL + 'mxGraph/custom/swimlane.png?t=20201118', 600, 180, 'wkf_Swimlane', this.$t('DESIGNER.workflow.swimlane')) // Swimlane
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-modulo-100.png?t=20211101', 80, 80, 'wkf_Task', this.$t('DESIGNER.workflow.task')) // Task
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-envelope-dots-100.png?t=20200421', 100, 80, 'wkf_Email', this.$t('DESIGNER.workflow.email')) // Email
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-microsoft-word-2019-96.png?t=20210315', 100, 80, 'wkf_Report', this.$t('DESIGNER.workflow.report')) // Report
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-rhombus-80.png?t=20200324', 80, 80, 'wkf_Decision', this.$t('DESIGNER.workflow.decision')) // Decision
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-rhombus-80_approve.png?t=20200405', 80, 80, 'wkf_Approve', this.$t('DESIGNER.workflow.approval')) // Approve
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-gear-96.png?t=20210105', 80, 110, 'wkf_Process', this.$t('DESIGNER.workflow.process')) // Process
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-round-96-green.png?t=20200324', 80, 80, 'wkf_Start', this.$t('DESIGNER.workflow.start')) // Start process
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-round-96-red.png?t=20200324', 80, 80, 'wkf_End', this.$t('DESIGNER.workflow.end')) // End process
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-parallel-workflow-merge.png?t=20201209', 100, 100, 'wkf_Merge', this.$t('DESIGNER.workflow.merge')) // Merge
      // toolbar.addLine()
      addVertex(process.env.BASE_URL + 'mxGraph/custom/icons8-parallel-workflow-join.png?t=20201209', 100, 100, 'wkf_Join', this.$t('DESIGNER.workflow.join')) // Join

      // Connection Rules

      // Start node does not want any incoming connections
      /* eslint-disable-next-line */
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        false, null, 'wkf_Start', '1', 0, 0, null,
        this.$t('DESIGNER.graph.rules.1'),
        null)) // Type does not matter

      // Start node cannot connect to end node
      /* eslint-disable-next-line */
      /* 
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        false, null, 'wkf_Start', '1', 0, 10, ['wkf_End'],
        'Start node cannot connect to end node',
        null)) // Type does not matter
      */

      // End node does not want any outgoing connections
      /* eslint-disable-next-line */
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        true, null, 'wkf_End', '1', 0, 0, null,
        this.$t('DESIGNER.graph.rules.2'),
        null)) // Type does not matter

      // Decision must have only 1 incoming connection
      /* eslint-disable-next-line */
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        false, null, 'wkf_Decision', '1', 1, 1, null,
        this.$t('DESIGNER.graph.rules.3'),
        null)) // Type does not matter

      // Decision must have 2 outgoing connections (1 condition, 1 else)
      /* eslint-disable-next-line */
      /*
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        true, null, 'wkf_Decision', '1', 0, 2, null,
        'Decision must have 2 outgoing connections',
        null)) // Type does not matter
      */

      // Approve must have only 1 incoming connection
      /* eslint-disable-next-line */
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        false, null, 'wkf_Approve', '1', 1, 1, null,
        this.$t('DESIGNER.graph.rules.4'),
        null)) // Type does not matter

      // Approve must have 2 outgoing connections (1 condition, 1 else)
      /* eslint-disable-next-line */
      /*
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        true, null, 'wkf_Approve', '1', 0, 2, null,
        'Approve must have 2 outgoing connections',
        null)) // Type does not matter
      */

      // Swimlanes don't need connections (both directions)
      /* eslint-disable-next-line */
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        true, null, 'wkf_Swimlane', '1', 0, 0, null,
        this.$t('DESIGNER.graph.rules.5'),
        null))

      /* eslint-disable-next-line */
      this.graph.multiplicities.push(new mxgraph.mxMultiplicity(
        false, null, 'wkf_Swimlane', '1', 0, 0, null,
        this.$t('DESIGNER.graph.rules.5'),
        null))

      // **********************************************************************************************************
      // Mouse events for displaying the actions on the vertex, rendered with icons/images
      // It show the icons if the mouse is over a cell
      var iconTolerance = 32 // Defines the tolerance before removing the icons
      this.graph.addMouseListener({
        currentState: null,
        currentIconSet: null,
        graph: this.graph,
        editComponentCallback: this.editComponent,
        mouseDown: function (sender, me) {
          // Hides icons on mouse down
          if (this.currentState != null) {
            this.dragLeave(me.getEvent(), this.currentState)
            this.currentState = null
          }
        },
        mouseMove: function (sender, me) {
          if (this.currentState != null && (me.getState() === this.currentState ||
            me.getState() == null)) {
            var tol = iconTolerance
            /* eslint-disable-next-line */
            let tmp = new mxgraph.mxRectangle(me.getGraphX() - tol,
              me.getGraphY() - tol, 2 * tol, 2 * tol)

            if (mxgraph.mxUtils.intersects(tmp, this.currentState)) {
              return
            }
          }
          var tmp = this.graph.view.getState(me.getCell())

          // Ignores everything but vertices
          if (this.graph.isMouseDown || (tmp != null && !this.graph.getModel().isVertex(tmp.cell))) {
            tmp = null
          }

          if (tmp !== this.currentState) {
            if (this.currentState != null) {
              this.dragLeave(me.getEvent(), this.currentState)
            }

            this.currentState = tmp

            if (this.currentState != null) {
              this.dragEnter(me.getEvent(), this.currentState)
            }
          }
        },
        mouseUp: function (sender, me) { },
        dragEnter: function (evt, state) {
          if (this.currentIconSet == null) {
            this.currentIconSet = new MxIconSet(state, this.editComponentCallback) // Pass function pointer to vue.editComponent
          }
        },
        dragLeave: function (evt, state) {
          if (this.currentIconSet != null) {
            this.currentIconSet.destroy()
            this.currentIconSet = null
          }
        }
      })
    },
    createNode (label, type) {
      // Create a node for an mxGraph vertex.
      // attribute 'label' will contain the text to display
      // attribute named type will have the value "1"
      // https://jgraph.github.io/mxgraph/docs/js-api/files/model/mxCell-js.html
      var doc = mxgraph.mxUtils.createXmlDocument()
      var node = doc.createElement('myNode')
      node.setAttribute('label', label)
      node.setAttribute(type, '1')
      return node
    },
    defaultWorkflow () {
      // Create a default workflow (to be used for new workflow instances)

      // Gets the default parent for inserting new cells. This
      // is normally the first child of the root (ie. layer 0).
      var parent = this.graph.getDefaultParent()

      // Adds cells to the model in a single step
      this.graph.getModel().beginUpdate()

      // Default items
      try {
        const swimlane1 = this.graph.insertVertex(parent, null, this.createNode('Swimlane 1', 'wkf_Swimlane'), 100, 50, 600, 180, 'wkf_Swimlane')
        swimlane1.setConnectable(false)
        const swimlane2 = this.graph.insertVertex(parent, null, this.createNode('Swimlane 2', 'wkf_Swimlane'), 100, 250, 600, 180, 'wkf_Swimlane')
        swimlane2.setConnectable(false)
        const start = this.graph.insertVertex(swimlane1, null, this.createNode('Start', 'wkf_Start'), 20, 60, 80, 80, 'wkf_Start')
        const task1 = this.graph.insertVertex(swimlane1, null, this.createNode('Task 1', 'wkf_Task'), 180, 40, 80, 80, 'wkf_Task')
        const decision = this.graph.insertVertex(swimlane1, null, this.createNode('Decision 1', 'wkf_Decision'), 340, 60, 80, 80, 'wkf_Decision')
        const task2 = this.graph.insertVertex(swimlane1, null, this.createNode('Task 2', 'wkf_Task'), 460, 40, 120, 120, 'wkf_Task')
        const task3 = this.graph.insertVertex(swimlane2, null, this.createNode('Task 3', 'wkf_Task'), 180, 40, 120, 120, 'wkf_Task')
        const end = this.graph.insertVertex(swimlane2, null, this.createNode('End', 'wkf_End'), 480, 60, 80, 80, 'wkf_End')

        this.graph.insertEdge(parent, null, '', start, task1)
        this.graph.insertEdge(parent, null, '', task1, decision)
        this.graph.insertEdge(parent, null, '', decision, task2)
        this.graph.insertEdge(parent, null, '', decision, task3)
        this.graph.insertEdge(parent, null, '', task2, end)
        this.graph.insertEdge(parent, null, '', task3, end)
      } finally {
        // Updates the display
        this.graph.getModel().endUpdate()
      }
    },
    configureStylesheet (graph) {
      // Initialize the style of mxGraph components.

      var style = {}
      // General options
      // style = mxgraph.mxUtils.clone(style)
      style = graph.getStylesheet().getDefaultVertexStyle()
      style[mxgraph.mxConstants.STYLE_SHAPE] = mxgraph.mxConstants.SHAPE_SWIMLANE
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_MIDDLE
      // Transparent
      // style[mxgraph.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = '#f4fcf8' // Same as background
      style[mxgraph.mxConstants.STYLE_FONTFAMILY] = 'Titillium Web, Arial, Helvetica'
      style[mxgraph.mxConstants.STYLE_FONTSIZE] = 14
      style[mxgraph.mxConstants.LINE_HEIGHT] = 1.2
      style[mxgraph.mxConstants.STYLE_STARTSIZE] = 40
      style[mxgraph.mxConstants.STYLE_HORIZONTAL] = false
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = 'black'
      style[mxgraph.mxConstants.STYLE_STROKECOLOR] = 'black'
      // delete style[mxgraph.mxConstants.STYLE_FILLCOLOR]

      // Swimlane
      style = mxgraph.mxUtils.clone(style)
      graph.getStylesheet().putCellStyle('wkf_Swimlane', style)

      // Start
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_HORIZONTAL] = true
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1 // maintain aspect ratio
      style[mxgraph.mxConstants.STYLE_SHAPE] = mxgraph.mxConstants.SHAPE_IMAGE
      style[mxgraph.mxConstants.STYLE_PERIMETER] = mxgraph.mxPerimeter.RectanglePerimeter
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-round-96-green.png?t=20200324'
      // style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#04881b'
      graph.getStylesheet().putCellStyle('wkf_Start', style)

      // End
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-round-96-red.png?t=20200324'
      // style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#f5320b'
      graph.getStylesheet().putCellStyle('wkf_End', style)

      // Task
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1 // Don't resize
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-modulo-100.png?t=20211101'
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#881c07'
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = mxgraph.mxConstants.FONT_BOLD
      style[mxgraph.mxConstants.STYLE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_SPACING_TOP] = '0'
      style[mxgraph.mxConstants.STYLE_IMAGE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_BOTTOM
      // style[mxgraph.mxConstants.STYLE_IMAGE_WIDTH] = '15'
      // style[mxgraph.mxConstants.STYLE_IMAGE_HEIGHT] = '15'
      style[mxgraph.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = '#fae8e6'
      graph.getStylesheet().putCellStyle('wkf_Task', style)

      // Email
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-envelope-dots-100.png?t=20200421'
      style[mxgraph.mxConstants.STYLE_IMAGE_WIDTH] = 15
      style[mxgraph.mxConstants.STYLE_IMAGE_HEIGHT] = 15
      style[mxgraph.mxConstants.STYLE_STARTSIZE] = 10
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#881c07'
      style[mxgraph.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = '#fefdef'
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = mxgraph.mxConstants.FONT_BOLD
      graph.getStylesheet().putCellStyle('wkf_Email', style)

      // Report
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-microsoft-word-2019-96.png?t=20210315'
      style[mxgraph.mxConstants.STYLE_IMAGE_WIDTH] = 15
      style[mxgraph.mxConstants.STYLE_IMAGE_HEIGHT] = 15
      style[mxgraph.mxConstants.STYLE_STARTSIZE] = 10
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_BOTTOM
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#dedede'
      style[mxgraph.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = '#2150a9'
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = mxgraph.mxConstants.FONT_BOLD
      graph.getStylesheet().putCellStyle('wkf_Report', style)

      // Decision
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-rhombus-80.png?t=20200324'
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#000000'
      style[mxgraph.mxConstants.STYLE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_SPACING_TOP] = '0'
      style[mxgraph.mxConstants.STYLE_IMAGE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = '0'
      style[mxgraph.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = ''
      // style[mxgraph.mxConstants.STYLE_IMAGE_WIDTH] = '50'
      // style[mxgraph.mxConstants.STYLE_IMAGE_HEIGHT] = '50'
      graph.getStylesheet().putCellStyle('wkf_Decision', style)

      // Approve
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-rhombus-80_approve.png?t=20200324'
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#000000'
      style[mxgraph.mxConstants.STYLE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_SPACING_TOP] = '0'
      style[mxgraph.mxConstants.STYLE_IMAGE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      // style[mxgraph.mxConstants.STYLE_IMAGE_WIDTH] = '50'
      // style[mxgraph.mxConstants.STYLE_IMAGE_HEIGHT] = '50'
      graph.getStylesheet().putCellStyle('wkf_Approve', style)

      // Process
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-gear-96.png?t=20210105'
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#00afff'
      style[mxgraph.mxConstants.STYLE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_SPACING_TOP] = '0'
      style[mxgraph.mxConstants.STYLE_IMAGE_ALIGN] = mxgraph.mxConstants.ALIGN_TOP
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_BOTTOM
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = mxgraph.mxConstants.FONT_BOLD
      style[mxgraph.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = '#ffffff'
      // style[mxgraph.mxConstants.STYLE_IMAGE_WIDTH] = '50'
      // style[mxgraph.mxConstants.STYLE_IMAGE_HEIGHT] = '50'
      graph.getStylesheet().putCellStyle('wkf_Process', style)

      // Merge
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-parallel-workflow-merge.png?t=20201209'
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#881c07'
      style[mxgraph.mxConstants.STYLE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_SPACING_TOP] = '0'
      style[mxgraph.mxConstants.STYLE_IMAGE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_BOTTOM
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = mxgraph.mxConstants.FONT_BOLD
      graph.getStylesheet().putCellStyle('wkf_Merge', style)

      // Join
      style = mxgraph.mxUtils.clone(style)
      style[mxgraph.mxConstants.STYLE_IMAGE_ASPECT] = 1
      style[mxgraph.mxConstants.STYLE_IMAGE] = process.env.BASE_URL + 'mxGraph/custom/icons8-parallel-workflow-join.png?t=20201209'
      style[mxgraph.mxConstants.STYLE_FONTCOLOR] = '#881c07'
      style[mxgraph.mxConstants.STYLE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_SPACING_TOP] = '0'
      style[mxgraph.mxConstants.STYLE_IMAGE_ALIGN] = mxgraph.mxConstants.ALIGN_CENTER
      style[mxgraph.mxConstants.STYLE_VERTICAL_ALIGN] = mxgraph.mxConstants.ALIGN_BOTTOM
      style[mxgraph.mxConstants.STYLE_FONTSTYLE] = mxgraph.mxConstants.FONT_BOLD
      graph.getStylesheet().putCellStyle('wkf_Join', style)

      // Edge style (arrows)
      var edgeStyle = graph.stylesheet.getDefaultEdgeStyle()
      edgeStyle[mxgraph.mxConstants.STYLE_EDGE] = mxgraph.mxEdgeStyle.ElbowConnector
      mxgraph.mxStyleRegistry.putValue('myEdgeStyle', mxgraph.mxEdgeStyle.MyStyle)
    },
    purgeAndAlign () {
      // 1) Remove deleted task, swimlane,... from main structures if not present in the mxGraph
      // 2) Align name of each task, swimlane, ...
      // 3) Remove orphans rules (it set variables to null if not found)
      // Warning: It has to be called at least before saving and before editing components.

      var allProcesses = {}
      this.$store.commit('designerGraphModelXmlSet', this.getXmlModel()) // Mxgraph in xml (this one is used to restore the graph))
      this.$store.commit('designerGraphModelJsonSet', this.getJsonModel()) // Mxgraph in json (used to extract connections)

      var myModel = this.$store.state.designerGraphModelJson.mxGraphModel[0].root[0]
      for (const node in myModel.myNode) {
        const id = myModel.myNode[node]._attr.id._value
        const name = myModel.myNode[node]._attr.label._value

        /* eslint-disable-next-line */
        if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Swimlane')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Swimlane' }

          if (id in this.$store.state.designerSwimlanes) {
            if (this.$store.state.designerSwimlanes[id].name !== name) {
              const currentSwimlane = { ...this.$store.state.designerSwimlanes[id], name: name } // Realign the name
              this.$store.commit('designerSwimlaneSet', currentSwimlane)
            }
          }
        /* eslint-disable-next-line */
        } else if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Task')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Task' }

          if (id in this.$store.state.designerTasks) {
            if (this.$store.state.designerTasks[id].name !== name) {
              const currentTask = { ...this.$store.state.designerTasks[id], name: name } // Realign the name
              this.$store.commit('designerTaskSet', currentTask)
            }
          }
        /* eslint-disable-next-line */
        } else if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Approve')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Approve' }

          if (id in this.$store.state.designerApprovals) {
            if (this.$store.state.designerApprovals[id].name !== name) {
              const currentApproval = { ...this.$store.state.designerApprovals[id], name: name } // Realign the name
              this.$store.commit('designerApprovalSet', currentApproval)
            }
          }
        /* eslint-disable-next-line */
        } else if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Process')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Process' }

          if (id in this.$store.state.designerProcesses) {
            if (this.$store.state.designerProcesses[id].name !== name) {
              const currentProcess = { ...this.$store.state.designerProcesses[id], name: name } // Realign the name
              this.$store.commit('designerProcessSet', currentProcess)
            }
          }
        /* eslint-disable-next-line */
        } else if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Decision')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Decision' }

          if (id in this.$store.state.designerDecisions) {
            if (this.$store.state.designerDecisions[id].name !== name) {
              const currentDecision = { ...this.$store.state.designerDecisions[id], name: name } // Realign the name
              this.$store.commit('designerDecisionSet', currentDecision)
            }
          }
        /* eslint-disable-next-line */
        } else if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Email')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Email' }

          if (id in this.$store.state.designerEmails) {
            if (this.$store.state.designerEmails[id].name !== name) {
              const currentEmail = { ...this.$store.state.designerEmails[id], name: name } // Realign the name
              this.$store.commit('designerEmailSet', currentEmail)
            }
          }
        /* eslint-disable-next-line */
        } else if (myModel.myNode[node]._attr.hasOwnProperty('wkf_Report')) {
          allProcesses[id] = { id: id, name: name, type: 'wkf_Report' }

          if (id in this.$store.state.designerReports) {
            if (this.$store.state.designerReports[id].name !== name) {
              const currentReport = { ...this.$store.state.designerReports[id], name: name } // Realign the name
              this.$store.commit('designerReportSet', currentReport)
            }
          }
        }
      } // for
      // Check swimlanes
      for (const id in this.$store.state.designerSwimlanes) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerSwimlaneRemove', id) // Remove swimlane
        }
      }

      // Check tasks
      for (const id in this.$store.state.designerTasks) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerTaskRemove', id) // Remove task
        }
      }

      // Check approve
      for (const id in this.$store.state.designerApprovals) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerApprovalRemove', id) // remove approval
        }
      }

      // Check decision
      for (const id in this.$store.state.designerDecisions) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerDecisionRemove', id) // remove decision
        }
      }

      // Check email
      for (const id in this.$store.state.designerEmails) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerEmailRemove', id) // remove email
        }
      }

      // Check report
      for (const id in this.$store.state.designerReports) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerReportRemove', id) // remove report
        }
      }

      // Check processes
      for (const id in this.$store.state.designerProcesses) {
        /* eslint-disable-next-line */
        if (!allProcesses.hasOwnProperty(id)) {
          this.$store.commit('designerProcessRemove', id) // remove process
        }
      }

      // Find all task fields, excluding type "reference"
      var allFields = {}
      for (const task in this.$store.state.designerTasks) {
        for (const field in this.$store.state.designerTasks[task].items) {
          if (this.$store.state.designerTasks[task].items[field].type !== 'reference') {
            allFields[field] = 1
          }
        }
      }
      // Find all subprocesses
      var allSubprocesses = {}
      for (const process in this.$store.state.designerProcesses) {
        allSubprocesses[process] = 1
      }
      // Process all rules and clean the one with not found fields
      for (const decision in this.$store.state.designerDecisions) {
        for (const rule in this.$store.state.designerDecisions[decision].rules) {
          const currentValues = this.$store.state.designerDecisions[decision].rules[rule]
          const currentValuesCopy = { ...currentValues }
          let toChange = false
          if (currentValues.ruleVariable != null && !(currentValues.ruleVariable in allFields)) {
            currentValuesCopy.ruleVariable = null // Reset
            toChange = true
          }
          if (currentValues.ruleValueVariable != null && !(currentValues.ruleValueVariable in allFields)) {
            currentValuesCopy.ruleValueVariable = null // Reset
            toChange = true
          }
          if (toChange) {
            const obj = {
              decisionId: decision,
              ruleId: currentValuesCopy.id,
              ruleValue: currentValuesCopy
            }
            this.$store.commit('designerDecisionRuleSet', obj) // apply the changes
          }
        }

        for (const rule in this.$store.state.designerDecisions[decision].processes) {
          const currentValues = this.$store.state.designerDecisions[decision].processes[rule]
          const currentValuesCopy = { ...currentValues }
          let toChange = false
          if (currentValues.processTaskId != null && !(currentValues.processTaskId in allSubprocesses)) {
            currentValuesCopy.processTaskId = null // Reset
            toChange = true
          }
          if (toChange) {
            const obj = {
              decisionId: decision,
              processId: currentValuesCopy.id,
              ruleValue: currentValuesCopy
            }
            this.$store.commit('designerDecisionRuleProcessSet', obj) // apply the changes
          }
        }
      }
      // Process all references and clean the one that has been deleted
      for (const task in this.$store.state.designerTasks) {
        for (const field in this.$store.state.designerTasks[task].items) {
          if (this.$store.state.designerTasks[task].items[field].type === 'reference') {
            if (!(this.$store.state.designerTasks[task].items[field].referencedItemId in allFields)) {
              const obj = { taskId: task, itemId: field }
              this.$store.commit('designerTaskItemRemove', obj)
            }
          }
        }
      }
    },
    async saveWorkflow () {
      // Saves all the data acquired to the server.
      var contents = {}

      this.purgeAndAlign()

      contents.workflow = {}
      contents.workflow.graphModelJson = JSON.stringify(this.$store.state.designerGraphModelJson) // JSON stringified
      contents.workflow.graphModelXml = this.$store.state.designerGraphModelXml
      contents.workflow.baseData = this.$store.state.designerBaseData
      contents.workflow.swimlanes = this.$store.state.designerSwimlanes
      contents.workflow.attachments = this.$store.state.designerAttachments
      contents.workflow.tasks = this.$store.state.designerTasks
      contents.workflow.decisions = this.$store.state.designerDecisions
      contents.workflow.emails = this.$store.state.designerEmails
      contents.workflow.reports = this.$store.state.designerReports
      contents.workflow.approvals = this.$store.state.designerApprovals
      contents.workflow.processes = this.$store.state.designerProcesses

      const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/save', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.$store.state.userToken,
          'Accept-Language': this.$i18n.locale
        },
        body: JSON.stringify(contents)
      })
      if (rawResponse.ok) {
        // Get the json contents
        const response = await rawResponse.json()
        if (response.errorCode === 0) {
          /*
          this.$bvToast.toast('Workflow successfully saved.', {
            title: 'Save workflow',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center'
          })
          */
          this.$router.push({ name: 'WorkflowDesignerListPage', params: { fromAction: 'saveWorkflowButton' } })
        } else {
          this.$bvToast.toast(this.$t('DESIGNER.main.errorSave') + ': ' + response.errorDescription, {
            title: this.$t('DESIGNER.main.btnSave'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } else {
        this.$bvToast.toast(this.$t('DESIGNER.main.errorSave'), {
          title: this.$t('DESIGNER.main.btnSave'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async getCategories () {
      // Retrieve the list of all categories
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/category', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.categoryList = content.categoryList
          this.categoryList.unshift({ id: null, name: '- No category -' })
        } else {
          this.categoryList = []
          this.$bvToast.toast(this.$t('DESIGNER.main.errorCategory'), {
            title: this.$t('DESIGNER.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.categoryList = []
        this.$bvToast.toast(this.$t('DESIGNER.main.errorCategory'), {
          title: this.$t('DESIGNER.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  },
  computed: {
    id: {
      get () {
        return this.$store.state.designerBaseData.id
      },
      set (value) {
        const copyOfData = { ...this.$store.state.designerBaseData, id: value }
        this.$store.commit('designerBaseData', copyOfData)
      }
    },
    name: {
      get () {
        return this.$store.state.designerBaseData.name
      },
      set (value) {
        const copyOfData = { ...this.$store.state.designerBaseData, name: value }
        this.$store.commit('designerBaseData', copyOfData)
      }
    },
    description: {
      get () {
        return this.$store.state.designerBaseData.description
      },
      set (value) {
        const copyOfData = { ...this.$store.state.designerBaseData, description: value }
        this.$store.commit('designerBaseData', copyOfData)
      }
    },
    category: {
      get () {
        // Return the description of the category
        for (const cat of this.categoryList) {
          if (cat.id === this.$store.state.designerBaseData.categoryId) {
            return cat.name
          }
        }
        return null
      }
    }
  },
  mounted () {
    // this.$store.commit('designerReset')

    // Initizialize the mxGraph
    this.initGraph()

    // Load the mxGraph from the xml
    /* eslint-disable-next-line */
    var encoder = new mxgraph.mxCodec()
    var doc = mxgraph.mxUtils.parseXml(this.$store.state.designerGraphModelXml) // Xml represention
    encoder.decode(doc.documentElement, this.graph.getModel())

    // Create a default workflow
    // this.defaultWorkflow()

    // Initialize the store for the designer
    // this.$store.commit('designerReset')

    // Load workflow categories
    this.getCategories()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#workflowplaceholder {
  text-align: left;
}
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.mxplaceholder {
  background-color: var(--white);
  padding: 0px;
  border-bottom: 5px solid var(--info);
  border-left: 5px solid var(--info);
  border-right: 5px solid var(--info);
  --box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  overflow:auto;
  /* min-height: 100%; */
}
.mxtoolbarplaceholder {
  background-color: var(--secondary);
  --background: linear-gradient(90deg, #8dd4bf 0%, #ffffff 60%, #8dd4bf 100%);
  --padding: 5px;
  border: 5px solid var(--info);
  --overflow:auto;
  --box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  --text-align: center;
}

</style>
<style>
.mxToolbarMode {
  width: 45px;
  margin: 1em 1em;
}
</style>
