<template>
  <div>
    <h4 class="mt-2">{{ $t('DESIGNER.approval.task') }}: <span class="text-primary">{{ theData.name }}</span></h4>
    <br>
    <h3>{{ $t('DESIGNER.approval.completionPolicy') }}</h3>
    <b-form-group label="">
      <b-form-radio-group id="radio-group-2" v-model="selectedCompletionPolicy" name="radio-sub-component">
        <b-form-radio value="N">{{ $t('DESIGNER.approval.approversNumber') }}<b-form-input type="number" min="1" v-model="approveNumber" placeholder=""></b-form-input></b-form-radio>
        <b-form-radio value="P">{{ $t('DESIGNER.approval.approversPercentage') }}<b-form-input type="number" min="1" max="100" v-model="approvePercentage" placeholder=""></b-form-input></b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <h3>{{ $t('DESIGNER.approval.timeLimit') }}</h3>
    <b-row>
      <b-col sm="12" md="4">
        <b-form-select v-model="selectedTimeLimitType" class="mb-3">
          <b-form-select-option value="N">{{ $t('DESIGNER.approval.timeLimit.nolimits') }}</b-form-select-option>
          <b-form-select-option value="D">{{ $t('DESIGNER.approval.timeLimit.days') }}</b-form-select-option>
          <b-form-select-option value="H">{{ $t('DESIGNER.approval.timeLimit.hours') }}</b-form-select-option>
        </b-form-select>
        <b-form-input type="number" min="1" v-model="timeUnit" :placeholder="$t('DESIGNER.approval.timeLimit.unitDescr')"></b-form-input>
      </b-col>
      <b-col sm="12" md="8"></b-col>
    </b-row>
    <b-row>
      <b-col>
        <br><h3>{{ $t('DESIGNER.approval.description') }}</h3>
        <!--<b-form-textarea v-model="description" trim rows="3" max-rows="6"></b-form-textarea>-->
        <EditorHtml v-model="description"></EditorHtml>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EditorHtml from '../common/EditorHtml'

// Form editor
export default {
  name: 'ApproveEditor',
  props: {
    theData: Object
  },
  data () {
    return {
    }
  },
  components: {
    EditorHtml
  },
  methods: {
  },
  mounted () {
    // alert('approval editor')
  },
  computed: {
    description: {
      get () {
        return this.theData.description
      },
      set (value) {
        const copyOfData = { ...this.theData, description: value }
        this.$store.commit('designerApprovalSet', copyOfData)
      }
    },
    selectedCompletionPolicy: {
      get () {
        return this.theData.selectedCompletionPolicy
      },
      set (value) {
        const copyOfData = { ...this.theData, selectedCompletionPolicy: value }
        this.$store.commit('designerApprovalSet', copyOfData)
      }
    },
    approvePercentage: {
      get () {
        return this.theData.approvePercentage
      },
      set (value) {
        const copyOfData = { ...this.theData, approvePercentage: value }
        this.$store.commit('designerApprovalSet', copyOfData)
      }
    },
    approveNumber: {
      get () {
        return this.theData.approveNumber
      },
      set (value) {
        const copyOfData = { ...this.theData, approveNumber: value }
        this.$store.commit('designerApprovalSet', copyOfData)
      }
    },
    selectedTimeLimitType: {
      get () {
        return this.theData.selectedTimeLimitType
      },
      set (value) {
        const copyOfData = { ...this.theData, selectedTimeLimitType: value }
        this.$store.commit('designerApprovalSet', copyOfData)
      }
    },
    timeUnit: {
      get () {
        return this.theData.timeUnit
      },
      set (value) {
        const copyOfData = { ...this.theData, timeUnit: value }
        this.$store.commit('designerApprovalSet', copyOfData)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
