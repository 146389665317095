import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import MasterPage from '@/components/common/MasterPage.vue'
import HomePage from '@/components/HomePage'
import LoginPage from '@/components/LoginPage'
import RegisterPage from '@/components/RegisterPage'
import ActivatePage from '@/components/ActivatePage'
import UnsubscribePage from '@/components/UnsubscribePage'
import ResetPasswordPage from '@/components/ResetPasswordPage'
import ManageUsersPage from '@/components/admin/ManageUsersPage'
import ManageGroupsPage from '@/components/admin/ManageGroupsPage'
import ManageCategoryPage from '@/components/admin/ManageCategoryPage'
import AboutPage from '@/components/AboutPage'
import WorkflowDesignerPage from '@/components/WorkflowDesigner/WorkflowDesignerPage'
import WorkflowDesignerListPage from '@/components/WorkflowDesigner/WorkflowDesignerListPage'
import MyWorkflowsPage from '@/components/Workflow/MyWorkflowsPage'
import MyTasksflowsPage from '@/components/Workflow/MyTasksPage'
import NextTasksPage from '@/components/Workflow/NextTasksPage'
import TaskEditPage from '@/components/Workflow/TaskEditPage'
import ApproveEditPage from '@/components/Workflow/ApproveEditPage'
import ProcessesPage from '@/components/Workflow/ProcessesPage'

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', redirect: '/login' },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: 'homepage',
          name: 'HomePage',
          component: HomePage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: 'about',
          name: 'AboutPage',
          component: AboutPage
        }
      ]
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage
    },
    {
      path: '/register',
      name: 'RegisterPage',
      component: RegisterPage
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/admin/unsubscribe',
          name: 'UnsubscribePage',
          component: UnsubscribePage
        }
      ]
    },
    {
      path: '/tenant/activate/:tenant/:token',
      name: 'ActivatePage',
      component: ActivatePage
    },
    {
      path: '/user/resetpassword/:tenant/:token',
      name: 'ResetPasswordPage',
      component: ResetPasswordPage
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/admin/users',
          name: 'ManageUsersPage',
          component: ManageUsersPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/admin/users',
          name: 'ManageGroupsPage',
          component: ManageGroupsPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/admin/category',
          name: 'ManageCategoryPage',
          component: ManageCategoryPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/designerlist/:fromAction',
          name: 'WorkflowDesignerListPage',
          component: WorkflowDesignerListPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/myworkflows',
          name: 'MyWorkflows',
          component: MyWorkflowsPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/mytasks',
          name: 'MyTasks',
          component: MyTasksflowsPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/nexttasks',
          name: 'NextTasks',
          component: NextTasksPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/processes',
          name: 'Processes',
          component: ProcessesPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/designer',
          name: 'WorkflowDesignerPage',
          component: WorkflowDesignerPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/taskedit',
          name: 'TaskEdit',
          component: TaskEditPage
        }
      ]
    },
    {
      path: '/',
      component: MasterPage,
      children: [
        {
          path: '/workflow/approveedit',
          name: 'ApproveEdit',
          component: ApproveEditPage
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // Return to top of the page when changing page
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
})

export default router

// Prevent to access directly some routes if not authenticated
router.beforeEach((to, from, next) => {
  if (to.name !== 'LoginPage' && to.name !== 'RegisterPage' && to.name !== 'ActivatePage' && to.name !== 'ResetPasswordPage' && store.state.userToken === '') {
    next({ name: 'LoginPage' }) // Redirect to login page
  } else if (from.name === 'WorkflowDesignerPage' && to.params.fromAction !== 'saveWorkflowButton') {
    // Prevent leaving the designer without saving.
    // Don't ask if the navigation comes from the "official" save button
    // TODO - should be translated
    // const answer = window.confirm(Vue.$t('DESIGNER.leaveMessage'))
    const answer = window.confirm('Do you really want to quit this page without saving?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next() // Go to the next url
  }
})
