<template>
  <div><br>{{ $t('DESIGNER.workflow.process') }}
    <h4 class="mt-0"><span class="text-primary">{{ theData.name }}</span></h4>
    <br><h4><b-icon icon="gear-fill" ></b-icon> {{ $t('DESIGNER.workflow.process.run') }}</h4>
    <!-- Select workflow -->
    <b-form-select
      v-model="selectedWorkflow"
      :options="workflowList"
      @change="onChangeSelectedWorkflow"
      value-field="originatorId"
      text-field="name">
    </b-form-select>

    <!-- Mappings internal field ==> subprocess field-->
    <b-row v-if="workflowFields"><!-- Mapping -->
      <b-col><h4 class="mt-4"><b-icon icon="bezier2" style="color: var(--pink)"></b-icon> {{ $t('DESIGNER.workflow.process.mapping') }}:</h4></b-col>
    </b-row>
    <b-row v-for="(val, key) in workflowFields" v-bind:key="key" class="my-2 p-1">
      <b-col md="6">
        <b-form-select
          v-model="mapping[key]"
          value-field="value"
          text-field="text"
          :options="listOfAllFields[val.type]">
        </b-form-select>
      </b-col>
      <b-col md="2" class="text-center p-1"><b-icon icon="arrow-right-square" style="color: var(--pink)"></b-icon></b-col>
      <b-col md="4" class="text-left p-1 pl-3 font-weight-bold text-primary bg-secondary">
        {{ val.name }}
      </b-col>
    </b-row>
  </div>
</template>

<script>

// Form editor
export default {
  name: 'ProcessEditor',
  props: {
    theData: Object
  },
  data () {
    return {
      selectedWorkflow: this.theData.originatorId,
      workflowList: [],
      workflowFields: {},
      mapping: { ...this.theData.mapping }
    }
  },
  methods: {
    onChangeSelectedWorkflow (event) {
      this.reloadSubprocessFields()
    },
    async reloadSubprocessFields () {
      // Reload subprocess fields from db. Get the definitions from the "real" workflow id (not the originator...)
      for (const idx in this.workflowList) {
        if (this.workflowList[idx].originatorId === this.selectedWorkflow) {
          // Find the real workflow id to request for available fields.
          // Only from the workflow version that is published.
          for (const version in this.workflowList[idx].versions) {
            if (this.workflowList[idx].versions[version].enabled === 1) { // published
              try {
                // edit workflow
                const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/load/' + this.workflowList[idx].versions[version].id, {
                  method: 'GET',
                  headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.userToken,
                    'Accept-Language': this.$i18n.locale
                  }
                })
                if (rawResponse.ok) {
                  // Get the json contents
                  const content = await rawResponse.json()
                  if (content.errorCode === 0) {
                    // Extract all fields from that workflow (subprocess)
                    this.workflowFields = {}

                    for (const task in content.workflow.tasks) {
                      for (const fieldId in content.workflow.tasks[task].items) {
                        const field = content.workflow.tasks[task].items[fieldId]
                        if (['text', 'number', 'list', 'boolean', 'date'].includes(field.type)) {
                          this.workflowFields[fieldId] = { name: content.workflow.tasks[task].name + '.' + field.name, type: field.type }
                        }
                      }
                    }
                    // Clean up from mapping all fields that don't belong to subprocess field list
                    await this.$nextTick()
                    for (const fieldId in { ...this.mapping }) {
                      if (!(Object.keys(this.workflowFields).includes(fieldId))) {
                        delete this.mapping[fieldId]
                      }
                    }
                  }
                }
              } catch (err) {
                // Do nothing
                this.workflowFields = {}
              }
              break
            }
          }
        }
      }
    },
    async getWorkflowList () {
      // Retrieve the list of published workflows
      this.errorDescription = ''
      this.loading = true
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/list', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          // Filter workflow with at least one published version. Discard itself.
          this.workflowList = content.workflowList.filter((workflow) => {
            return workflow.atLeastOneEnabled === 1 &&
              this.$store.state.designerBaseData.originatorId !== workflow.originatorId
          })
        } else {
          this.workflowList = []
        }
      } catch (err) {
        //
      }
    }
  },
  async mounted () {
    await this.getWorkflowList()
    await this.reloadSubprocessFields()
  },
  beforeDestroy () {
    // Save back to the store, before exiting
    const copyOfData = { ...this.theData, originatorId: this.selectedWorkflow, mapping: { ...this.mapping } }
    this.$store.commit('designerProcessSet', copyOfData)
  },
  computed: {
    listOfAllFields: {
      get () {
        // Return the list of all available fields (of this workflow), to be used for report mappings, separated by type.
        var allFields = {}
        allFields.text = [{ text: '-', value: '-' }]
        allFields.number = [{ text: '-', value: '-' }]
        allFields.list = [{ text: '-', value: '-' }]
        allFields.boolean = [{ text: '-', value: '-' }]
        allFields.date = [{ text: '-', value: '-' }]

        for (const task in this.$store.state.designerTasks) {
          for (const item in this.$store.state.designerTasks[task].items) {
            const type = this.$store.state.designerTasks[task].items[item].type
            if (['text', 'number', 'list', 'boolean', 'date'].includes(type)) {
              // [type][id] = taskName.itemName
              allFields[type].push({
                value: this.$store.state.designerTasks[task].items[item].id,
                text: this.$store.state.designerTasks[task].name + '.' + this.$store.state.designerTasks[task].items[item].name
              })
            }
          }
        }
        return allFields
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
