<template>
  <div style="text-align: left">
    <b-modal id="confirmDeleteModal"
      @ok="deleteWorkflowExecute"
    >
      <template v-slot:modal-title>
        <span class="text-primary">{{ $t('DEGIGNER.warningDeletionTitle') }}</span>
      </template>
      <b-icon class="mr-2" icon="exclamation-triangle-fill" style="color: orange" scale="2"></b-icon>
      {{ $t('DEGIGNER.warningDeletion.1') }}
      <br><br>{{ $t('DEGIGNER.warningDeletion.2') }}
    </b-modal>
    <b-row>
      <b-col md="6"><!-- Title -->
        <h1 style="display:inline"><b-icon icon="cloud-fill" style="color: #8edf9c"></b-icon> {{ $t('DESIGNER.title') }}
          <b-badge variant="warning">{{ workflowList.length }}</b-badge>
        </h1>
        <!-- New workflow -->
        <b-icon scale="2" variant="success" class="ml-5 my-1" @click="newWorkflow()" icon="plus-circle-fill"></b-icon>
      </b-col>
      <b-col md="6" class="text-right">
        <!-- Table filter -->
        <b-form-group
          :label="$t('GLOBAL.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 mt-4 text-primary font-weight-bold"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder=""
              style="background-color: #f9e49d"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{ $t('GLOBAL.clear') }}</b-button>
            </b-input-group-append>
            <!-- Refresh -->
            <b-button
                class="ml-2 py-0"
                variant="primary"
                size="sm"
                @click="loading=true;getWorkflowList()"
            >
                <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.refresh') }}
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-2"><!-- Paging -->
      <b-col md="2"></b-col>
      <b-col md="4"><!-- Rows per page -->
        <b-form-group
          :label="$t('GLOBAL.filterPerPage')"
          label-align-sm="right"
          label-size="sm"
          label-cols-sm="4"
          label-cols-md="8"
          label-cols-lg="8"
          label-for="perPageSelect"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="6"><!-- Page selector -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
    <div v-if="!loading">
      <br>
      <div v-if="workflowList.length > 0">
        <b-table striped hover stacked='md'
          outlined bordered
          head-variant="dark"
          :items="workflowList" :fields="workflowFields"
          :filter="filter"
          @filtered="onFiltered"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Header -->
          <template #head(enabled)>{{ $t('DESIGNER.enabled') }}</template>
          <template #head(categoryName)>{{ $t('DESIGNER.category') }}</template>
          <template #head(name)>{{ $t('DESIGNER.workflow') }}</template>
          <template #head(modificationDate)>{{ $t('DESIGNER.version') }}</template>
          <template #head(actions)>{{ $t('GLOBAL.tableActions') }}</template>

          <!-- Column templates -->
          <template v-slot:cell(modificationDate)="data">
            {{ data.item.modificationDate.slice(0, 19) }}
          </template>
          <template v-slot:cell(enabled)="data">
            <div class="text-center">
              <div v-if="data.item.versions[0].enabled === 1"><!-- last version is enabled, ok -->
                <b-icon variant="success" scale="1.3" icon="check-circle-fill" ></b-icon>
              </div>
              <div v-else-if="data.item.versions[0].enabled === 0 && data.item.atLeastOneEnabled === 1"><!-- last version is NOT enabled but another version is enabled -->
                <b-icon variant="warning" scale="1.3" icon="exclamation-triangle-fill" ></b-icon> &nbsp;( {{ $t('DESIGNER.oldVersion') }} )
              </div>
              <b-icon v-else icon="dash"></b-icon>
            </div>
          </template>
          <template v-slot:cell(categoryName)="data">
            <span class="p-1" ><b-icon v-if="data.item.categoryName && data.item.categoryName.length > 0" icon="square-fill" :style="{ color: data.item.categoryColor }"></b-icon> {{ data.item.categoryName }}</span>
          </template>
          <template v-slot:cell(name)="data">
            <span class="text-primary"><b>{{ data.item.name }}</b></span>
          </template>
          <template v-slot:cell(actions)="data"><!-- Show / hide version list -->
            <div class="text-right">
            <b-button size="sm" @click="data.toggleDetails" class="mr-2" variant="primary">
              <b-icon icon="pencil-square"></b-icon> {{ data.detailsShowing ? $t('GLOBAL.hideDetails') : $t('GLOBAL.showDetails') }}
            </b-button>
            </div>
          </template>
          <template #row-details="data"><!-- Version list template -->
            <div v-for="version in data.item.versions" v-bind:key="version.id" class="actions mx-1 my-2 px-2 py-1"><!-- Actions, for each version -->
              <b-icon v-if="version.enabled === 1" variant="success" scale="1.3" icon="check-circle-fill" class="mr-2"></b-icon>
              <b-icon v-else variant="secondary" scale="1.3" icon="circle-fill" class="mr-2"></b-icon>
              {{ $t('DESIGNER.version') }} <span class="text-primary font-weight-bold">{{ version.modificationDate.slice(0, 19) }}</span>&nbsp;
              <span>
                <b-button
                  class="m-1"
                  variant="primary"
                  size="sm"
                  @click="editWorkflow(version.id)"
                >
                  <b-icon icon="gear"></b-icon> {{ $t('DESIGNER.btnEdit') }}
                </b-button>
              </span>&nbsp;&nbsp;
              <span>
                <b-button
                  class="m-1"
                  variant="danger"
                  size="sm"
                  @click="deleteWorkflow(version.id)"
                >
                  <b-icon icon="trash"></b-icon> {{ $t('DESIGNER.btnDelete') }}
                </b-button>
              </span>&nbsp;&nbsp;
              <span v-if="version.enabled">
                <b-button
                  class="m-1"
                  variant="warning"
                  size="sm"
                  @click="publishWorkflow(version.id, 0)"
                >
                  <b-icon icon="arrow-clockwise"></b-icon> {{ $t('DESIGNER.btnRetract') }}
                </b-button>
              </span>
              <span v-else>
                <b-button
                  class="m-1"
                  variant="success"
                  size="sm"
                  @click="publishWorkflow(version.id, 1)"
                >
                  <b-icon icon="cloud-upload"></b-icon> {{ $t('DESIGNER.btnPublish') }}
                </b-button>
              </span>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else><br><b-alert show variant="info"><b-icon icon="info-circle-fill"></b-icon> {{ $t('DESIGNER.noWorkflow') }}</b-alert></div>
    </div>
    <div v-else><br><b-spinner label="Spinning"></b-spinner> {{ $t('GLOBAL.loading') }}</div>
  </div>
</template>

<script>

export default {
  name: 'WorkflowDesignerListPage',
  components: {
  },
  data () {
    return {
      workflowFields: [{
        key: 'enabled',
        label: this.$t('DESIGNER.enabled'),
        sortable: true
      },
      {
        key: 'categoryName',
        label: this.$t('DESIGNER.category'),
        sortable: true
      },
      {
        key: 'name',
        label: this.$t('DESIGNER.workflow'),
        sortable: true
      },
      {
        key: 'modificationDate',
        label: this.$t('DESIGNER.version'),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('GLOBAL.tableActions'),
        sortable: false
      }],
      workflowList: [],
      errorDescription: '',
      loading: true,
      workflowToDelete: null,
      filter: this.$store.state.savedWorkflowsPageParameters.filter, // Filter
      totalRows: this.$store.state.savedWorkflowsPageParameters.totalRows, // Paging
      currentPage: this.$store.state.savedWorkflowsPageParameters.currentPage, // Paging
      perPage: this.$store.state.savedWorkflowsPageParameters.perPage, // Paging
      pageOptions: this.$store.state.savedWorkflowsPageParameters.pageOptions // Paging
    }
  },
  computed: {
  },
  mounted () {
    this.getWorkflowList()
  },
  beforeDestroy () {
    // Save all search / and filter parameters to the store
    this.$store.commit('updateSavedWorkflowsPageParameters', {
      filter: this.filter,
      totalRows: this.totalRows,
      currentPage: this.currentPage,
      perPage: this.perPage,
      pageOptions: [...this.pageOptions]
    })
  },
  methods: {
    newWorkflow () {
      this.$store.commit('designerReset')
      this.$router.push({ name: 'WorkflowDesignerPage' })
    },
    async publishWorkflow (id, enabled) {
      try {
        // Publish / retire
        var publishRetire = this.$t('DESIGNER.infosPublish')
        var publishRetirePast = this.$t('DESIGNER.infosPublished')
        if (enabled === 0) {
          publishRetire = this.$t('DESIGNER.infosRetract')
          publishRetirePast = this.$t('DESIGNER.infosRetracted')
        }
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/enable', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ workflowId: id, enabled: enabled })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode !== 0) {
            this.$bvToast.toast(this.$t('DESIGNER.infosUnableTo') + ' ' + publishRetire + ': ' + content.errorDescription, {
              title: this.$t('DESIGNER.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          } else {
            this.$bvToast.toast(this.$t('DESIGNER.infosSuccessfully') + ' ' + publishRetirePast, {
              title: this.$t('DESIGNER.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-center'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('DESIGNER.infosUnableTo') + ' ' + publishRetire, {
            title: this.$t('DESIGNER.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('DESIGNER.infosUnableTo') + ' ' + publishRetire, {
          title: this.$t('DESIGNER.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }

      // Force a reload of the workflows
      this.getWorkflowList()
    },
    async deleteWorkflow (id) {
      this.workflowToDelete = id // Store the workflow ID for deletion
      await this.$nextTick()
      this.$bvModal.show('confirmDeleteModal') // Confirm dialog
    },
    async deleteWorkflowExecute () {
      try {
        // delete workflow
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/delete', {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ workflowId: this.workflowToDelete })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode !== 0) {
            this.$bvToast.toast('Unable to delete', {
              title: this.$t('DESIGNER.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          } else {
            this.$bvToast.toast(this.$t('DESIGNER.infoDeleted'), {
              title: this.$t('DESIGNER.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-center'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('DESIGNER.infoUnableDelete'), {
            title: this.$t('DESIGNER.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('DESIGNER.infoUnableDelete'), {
          title: this.$t('DESIGNER.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }

      // Force a reload of the workflows
      this.getWorkflowList()
    },
    async editWorkflow (id) {
      try {
        // edit workflow
        // alert(this.$store.state.serviceAPIUrl + 'workflow/load/' + id)
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/load/' + id, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode !== 0) {
            this.$bvToast.toast(this.$t('DESIGNER.infoUnableEdit'), {
              title: this.$t('DESIGNER.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          } else {
            // Load all workflow data and open workflow designer page
            this.$store.commit('designerReset')
            this.$store.commit('designerGraphModelXmlSet', content.workflow.graphModelXml)
            this.$store.commit('designerGraphModelJsonSet', JSON.parse(content.workflow.graphModelJson))
            this.$store.commit('designerBaseData', content.workflow.baseData)

            for (const swimlane in content.workflow.swimlanes) {
              this.$store.commit('designerSwimlaneSet', content.workflow.swimlanes[swimlane])
            }
            for (const attachment in content.workflow.attachments) {
              this.$store.commit('designerAttachmentsSet', content.workflow.attachments[attachment])
            }
            for (const task in content.workflow.tasks) {
              this.$store.commit('designerTaskSet', content.workflow.tasks[task])
            }
            for (const decision in content.workflow.decisions) {
              this.$store.commit('designerDecisionSet', content.workflow.decisions[decision])
            }
            for (const email in content.workflow.emails) {
              this.$store.commit('designerEmailSet', content.workflow.emails[email])
            }
            for (const report in content.workflow.reports) {
              this.$store.commit('designerReportSet', content.workflow.reports[report])
            }
            for (const approval in content.workflow.approvals) {
              this.$store.commit('designerApprovalSet', content.workflow.approvals[approval])
            }
            for (const process in content.workflow.processes) {
              this.$store.commit('designerProcessSet', content.workflow.processes[process])
            }

            // Go to designer page
            this.$router.push({ name: 'WorkflowDesignerPage' })
          }
        } else {
          this.$bvToast.toast(this.$t('DESIGNER.infoUnableEdit'), {
            title: this.$t('DESIGNER.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('DESIGNER.infoUnableEdit'), {
          title: this.$t('DESIGNER.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }

      // Force a reload of the workflows
      this.getWorkflowList()
    },
    async getWorkflowList () {
      // Retrieve the list of workflows
      this.errorDescription = ''
      this.loading = true
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/list', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.workflowList = content.workflowList
          this.totalRows = content.workflowList.length
        } else {
          this.errorDescription = this.$t('DESIGNER.error')
          this.workflowList = []
        }
      } catch (err) {
        this.errorDescription = err
        this.workflowList = []
      }

      this.loading = false
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.actions {
  text-align: right;
}
</style>
