<template>
  <div>
    <h1 class="text-primary"><b-icon icon="shield-exclamation" font-scale="2" class="m-2" variant="danger"></b-icon><br> {{ $t('UNSUBSCRIBE.title') }}</h1>
    {{ $t('ABOUT.tenant') }}<h2><b>{{ this.$store.state.userData.tenant_account_name }}</b></h2>
    <b-row>
      <b-col offset-md="2" md="8">
        <br>
        <div class="text-left bg-danger text-white p-3">
          <ul>
            <li>{{ $t('UNSUBSCRIBE.warning_1') }}</li>
            <li><b>{{ $t('UNSUBSCRIBE.warning_2') }}</b></li>
            <li>{{ $t('UNSUBSCRIBE.warning_3') }}</li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-form-checkbox
                id="checkbox-1"
                v-model="confirmDelete"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
                >
                {{ $t('UNSUBSCRIBE.warning_4') }}
        </b-form-checkbox>
        <br>
        {{ $t('UNSUBSCRIBE.write_tenant') }} ( <span class="text-primary"><i>{{this.$store.state.userData.tenant_account_name}} </i></span>)
        <b-form-input v-model="tenantName" placeholder="" class="my-1"></b-form-input>
        <div v-if="confirmDelete === 'accepted' && tenantName===this.$store.state.userData.tenant_account_name">
        <b-button type="submit" variant="warning" class="m-3" @click="unsubscribe()">
          <b-icon icon="exclamation-triangle-fill" class="mr-2"></b-icon> <b>{{ $t('UNSUBSCRIBE.deleteBtn') }}</b>
        </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'UnsubscribePage',
  data () {
    return {
      confirmDelete: '',
      tenantName: ''
    }
  },
  mounted () {
  },
  methods: {
    async unsubscribe () {
      // Unsubscribe account (delete permanently)

      try {
        // Login with user and password
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'tenant/delete', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ tenantId: this.$store.state.userData.tenant_id })
        })

        // Get the json contents
        const content = await rawResponse.json()

        if (content.errorCode === 0) {
          // Account deleted! Clean user data and token and return to login page
          setTimeout(() => {
            this.$store.commit('setUserData', []) // Reset user data
            this.$store.commit('setUserToken', '') // Reset token
            this.$router.push({ name: 'LoginPage' }) // go to Login page
          }, 5000)

          this.$bvToast.toast(this.$t('UNSUBSCRIBE.done'), {
            title: '',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'info',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        } else {
          // Error
          this.$bvToast.toast(this.$t('UNSUBSCRIBE.error'), {
            title: '',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        // connection problems / invalid request
        this.$bvToast.toast(this.$t('UNSUBSCRIBE.error'), {
          title: '',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
