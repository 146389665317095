<template>
  <div class="taskeditoritem">
    <b-row>
      <b-col md="3" align="left">
        <b>
        <span v-if="item.type === 'number'"><b-icon icon="hash" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.number') }}</span>
        <span v-else-if="item.type === 'text'"><b-icon icon="textarea-t" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.text') }}</span>
        <span v-else-if="item.type === 'date'"><b-icon icon="calendar" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.date') }}</span>
        <span v-else-if="item.type === 'boolean'"><b-icon icon="check-square" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.boolean') }}</span>
        <span v-else-if="item.type === 'list'"><b-icon icon="card-list" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.list') }}</span>
        <span v-else-if="item.type === 'file'"><b-icon icon="file-earmark-arrow-up" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.file') }}</span>
        <span v-else-if="item.type === 'form_text'"><b-icon icon="textarea-t" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.form_text') }}</span>
        <span v-else-if="item.type === 'form_image'"><b-icon icon="image" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.form_image') }}</span>
        <span v-else-if="item.type === 'form_video'"><b-icon icon="collection-play" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.form_video') }}</span>
        <span v-else-if="item.type === 'form_file'"><b-icon icon="file-earmark-arrow-up" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.form_file') }}</span>
        <span v-else-if="item.type === 'reference'"><b-icon icon="link45deg" scale="1.2"></b-icon>&nbsp; {{ $t('DESIGNER.task.type.reference') }}</span>
        </b>
        <br>
        <!-- Remove this item -->
        <b-button
                  class="m-1"
                  variant="info"
                  size="sm"
                  @click="removeItem()"
                >
                  <b-icon icon="trash"></b-icon> {{ $t('DESIGNER.task.btnRemove') }}
        </b-button>
      </b-col>
      <b-col  md="3" align="left">
        <b-form-checkbox v-if="['form_text', 'form_file', 'form_image', 'form_video', 'reference'].indexOf(item.type) === -1"
          v-model="mandatory"
          value=1
          unchecked-value=0
        >
          {{ $t('DESIGNER.task.mandatory') }}
        </b-form-checkbox>
      </b-col>
      <b-col  md="4" >
        <b-input v-model="name" :placeholder="$t('DESIGNER.task.fieldName')" trim ></b-input>
      </b-col>
      <b-col  md="2" align="right">
        <!-- Move Up -->
        <b-icon class="updownArrow mr-1" icon="arrow-up-square-fill" font-scale="1.5" @click="moveUpDown(true)"></b-icon>
        <!-- Move Down -->
        <b-icon class="updownArrow" icon="arrow-down-square-fill" font-scale="1.5" @click="moveUpDown(false)"></b-icon>
        <br>
        <!-- Maximize -->
        <b-iconstack class="minmaxButton mt-1" v-if="isMinimized && item.type !== 'reference'" font-scale="1.5" @click="toggleMinimize()">
          <b-icon stacked icon="square"></b-icon>
          <b-icon stacked icon="plus" class="text-primary"></b-icon>
        </b-iconstack>
        <!-- Minimize -->
        <b-iconstack class="minmaxButton  mt-1" v-if="!isMinimized && item.type !== 'reference'" font-scale="1.5" @click="toggleMinimize()">
          <b-icon stacked icon="square"></b-icon>
          <b-icon stacked icon="dash" class="text-primary"></b-icon>
        </b-iconstack>
      </b-col>
    </b-row>
    <b-row v-if="item.type === 'reference'">
      <b-col><div class="mt-2 mb-1 text-right">{{ $t('DESIGNER.task.referenceDescr') }}: <span class="text-primary"><b>{{ referenceFieldName }}</b></span></div>
      </b-col>
    </b-row>
    <div v-show="!isMinimized">
      <b-row v-if="item.type === 'form_text'">
      </b-row>
      <b-row v-if="item.type === 'form_video'">
        <b-col><span>{{ $t('DESIGNER.task.formVideoDescr') }}</span><br>
        <b-input v-model="defaultValue" class="m-1" type="text" placeholder="" trim ></b-input>
        <div v-if="defaultValue != null && defaultValue.length > 5" class="p-4">
          <video-embed :src="defaultValue"></video-embed>
        </div>
        </b-col>
      </b-row>
      <b-row v-if="['form_file','form_image'].indexOf(item.type) != -1">
        <!-- Contents. Upload of files -->
        <b-col>
          <b-row>
            <b-col md="12">
              <b-form-file
                    v-model="selectedInputFile"
                    :state="Boolean(selectedInputFile)"
                    :placeholder="$t('UPLOAD.placeHolder')"
                    :drop-placeholder="$t('UPLOAD.dropPlaceholder')"
              ></b-form-file>
              <div class="text-right">
                <b-button variant="outline-primary" @click="uploadFile()">
                  <b-icon icon="cloud-upload"></b-icon> {{ $t('UPLOAD.btnUpload') }}
                </b-button>
              </div>
              <!-- Display some file data -->
              <div v-if="fileSize > 0">
                <b-container fluid class="p-4 bg-light text-center">
                  <!-- Render form_image as image if possible -->
                  <b-img v-if="item.type === 'form_image'" center thumbnail fluid :src="$store.state.serviceAPIUrl + 'workflow/image/preview/' + downloadToken" alt=""></b-img>
                  <!-- Direct download -->
                  <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + downloadToken" target="_blank" class="font-weight-bold">
                    <b-icon icon="file-earmark-fill" class="text-red"></b-icon> {{fileOriginalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( fileSize / 1024) }} KB )
                  </a>
                </b-container>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'number'">
        <!-- Contents -->
        <b-col>
          <b-row>
            <b-col md="5">
              <!-- Decimals -->
              <b-form-select v-model="decimals" class="m-1">
                <b-form-select-option :value="null">{{ $t('DESIGNER.task.numberType.empty') }}</b-form-select-option>
                <b-form-select-option :value="0">{{ $t('DESIGNER.task.numberType.0') }}</b-form-select-option>
                <b-form-select-option :value="1">{{ $t('DESIGNER.task.numberType.1') }}</b-form-select-option>
                <b-form-select-option :value="2">{{ $t('DESIGNER.task.numberType.2') }}</b-form-select-option>
                <b-form-select-option :value="3">{{ $t('DESIGNER.task.numberType.3') }}</b-form-select-option>
                <b-form-select-option :value="4">{{ $t('DESIGNER.task.numberType.4') }}</b-form-select-option>
              </b-form-select>
              <b-input v-model="defaultValue" class="m-1" type="text" :placeholder="$t('DESIGNER.task.defaultValue')" trim ></b-input>
            </b-col>
            <b-col md="1"></b-col>
            <b-col md="3"><b-input v-model="minValue" type="number" step="0.0001" :placeholder="$t('DESIGNER.task.minNumber')" trim ></b-input></b-col>
            <b-col md="3"><b-input v-model="maxValue" type="number" step="0.0001" :placeholder="$t('DESIGNER.task.maxNumber')" trim ></b-input></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'text'">
        <!-- Contents -->
        <b-col>
          <b-row>
            <b-col md="3"><b-input v-model="defaultValue" type="text" :placeholder="$t('DESIGNER.task.defaultValue')" trim ></b-input></b-col>
            <b-col md="3" align="left">
              <b-form-checkbox
                v-model="isMultiline"
                value=1
                unchecked-value=0
              >
                {{ $t('DESIGNER.task.multiline') }}
              </b-form-checkbox>
            </b-col>
            <b-col md="3"><b-input v-if="isMultiline==0" v-model="minChars" type="number" min="0" :placeholder="$t('DESIGNER.task.minLen')" trim ></b-input></b-col>
            <b-col md="3"><b-input v-if="isMultiline==0" v-model="maxChars" type="number" min="0" :placeholder="$t('DESIGNER.task.maxLen')" trim ></b-input></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'date'">
        <!-- Contents -->
        <b-col>
          <b-row>
            <b-col md="6">{{ $t('DESIGNER.task.minDate') }}
              <b-form-datepicker v-model="minDate" class="mb-2"
                today-button
                reset-button
                close-button
                :show-decade-nav="true"
                :locale="$i18n.locale">
              </b-form-datepicker>
            </b-col>
            <b-col md="6">{{ $t('DESIGNER.task.daysBefore') }}<b-input v-model="minDateOffsetDays" type="number" placeholder="" min="0" trim ></b-input></b-col>
          </b-row>
          <b-row>
            <b-col md="6">{{ $t('DESIGNER.task.maxDate') }}
              <b-form-datepicker v-model="maxDate" class="mb-2"
                today-button
                reset-button
                close-button
                :show-decade-nav="true"
                :locale="$i18n.locale">
              </b-form-datepicker>
            </b-col>
            <b-col md="6">{{ $t('DESIGNER.task.daysAfter') }}<b-input v-model="maxDateOffsetDays" type="number" placeholder="" min="0" trim ></b-input></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'boolean'">
        <!-- Contents -->
        <b-col>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'list'">
        <!-- Contents -->
        <b-col>
          <hr>
          <!-- Add value -->
          <b-row>
            <b-col md="3">{{ $t('DESIGNER.task.listNewValue') }}</b-col>
            <b-col md="3">
              <b-input v-model="newListValueCode" type="text" :placeholder="$t('DESIGNER.task.listCode')" trim ></b-input>
            </b-col>
            <b-col md="3">
              <b-input v-model="newListValueDisplay" type="text" :placeholder="$t('DESIGNER.task.listDisplay')" trim ></b-input>
            </b-col>
            <b-col md="3" align="left">
              <b-button href="#" variant="primary" @click="addListItem()">{{ $t('DESIGNER.task.listBtnAdd') }}</b-button>
            </b-col>
          </b-row>
          <!-- List of current values -->
          <b-row>{{ item.listValues.size }}
            <b-col v-if="item.listValues && Object.keys(item.listValues).length > 0">{{ $t('DESIGNER.task.listCurrentValues') }}</b-col>
          </b-row>
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="9">
              <ul v-for="element in item.listValues" v-bind:key="element.code">
                <li><b-icon class="text-info mr-2" icon="trash" scale="1.2" @click="removeListItem(element)"></b-icon> <b>{{ element.code }}</b> : {{ element.display }}</li>
              </ul>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              {{ $t('DESIGNER.task.listDefault') }}
            </b-col>
            <b-col md="3">
              <b-input v-model="defaultValue" type="text" placeholder="" trim ></b-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'file'">
        <!-- Contents -->
        <b-col>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <br><span><b>{{ $t('DESIGNER.task.displayText') }}</b></span>
          <EditorHtml v-model="description"></EditorHtml>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// Task editor item
import { arrayBufferToBase64 } from '../common/Utility'
import EditorHtml from '../common/EditorHtml'

export default {
  name: 'TaskEditorItem',
  props: {
    taskId: String,
    item: Object
  },
  components: {
    EditorHtml
  },
  data () {
    return {
      newListValueCode: '', // for type=list, to add new element
      newListValueDisplay: '',
      isMinimized: false,
      selectedInputFile: null
    }
  },
  methods: {
    // Update a single field of this item to the store
    updateItemField (name, value) {
      const copyOfData = { ...this.item }
      copyOfData[name] = value

      const obj = {
        taskId: this.taskId,
        itemId: this.item.id,
        itemValue: copyOfData
      }
      this.$store.commit('designerTaskItemSet', obj)
    },
    removeItem () {
      const obj = { taskId: this.taskId, itemId: this.item.id }
      this.$store.commit('designerTaskItemRemove', obj)
    },
    addListItem () {
      // Add a new value to the component of type="list"
      if (this.newListValueCode.length > 0 && this.newListValueDisplay.length > 0) {
        const copyList = { ...this.item.listValues }
        copyList[this.newListValueCode] = { code: this.newListValueCode, display: this.newListValueDisplay }

        this.updateItemField('listValues', copyList) // Update the list of values
        // Reset current one
        this.newListValueCode = ''
        this.newListValueDisplay = ''
      }
    },
    removeListItem (element) {
      // Remove value from the list
      const copyList = { ...this.item.listValues }
      delete copyList[element.code]
      this.updateItemField('listValues', copyList) // Update the list of values
    },
    toggleMinimize () {
      this.isMinimized = !this.isMinimized
    },
    moveUpDown (upOrDown) {
      // Move the component up or down (re-sort with displayOrder)
      const copyOfData = { ...this.item }

      const obj = {
        moveUp: upOrDown,
        taskId: this.taskId,
        itemId: this.item.id,
        itemValue: copyOfData
      }
      this.$store.commit('designerTaskItemMove', obj)
    },
    async uploadFile () {
      if (this.selectedInputFile != null) {
        var reader = new FileReader()

        reader.onloadend = async (e) => {
          // Callback that is called when finished the reading of the file

          // Call backend service
          try {
            const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/upload', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.$store.state.userToken,
                'Accept-Language': this.$i18n.locale
              },
              body: JSON.stringify({
                type: 'workflow_definition_task',
                workflowId: this.$store.state.designerBaseData.id,
                workflowTaskId: this.taskId,
                fileName: this.selectedInputFile.name,
                fileContents: arrayBufferToBase64(reader.result)
              })
            })

            if (rawResponse.ok) {
              const resContent = await rawResponse.json()
              if (resContent.errorCode === 0) {
                // Upload ok

                // We update 3 fields sequentially, the use of nested nexttick is to prevent loosing fields
                // (old copy of the same not yet updated object)
                this.$nextTick(() => {
                  this.fileIdentifier = resContent.fileId
                  this.$nextTick(() => {
                    this.fileOriginalName = resContent.fileName
                    this.$nextTick(() => {
                      this.downloadToken = resContent.downloadToken
                      this.$nextTick(() => {
                        this.fileSize = resContent.fileSize
                        this.selectedInputFile = null
                      })
                    })
                  })
                })
              } else {
                // An error occurred
                this.$bvToast.toast(resContent.errorDescription, {
                  title: this.$t('DESIGNER.task'),
                  autoHideDelay: 5000,
                  appendToast: false,
                  variant: 'danger',
                  solid: true,
                  toaster: 'b-toaster-top-full'
                })
              }
            }
          } catch (err) {
            // this.errorDescription = err
          }
        }
        reader.readAsArrayBuffer(this.selectedInputFile) // Start reading the local file
      }
    }
  },
  computed: {
    name: {
      get () {
        return this.item.name
      },
      set (value) {
        this.updateItemField('name', value)
      }
    },
    description: {
      get () {
        return this.item.description
      },
      set (value) {
        this.updateItemField('description', value)
      }
    }, /* **************************** Input values ******************************************/
    mandatory: {
      get () {
        return this.item.mandatory
      },
      set (value) {
        this.updateItemField('mandatory', value)
      }
    },
    decimals: {
      get () {
        return this.item.decimals
      },
      set (value) {
        this.updateItemField('decimals', value)
      }
    },
    minChars: {
      get () {
        return this.item.minChars
      },
      set (value) {
        this.updateItemField('minChars', value)
      }
    },
    maxChars: {
      get () {
        return this.item.maxChars
      },
      set (value) {
        this.updateItemField('maxChars', value)
      }
    },
    defaultValue: {
      get () {
        return this.item.defaultValue
      },
      set (value) {
        this.updateItemField('defaultValue', value)
      }
    },
    isMultiline: {
      get () {
        return this.item.isMultiline
      },
      set (value) {
        this.updateItemField('isMultiline', value)
      }
    },
    minValue: {
      get () {
        return this.item.minValue
      },
      set (value) {
        this.updateItemField('minValue', value)
      }
    },
    maxValue: {
      get () {
        return this.item.maxValue
      },
      set (value) {
        this.updateItemField('maxValue', value)
      }
    },
    minDate: {
      get () {
        return this.item.minDate
      },
      set (value) {
        this.updateItemField('minDate', value)
      }
    },
    maxDate: {
      get () {
        return this.item.maxDate
      },
      set (value) {
        this.updateItemField('maxDate', value)
      }
    },
    minDateOffsetDays: {
      get () {
        return this.item.minDateOffsetDays
      },
      set (value) {
        this.updateItemField('minDateOffsetDays', value)
      }
    },
    maxDateOffsetDays: {
      get () {
        return this.item.maxDateOffsetDays
      },
      set (value) {
        this.updateItemField('maxDateOffsetDays', value)
      }
    }, /* **************************** Form values ******************************************/
    fileIdentifier: {
      get () {
        return this.item.fileIdentifier
      },
      set (value) {
        this.updateItemField('fileIdentifier', value)
      }
    },
    fileOriginalName: {
      get () {
        return this.item.fileOriginalName
      },
      set (value) {
        this.updateItemField('fileOriginalName', value)
      }
    },
    fileSize: {
      get () {
        return this.item.fileSize
      },
      set (value) {
        this.updateItemField('fileSize', value)
      }
    },
    downloadToken: {
      get () {
        return this.item.downloadToken
      },
      set (value) {
        this.updateItemField('downloadToken', value)
      }
    },
    displayOrder: {
      get () {
        return this.item.displayOrder
      },
      set (value) {
        this.updateItemField('displayOrder', value)
      }
    },
    referenceFieldName: {
      get () {
        // Retrieve task name and field name for referenced fields
        if (this.item.type !== 'reference') {
          return ''
        } else {
          for (const task in this.$store.state.designerTasks) {
            for (const item in this.$store.state.designerTasks[task].items) {
              if (task === this.item.referencedTaskId && this.$store.state.designerTasks[task].items[item].id === this.item.referencedItemId) {
                return this.$store.state.designerTasks[task].name + '.' + this.$store.state.designerTasks[task].items[item].name
              }
            }
          }
        }
        return ''
      }
    }
  },
  mounted () {
    // alert('task editor')
  },
  beforeDestroy () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.taskeditoritem {
  border: 1px solid #b5b9b5;
  margin: 10px 5px;
  padding: 10px;
  background-color: #ffffff;
}
.taskeditoritem input {
  margin:5px;
}
.taskeditoritem .btn {
  margin:5px;
}
.minmaxButton {
  color:rgb(56, 56, 56);
}
.updownArrow {
  color:#4f584e;
}

</style>
