<template>
  <div>
    <br>
    <b-row>
      <b-col sm="12" md="6" lg="2" class="text-right text-primary mb-2">{{ $t('DESIGNER.category') }}
      </b-col>
      <b-col sm="12" md="6" lg="6" class="mb-2">
        <b-form-select v-model="categoryId" :options="categoryList" value-field="id" text-field="name"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6" lg="2" class="text-right text-primary">{{ $t('DESIGNER.workflow') }}
      </b-col>
      <b-col sm="12" md="6" lg="6">
        <!-- Name -->
        <b-form-group
                class="mb-0"
                label=""
              >
                <b-input v-model="name" placeholder="" required trim ></b-input>
              </b-form-group>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="8" class="m-1">
        <!-- Description -->
        <h4>{{ $t('DESIGNER.main.description') }}</h4>
        <EditorHtml v-model="description"></EditorHtml>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="8">
        <!-- Attachments -->
        <br><h4>{{ $t('DESIGNER.main.newAttachment') }}</h4>
        <b-form-file
                v-model="selectedInputFile"
                :state="Boolean(selectedInputFile)"
                ref="file-input"
                :placeholder="$t('UPLOAD.placeHolder')"
                :drop-placeholder="$t('UPLOAD.dropPlaceholder')"
        >
        </b-form-file>
        <div align="right" class="m-1">
          <b-button variant="outline-primary" @click="uploadFile()">
            <b-icon icon="cloud-upload"></b-icon> {{ $t('UPLOAD.btnUpload') }}
          </b-button>
        </div>
        <h4 v-if="Object.keys($store.state.designerAttachments).length">{{ $t('DESIGNER.main.currentAttachments') }}</h4>
        <ol class="m-1">
          <li v-for="att in $store.state.designerAttachments" v-bind:key="att.id">
            <b-button href="#" variant="primary" class="m-1 mr-3" @click="removeAttachment(att.id)">
                <b-icon icon="trash"></b-icon>
            </b-button>
            <a :href="$store.state.serviceAPIUrl + 'workflow/download/' + att.downloadToken" target="_blank">
              {{att.originalName}}&nbsp;&nbsp;&nbsp;( {{ Math.round( att.fileSize / 1024) }} KB )
            </a>
          </li>
        </ol>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EditorHtml from '../common/EditorHtml'
import { arrayBufferToBase64 } from '../common/Utility'

// Form editor
export default {
  name: 'MainDataEditor',
  props: {
    categoryList: Array
  },
  data () {
    return {
      selectedInputFile: null // Placeholder for adding attachment (at workflow level)
    }
  },
  components: {
    EditorHtml
  },
  methods: {
    async uploadFile () {
      if (this.selectedInputFile != null) {
        var reader = new FileReader()

        reader.onloadend = async (e) => {
          // Callback that is called when finished the reading of the file
          try {
            // Call the backend service
            const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/upload', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.$store.state.userToken,
                'Accept-Language': this.$i18n.locale
              },
              body: JSON.stringify({
                type: 'workflow_definition',
                workflowId: this.$store.state.designerBaseData.id,
                fileName: this.selectedInputFile.name,
                fileContents: arrayBufferToBase64(reader.result)
              })
            })
            if (rawResponse.ok) {
              const resContent = await rawResponse.json()
              if (resContent.errorCode === 0) {
                // Upload ok

                // Add to the list of attachments
                const obj = {
                  id: resContent.fileId,
                  originalName: resContent.fileName,
                  fileSize: resContent.fileSize,
                  downloadToken: resContent.downloadToken
                }

                this.$store.commit('designerAttachmentsSet', obj)
              } else {
                // An error occurred
                this.$bvToast.toast(resContent.errorDescription, {
                  title: this.$t('UPLOAD.btnUpload'),
                  autoHideDelay: 5000,
                  appendToast: false,
                  variant: 'danger',
                  solid: true,
                  toaster: 'b-toaster-top-full'
                })
              }
            }
            // this.$refs['file-input'].reset()
            this.selectedInputFile = null
          } catch (err) {
            this.$bvToast.toast(err, {
              title: this.$t('UPLOAD.btnUpload'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        }
        reader.readAsArrayBuffer(this.selectedInputFile) // Start reading the local file
      }
    },
    removeAttachment (id) {
      this.$store.commit('designerAttachmentsRemove', id)
    }
  },
  mounted () {
    // alert('decision editor')
  },
  computed: {
    name: {
      get () {
        return this.$store.state.designerBaseData.name
      },
      set (value) {
        const copyOfData = { ...this.$store.state.designerBaseData, name: value }
        this.$store.commit('designerBaseData', copyOfData)
      }
    },
    description: {
      get () {
        return this.$store.state.designerBaseData.description
      },
      set (value) {
        const copyOfData = { ...this.$store.state.designerBaseData, description: value }
        this.$store.commit('designerBaseData', copyOfData)
      }
    },
    categoryId: {
      get () {
        return this.$store.state.designerBaseData.categoryId
      },
      set (value) {
        const copyOfData = { ...this.$store.state.designerBaseData, categoryId: value }
        this.$store.commit('designerBaseData', copyOfData)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
