<template>
    <b-container fluid>
        <b-row>
           <!-- Header menu -->
          <b-col>
            <b-navbar toggleable="lg" type="dark" class="menuTop navbar navbar-expand-lg navbar-dark mainMenu" fixed="top">
              <b-navbar-brand :to="{ name: 'HomePage' }">
                <div><img src="../../assets/LogoLG_W.png" style="height:2.5rem"></div>
              </b-navbar-brand>

              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                  <!-- Right aligned nav items -->
                  <b-navbar-nav class="ml-auto">
                    <!-- menus (replica) -->
                    <b-nav-item-dropdown text="Actions" class="my-2 my-lg-0 .d-none">
                      <template v-slot:button-content>
                        <span class="text-white"><b-icon icon="lightning-fill" style="color: yellow"></b-icon> <b>{{ $t('MAIN.actions') }}</b></span>
                      </template>
                      <b-dropdown-item class="bg-secondary">
                        <b-link :to="{ name: 'MyWorkflows' }"><b-icon icon="grid" font-scale="1" class="iconMyWorkflows iconTop p-1"></b-icon><span> {{ $t('MAIN.menuMyWorkflows') }}</span></b-link>
                      </b-dropdown-item>
                      <b-dropdown-item class="bg-secondary">
                        <b-link :to="{ name: 'Processes' }"><b-icon icon="gear-wide-connected" font-scale="1" class="iconProcesses iconTop p-1"></b-icon><span> {{ $t('MAIN.menuProcesses') }}</span></b-link>
                      </b-dropdown-item>
                      <b-dropdown-item class="bg-secondary">
                        <b-link :to="{ name: 'MyTasks' }"><b-icon icon="puzzle-fill" font-scale="1" class="iconMyTasks iconTop p-1"></b-icon><span> {{ $t('MAIN.menuMyTasks') }}</span></b-link>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="this.$store.state.userData.is_admin == 1" class="bg-secondary">
                        <b-link :to="{ name: 'ManageUsersPage' }"><b-icon icon="person-fill" font-scale="1" class="iconManage iconTop p-1"></b-icon><span> {{ $t('MAIN.menuUsers') }}</span></b-link>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="this.$store.state.userData.is_admin == 1" class="bg-secondary">
                        <b-link :to="{ name: 'ManageGroupsPage' }"><b-icon icon="people-fill" font-scale="1" class="iconManage iconTop p-1"></b-icon><span> {{ $t('MAIN.menuGroups') }}</span></b-link>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="this.$store.state.userData.is_admin == 1" class="bg-secondary">
                        <b-link :to="{ name: 'ManageCategoryPage' }"><b-icon icon="stickies" font-scale="1" class="iconManage iconTop p-1"></b-icon><span> {{ $t('MAIN.menuCategories') }}</span></b-link>
                      </b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-text class="user">{{ this.$store.state.userData.user_email }}</b-nav-text>
                    <b-nav-item-dropdown right>
                      <!-- Using 'button-content' slot -->
                      <template v-slot:button-content>
                        <span> </span>
                      </template>
                      <b-nav-form><LanguageSelector></LanguageSelector></b-nav-form><!-- Language -->
                      <b-dropdown-item-button @click="doAbout">{{ $t('MAIN.menuAbout') }}</b-dropdown-item-button>
                      <b-dropdown-item-button @click="doLogout">{{ $t('MAIN.menuLogout') }}</b-dropdown-item-button>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </b-collapse>
            </b-navbar>
          </b-col>
        </b-row>
        <b-row style="margin-top: 58px">
            <!-- Menu left space placeholder -->
            <b-col sm="12" lg="1" class="menuLeft d-none d-lg-block vh-100"><!-- Hide when lower than lg-->
              <!-- fixed position, stay here but is not here -->
              <b-col sm="12" lg="1" class="mx-0 pl-1 pt-3 text-left fixedMenuLeft d-none d-lg-block vh-100">
                <!-- My workflows -->
                <b-link :to="{ name: 'MyWorkflows' }">
                  <b-icon scale="1.25" icon="grid" class="iconMyWorkflows iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuMyWorkflows') }}</span>
                </b-link>
                 <!-- Processes -->
                <b-link :to="{ name: 'Processes' }">
                  <hr class="m-2">
                  <b-icon scale="1.25" icon="gear-wide-connected" class="iconProcesses iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuProcesses') }}</span>
                </b-link>
                <!-- My tasks -->
                <b-link :to="{ name: 'MyTasks' }">
                  <hr class="m-2">
                  <b-icon scale="1.25" icon="puzzle-fill" class="iconMyTasks iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuMyTasks') }}</span>
                </b-link>
                <br><br>
                <!-- Workflow designer list -->
                <b-link :to="{ name: 'WorkflowDesignerListPage', params: { fromAction: 'fromMasterPage' } }">
                  <hr class="m-2">
                  <b-icon scale="1.25" icon="cloud-fill" class="iconWorkflowList iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuSavedWorkflows') }}</span>
                </b-link>
                <!-- User list -->
                <b-link v-if="this.$store.state.userData.is_admin == 1" :to="{ name: 'ManageUsersPage' }">
                  <hr class="m-2">
                  <br>
                  <b-icon scale="1.25" icon="person-fill" class="iconManage iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuUsers') }}</span>
                </b-link>
                <!-- User groups -->
                <b-link v-if="this.$store.state.userData.is_admin == 1" :to="{ name: 'ManageGroupsPage' }">
                  <hr class="m-2">
                  <b-icon scale="1.25" icon="people-fill" class="iconManage iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuGroups') }}</span>
                </b-link>
                <!-- Workflow categories -->
                <b-link v-if="this.$store.state.userData.is_admin == 1" :to="{ name: 'ManageCategoryPage' }">
                  <hr class="m-2">
                  <b-icon scale="1.25" icon="stickies" class="iconManage iconLeft mr-1"></b-icon>
                  <span class="iconText">{{ $t('MAIN.menuCategories') }}</span>
                </b-link>
                <br><br>
              </b-col>
            </b-col>
            <!-- Body of the page (child) -->
            <b-col sm="12" lg="11" style="margin-top:10px"><br><router-view></router-view></b-col>
        </b-row>
        <b-row>
          <!-- same background color as the menu left -->
          <b-col sm="12" lg="1" class="menuLeft d-none d-lg-block"><!-- Hide when lower than lg-->
          </b-col>
          <b-col>
            <div class="footer">
            www.digiwi.ch
            </div>
          </b-col>
        </b-row>
    </b-container>
</template>

<script>
import LanguageSelector from './LanguageSelector'

export default {
  name: 'MasterPage',
  data () {
    return {
      msg: ''
    }
  },
  components: {
    LanguageSelector
  },
  methods: {
    doAbout () {
      this.$router.push({ name: 'AboutPage' })
    },
    async doLogout () {
      // Force a logout
      try {
        // Logout
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/logout', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify(null)
        })
        // Get the json contents
        const content = await rawResponse.json()
        // Complete the credentials with the token if login successful
        if (content.errorCode === 0) {
          // Logout succesful, reset user variables
          this.$store.commit('setUserData', [])
          this.$store.commit('setUserToken', '')
        } else {
          // Logout failure
        }
      } catch (err) {
        // Logout failed
      }

      // Go to the login page (in all situations)
      this.$router.push({ name: 'LoginPage' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fixedMenuLeft {
  position: fixed;
  top: 65px;
  left: 0px;
  height: 2000px;
  border-right: 10px solid #e5e6e7;
}
.mainMenu {
  background: rgb(0,60,80);
  background: linear-gradient(90deg, rgba(93,200,236,1) 0%, rgba(0,140,186,1) 35%, rgba(0,60,80,1) 100%);
}
.footer {
  color: var(--primary);
  text-align: right;
  margin-top:60px;
  margin-bottom:10px;
}
.user {
  color: var(--primary);
  font-size: 15px;
  font-weight: bold;
}
.menuLeft {
  // background-color: #f2f4f4;
  // background-color: var(--light);
  // background: linear-gradient(90deg, #c9c8c8 0%, #ffffff 60%, #e2dfdf 100%);
  text-align:left;
}
.iconMyWorkflows {
  color: #a470e6;
}
.iconProcesses {
  color: #3395e6;
}
.iconMyTasks {
  color: #fad145;
}
.iconWorkflow {
  color: #9f6df0;
}
.iconWorkflowList {
  color: #8edf9c;
}
.iconManage {
  color:#ff7725;
}
.iconLeft {
  width:30%;
}
.iconTop {
  font-weight: bold;
}
.iconText {
  color: var(--primary);
  font-size: 0.6em;
  display:inline;
}
</style>
