<template>
  <div id="approveeditplaceholder">
    <b-row>
      <b-col xs="12" lg="2">
        <b-button
            variant="secondary"
            size="sm"
            class="mb-1"
            @click="$router.push({ name: 'MyTasks' })"
          >
            <b-icon icon="backspace"></b-icon> {{ $t('APPROVE.btnBack') }}
        </b-button>
        <div class="text-primary"><b>{{ $t('APPROVE.title') }}</b></div>
        <h2>{{ $store.state.currentApproval.approvalDefName }}</h2>
      </b-col>
      <b-col xs="12" lg="8">
        <b-modal id="reasonCheckModal" size="sm" :title="$t('APPROVE.title')">
          <b-icon variant="warning" icon="exclamation-triangle-fill"></b-icon> {{ $t('APPROVE.missingReason') }}
        </b-modal>
        <div id="approveactions">
          <p><i>{{ $t('APPROVE.createdOn') }} {{ $store.state.currentApproval.approvalCreationDate.slice(0,19) }}</i></p>
            <b-button
              variant="danger"
              size="sm"
              class="m-1"
              @click="approveReject(0)"
            >
              <b-icon icon="hand-thumbs-down"></b-icon> {{ $t('APPROVE.btnReject') }}
            </b-button>
            <b-button
              variant="success"
              size="sm"
              class="m-1"
              @click="approveReject(1)"
            >
              <b-icon icon="hand-thumbs-up"></b-icon> {{ $t('APPROVE.btnApprove') }}
            </b-button>
          </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" md="2" lg="3"></b-col>
      <b-col xs="12" md="8" lg="6">
        <b style="color:#dc3545">{{ $t('APPROVE.reason') }}</b>
        <b-form-textarea
          id="reason"
          v-model="reason"
          :placeholder="$t('APPROVE.reasonHelper')"
          rows="3"
          max-rows="6"
          style="background-color:var(--secondary);border:2px solid var(--danger)"
        ></b-form-textarea>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col xs="12" md="12" lg="2"></b-col>
      <b-col xs="12" md="12" lg="8">
        <br>
        <div id="approveinputform" >
          <ViewProcess :processId="$store.state.currentApproval.processId" :hideActions=true></ViewProcess>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ViewProcess from '../common/ViewProcess' // View the whole process data

export default {
  name: 'ApproveEditPage',
  components: {
    ViewProcess
  },
  data () {
    return {
      reason: ''
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    async approveReject (action) {
      if (this.reason.trim().length === 0) {
        this.$bvModal.show('reasonCheckModal') // Open modal to display single process
        return
      }

      // Do approval or rejection
      const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'approve/approval', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.$store.state.userToken,
          'Accept-Language': this.$i18n.locale
        },
        body: JSON.stringify({
          workflowId: this.$store.state.currentApproval.workflowId,
          approvalId: this.$store.state.currentApproval.approvalDefId,
          processId: this.$store.state.currentApproval.processId,
          processApprovalId: this.$store.state.currentApproval.approvalId,
          action: action,
          reason: this.reason
        })
      })
      if (rawResponse.ok) {
        // Get the json contents
        const content = await rawResponse.json()

        if (content.errorCode === 0) {
          // this.$router.push({ name: 'MyTasks' }) // Return to my task page
          // alert(JSON.stringify(this.$store.state.currentApproval))
          this.$store.commit('setNextTasksData', { processId: this.$store.state.currentApproval.processId, rootProcessId: this.$store.state.currentApproval.processRootId, sourceObject: this.$store.state.currentApproval.approvalDefName, sourcePage: 'approval' })
          // alert(JSON.stringify(this.$store.state.nextTasksData))
          this.$router.push({ name: 'NextTasks' })
        } else {
          this.$bvToast.toast(content.errorDescription, {
            title: this.$t('APPROVE.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } else {
        this.$bvToast.toast('Unable to process', {
          title: this.$t('APPROVE.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#approveeditplaceholder {
  text-align: left;
}
#approveactions {
  text-align: right;
}
#approveinputform {
  margin-top:10px;
  padding:10px;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  border: 10px solid rgb(141, 132, 132);
}
</style>
