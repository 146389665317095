<template>
  <div>
    <h4 class="mt-2">{{ $t('DESIGNER.decision.task') }}: <span class="text-primary">{{ theData.name }}</span></h4>

    <b-row><!-- full formula description -->
      <b-col sm="12" lg="2">
        <div id="formulaplaceholdertitle" class="pt-4 pl-4 pr-4 pb-4">
          <b>{{ $t('DESIGNER.decision.formula') }}</b>
          <b-button href="#" variant="warning" @click="buildDescribeRule()"><b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('DESIGNER.decision.btnCheck') }}</b-button><br><br>
        </div>
      </b-col>
      <b-col sm="12" lg="10">
        <div id="formulaplaceholder" class="pt-4 pl-4 pr-4 pb-4">
          <span v-html="describeRule"></span>
        </div>
      <br><br>
      </b-col>
    </b-row>

    <b-button href="#" variant="primary" class="m-1" @click="addDecisionRule()"><b-icon icon="plus-circle-fill"></b-icon> {{ $t('DESIGNER.decision.btnAddRule') }}</b-button>
    <b-button href="#" variant="primary" class="m-1" @click="addProcessRule()"><b-icon icon="plus-circle-fill"></b-icon> {{ $t('DESIGNER.decision.btnAddProcessResult') }}</b-button><br><br>

    <!-- Header -->
    <b-row v-if="Object.keys(theData.rules).length > 0">
      <b-col></b-col>
      <b-col><h4>{{ $t('DESIGNER.decision.source') }}</h4></b-col>
      <b-col><h4>{{ $t('DESIGNER.decision.operator') }}</h4></b-col>
      <b-col><h4>{{ $t('DESIGNER.decision.value') }}</h4></b-col>
      <b-col><h4>{{ $t('DESIGNER.decision.group') }}</h4></b-col>
      <b-col></b-col>
    </b-row>

    <!-- Display a DecisionEditorItem for each rule -->
    <div v-for="rule in theData.rules" v-bind:key="rule.id">
      <DecisionEditorItem :decisionId="theData.id" :rule="rule" :removeCallback="removeRule"></DecisionEditorItem>
    </div>

    <!-- Display a DecisionEditorProcessItem for each rule -->
    <div v-for="process in theData.processes" v-bind:key="process.id">
      <DecisionEditorProcessItem :decisionId="theData.id" :process="process" :removeCallback="removeProcessRule"></DecisionEditorProcessItem>
    </div>

    <b-row>
      <b-col>
        <br><h3>{{ $t('DESIGNER.decision.description') }}</h3>
        <EditorHtml v-model="description"></EditorHtml>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DecisionEditorItem from './DecisionEditorItem'
import DecisionEditorProcessItem from './DecisionEditorProcessItem'
import { uuid } from 'vue-uuid'
import EditorHtml from '../common/EditorHtml'

// Form editor
export default {
  name: 'DecisionEditor',
  data () {
    return {
      describeRule: '',
      describeFunctionNames: {
        text_eq: this.$t('DESIGNER.decision.function.text_eq'),
        text_ne: this.$t('DESIGNER.decision.function.text_ne'),
        text_contain: this.$t('DESIGNER.decision.function.text_contain'),
        text_notcontain: this.$t('DESIGNER.decision.function.text_notcontain'),
        text_start: this.$t('DESIGNER.decision.function.text_start'),
        text_end: this.$t('DESIGNER.decision.function.text_end'),
        list_eq: '=',
        list_ne: '<>',
        number_eq: '=',
        number_ne: '<>',
        number_lt: '<',
        number_lte: '<=',
        number_gt: '>',
        number_gte: '>=',
        boolean_eq: '=',
        boolean_ne: '<>',
        date_eq: '=',
        date_ne: '<>',
        date_lt: '<',
        date_lte: '<=',
        date_gt: '>',
        date_gte: '>='
      },
      describeVariableNames: {}
    }
  },
  props: {
    theData: Object
  },
  components: {
    DecisionEditorItem,
    DecisionEditorProcessItem,
    EditorHtml
  },
  methods: {
    addProcessRule () {
      // Add a new empty decision rule for process result
      const ruleId = uuid.v4() // Generate new id

      const currentDecision = { ...this.theData }
      currentDecision.processes[ruleId] = {
        id: ruleId,
        processTaskId: null, // Source variable
        processResult: null, // Type of function
        ruleGroup: null // To group them with "or" clause
      }

      this.$store.commit('designerDecisionSet', currentDecision) // Replace with new decision (containing a new empty rule)
    },
    removeProcessRule (id) {
      // Remove a single process rule
      const currentDecision = { ...this.theData }
      this.$delete(currentDecision.processes, id)
      this.$store.commit('designerDecisionSet', currentDecision)
    },
    addDecisionRule () {
      // Add a new empty decision rule for field
      const ruleId = uuid.v4() // Generate new id

      const currentDecision = { ...this.theData }
      currentDecision.rules[ruleId] = {
        id: ruleId,
        ruleVariable: null, // Source variable
        ruleFunction: null, // Type of function
        ruleVariableType: 'M', // M=manual value, V=with another variable
        ruleValueManual: null,
        ruleValueVariable: null, // Referenced variable
        ruleGroup: null // To group them with "or" clause
      }

      this.$store.commit('designerDecisionSet', currentDecision) // Replace with new decision (containing a new empty rule)
    },
    removeRule (id) {
      // Remove a single rule
      const currentDecision = { ...this.theData }
      this.$delete(currentDecision.rules, id)
      this.$store.commit('designerDecisionSet', currentDecision)
    },
    buildDescribeRule () {
      // Describe all the rules with a formula...
      var rules = this.$store.state.designerDecisions[this.theData.id].rules // Condition over fields
      var processResults = this.$store.state.designerDecisions[this.theData.id].processes // sub process results
      var orGroups = {}

      this.describeRule = ''

      // Group rules in OR groups
      for (const rule in rules) {
        let group = ''
        if (rules[rule].ruleGroup) {
          group = rules[rule].ruleGroup
        }
        if (!(group in orGroups)) {
          orGroups[group] = []
        }
        orGroups[group].push(rules[rule])
      }
      // Add to the groups also the sub process results
      for (const processResult in processResults) {
        let group = ''
        if (processResults[processResult].ruleGroup) {
          group = processResults[processResult].ruleGroup
        }
        if (!(group in orGroups)) {
          orGroups[group] = []
        }
        orGroups[group].push(processResults[processResult])
      }
      this.describeRule = '<b>IF</b><br> '
      var idxGroup = 0
      var idxAnd = 0
      for (const group in orGroups) { // Each group
        if (idxGroup > 0) {
          this.describeRule += '<br><b>OR</b> <br>'
        }
        idxAnd = 0
        this.describeRule += '('
        for (const rule in orGroups[group]) { // Each rule
          if (idxAnd > 0) {
            this.describeRule += '<br>&nbsp;&nbsp;<b>AND (</b>'
          } else {
            this.describeRule += '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b> (</b>'
          }
          const ruleValue = orGroups[group][rule]
          this.describeRule += '&nbsp;&nbsp;&nbsp;&nbsp;'

          let ruleVariable = 'undefined'
          if (ruleValue.processTaskId) {
            // Sub process results. e.g. [mysuprocess] Result is "myvalue"
            this.describeRule += '<span style="color:#4066e0">[' + this.listOfAllSubprocesses[ruleValue.processTaskId] + ']</span>'
            this.describeRule += ' <span style="color:#800080">' + this.$t('DESIGNER.decision.process.result') + '</span>'
            this.describeRule += ' <span style="color:#ff007f">"' + ruleValue.processResult + '"</span> '
          } else {
            // Rule on fields
            if (ruleValue.ruleVariable) {
              ruleVariable = this.describeVariableNames[ruleValue.ruleVariable]
              this.describeRule += '<span style="color:#4066e0">[' + ruleVariable.task + '.' + ruleVariable.name + ']</span> '
              this.describeRule += '<span style="color:#800080">' + this.describeFunctionNames[ruleVariable.type + '_' + ruleValue.ruleFunction] + '</span> ' // Decode function code to display value (type + _ + code)
            } else {
              this.describeRule += '<span style="color:#4066e0">undefined</span> '
              this.describeRule += '<span style="color:#800080">undefined</span> '
            }

            if (ruleValue.ruleVariableType === 'M') {
              if (ruleValue.ruleValueManual) {
                this.describeRule += '<span style="color:#ff007f">"' + ruleValue.ruleValueManual + '"</span> '
              } else {
                this.describeRule += '<span style="color:#ff007f">""</span> '
              }
            } else {
              if (ruleValue.ruleValueVariable) {
                ruleVariable = this.describeVariableNames[ruleValue.ruleValueVariable]
                this.describeRule += '<span style="color:#4066e0">[' + ruleVariable.task + '.' + ruleVariable.name + ']</span> ' + ' '
              } else {
                this.describeRule += '<span style="color:#4066e0">undefined variable</span> ' + ' '
              }
            }
          }
          idxAnd += 1
          this.describeRule += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b> )</b>'
        }
        this.describeRule += '<br>)'
        idxGroup += 1
      }
      this.describeRule += '<br> <b>THEN</b> return TRUE<br><b>ELSE</b> return FALSE'
    }
  },
  mounted () {
    // Fill describeVariableNames
    for (const task in this.$store.state.designerTasks) {
      const taskName = this.$store.state.designerTasks[task].name
      for (const item in this.$store.state.designerTasks[task].items) {
        const myVar = this.$store.state.designerTasks[task].items[item]

        if (taskName.length > 0 && myVar.name.length > 0 && ['number', 'text', 'date', 'boolean', 'list'].includes(myVar.type)) {
          this.describeVariableNames[myVar.id] = { task: taskName, name: myVar.name, type: myVar.type }
        }
      }
    }
    this.$nextTick(() => {
      this.buildDescribeRule()
    })
  },
  computed: {
    listOfAllSubprocesses: {
      get () {
        // Retrieve the list of all the subprocesses
        var allSubprocesses = {}
        for (const process in this.$store.state.designerProcesses) {
          allSubprocesses[this.$store.state.designerProcesses[process].id] = this.$store.state.designerProcesses[process].name
        }
        return allSubprocesses
      }
    },
    description: {
      get () {
        return this.theData.description
      },
      set (value) {
        const copyOfData = { ...this.theData, description: value }
        this.$store.commit('designerDecisionSet', copyOfData)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#formulaplaceholdertitle {
  border: 1px solid black;
  background-color:rgb(230, 230, 230);
}
#formulaplaceholder {
  border: 1px solid black;
  background-color:rgb(230, 230, 230);
}
</style>
