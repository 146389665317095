<template>
  <b-row>
    <b-col sm="12" md="4">
      <!-- Informations -->
      <br>{{ $t('DESIGNER.task') }}<br>
      <span class="text-primary"><h3>{{ theData.name }}</h3></span>
      <div class="mt-1">{{ $t('DESIGNER.task.description') }}
        <b-form-textarea v-model="description" trim rows="3" max-rows="6"></b-form-textarea>
      </div>
      <br>
      <!-- Toolbar -->
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block href="#" v-b-toggle.accordion-1 variant="primary"><b-icon class="mr-2" style="color: var(--light)" icon="file-text"></b-icon> {{ $t('DESIGNER.task.formElements') }}</b-button>
          </b-card-header>
          <!-- Tab components -->
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="textarea-t" type="form_text" :menuText="$t('DESIGNER.task.type.form_text')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="image" type="form_image" :menuText="$t('DESIGNER.task.type.form_image')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="collection-play" type="form_video" :menuText="$t('DESIGNER.task.type.form_video')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="file-earmark-arrow-up" type="form_file" :menuText="$t('DESIGNER.task.type.form_file')"></TaskEditorToolbarItem>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block href="#" v-b-toggle.accordion-2 variant="primary"><b-icon class="mr-2" style="color: var(--light)" icon="pencil-square"></b-icon> {{ $t('DESIGNER.task.inputElements') }}</b-button>
          </b-card-header>
          <!-- Tab components -->
          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="hash" type="number" :menuText="$t('DESIGNER.task.type.number')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="textarea-t" type="text" :menuText="$t('DESIGNER.task.type.text')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="calendar" type="date" :menuText="$t('DESIGNER.task.type.date')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="check-square" type="boolean" :menuText="$t('DESIGNER.task.type.boolean')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="card-list" type="list" :menuText="$t('DESIGNER.task.type.list')"></TaskEditorToolbarItem>
              <TaskEditorToolbarItem :clickCallback="addComponent" menuIcon="file-earmark-arrow-up" type="file" :menuText="$t('DESIGNER.task.type.file')"></TaskEditorToolbarItem>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Elements from other tasks -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block href="#" v-b-toggle.accordion-3 variant="primary"><b-icon class="mr-2" style="color: var(--light)" icon="link45deg"></b-icon> {{ $t('DESIGNER.task.referencedElements') }}</b-button>
          </b-card-header>
          <!-- Tab external components -->
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <span class="text-primary"><b>{{ $t('DESIGNER.task.sourceField') }}</b></span>
                <b-form-select v-model="currentReferencedField" :options="listOfAllReferenceableFields"></b-form-select>
                <div class="text-right">
                  <b-button v-if="currentReferencedField" class="mt-2" href="#" variant="primary" @click="addComponent('reference')">
                    <span><b-icon icon="forward-fill" scale="1" ></b-icon></span>
                  </b-button>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-col>
    <b-col sm="12" md="8">
      <!-- Body - form - display components -->
      <br>
      <div id="formbodyplaceholder">
        <span class="text-primary"><i>-- {{ $t('DESIGNER.task.formTitle') }} --</i></span>
        <div v-for="item in sortedComponents" v-bind:key="item.id">
          <!-- Display a TaskEditorItem for each element
               Order with field displayOrder -->
          <TaskEditorItem :taskId="theData.id" :item="item"></TaskEditorItem>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { uuid } from 'vue-uuid'
import TaskEditorItem from './TaskEditorItem.vue'
import TaskEditorToolbarItem from './TaskEditorToolbarItem'

// Form editor
export default {
  name: 'TaskEditor',
  props: {
    theData: Object
  },
  components: {
    TaskEditorItem,
    TaskEditorToolbarItem
  },
  data () {
    return {
      currentReferencedField: null // task id | field id | task name | field name
    }
  },
  methods: {
    addComponent (type) {
      // Add the component to the form
      // alert(type)
      const id = uuid.v4() // Generate new id

      // Generate a default name, Field NUMBER, e.g. Field 10.
      // Generate a default sorting (displayOrder)
      // Be sure not to generate an already existing name.
      let maxFieldNumber = 0
      let maxDisplayOrder = 0
      for (const item in this.$store.state.designerTasks[this.theData.id].items) {
        const curItem = this.$store.state.designerTasks[this.theData.id].items[item]
        if (curItem.name.startsWith('Field')) {
          let fieldNumber = parseInt(curItem.name.split(' ')[1]) // Extract the number "Field 567"

          if (isNaN(fieldNumber)) {
            fieldNumber = 0 // If empty or not a number, force to 0
          }

          if (fieldNumber > maxFieldNumber) {
            maxFieldNumber = fieldNumber // Store if greater
          }
        }
        // Set a number that is bigger than ALL the others (to have in last position)
        if (curItem.displayOrder > maxDisplayOrder) {
          maxDisplayOrder = curItem.displayOrder
        }
      }
      const defaultName = 'Field ' + (maxFieldNumber + 1) // Set name
      const defaultDisplayOrder = maxDisplayOrder + 1 // Insert at the end

      const obj = {
        taskId: this.theData.id,
        itemId: id,
        itemValue: {
          id: id,
          type: type, // [number, text, date, boolean, list, file]
          name: defaultName, // name (generate one at the beginning)
          description: '', // all
          mandatory: 0, // all (0|1)
          minChars: null, // text
          maxChars: null, // text
          defaultValue: null, // text, form_video
          isMultiline: 0, // text (should be a textarea). (0|1)
          minValue: null, // number
          maxValue: null, // number
          decimals: null, // number
          minDate: null, // date
          maxDate: null, // date
          minDateOffsetDays: null, // date
          maxDateOffsetDays: null, // date
          listValues: {}, // list,
          fileIdentifier: null, // form_image, form_file
          fileOriginalName: null, // form_image, form_file
          fileSize: null, // form_image, form_file
          downloadToken: null, // Used only to download of file
          displayOrder: defaultDisplayOrder, // Order in which they appear,
          referencedTaskId: null,
          referencedItemId: null
        }
      }
      if (type === 'reference') {
        // currentReferencedField: task id | field id | task name | field name
        obj.itemValue.referencedTaskId = this.currentReferencedField.split('|')[0] // Task id
        obj.itemValue.referencedItemId = this.currentReferencedField.split('|')[1] // Field id
      }
      this.$store.commit('designerTaskItemSet', obj)
    }
  },
  computed: {
    description: {
      get () {
        return this.theData.description
      },
      set (value) {
        const copyOfData = { ...this.theData, description: value }
        this.$store.commit('designerTaskSet', copyOfData)
      }
    },
    // Return the list of components but sorted with property "displayOrder"
    sortedComponents: function () {
      // return this.theData.items
      // var keys = Object.keys(this.theData.items)
      var keys = []
      for (const k in this.theData.items) {
        let newKey = this.theData.items[k].displayOrder.toString().padStart(5, '0')
        newKey += ';' + k // padded displayOrder;key
        keys.push(newKey)
      }

      // Get the number of keys - easy using the array 'length' property
      var len = keys.length

      // Sort the keys. We can use the sort() method because 'keys' is an array
      keys.sort()

      // This array will hold your key/value pairs in an ordered way
      // it will be an array of objects
      var sortedDict = []

      // Now let's go throught your keys in the sorted order
      for (let i = 0; i < len; i++) {
        // get the current key
        const k = keys[i].split(';')[1]

        // Using the array 'push' method, we add an object at the end of the result array
        // It will hold the key/value pair
        sortedDict.push(this.theData.items[k])
      }
      return sortedDict // Return the sorted array
    },
    listOfAllReferenceableFields: {
      get () {
        // Return the list of all referenceable fields from other tasks (excluding this task and field of type reference)
        var allFields = []
        for (const task in this.$store.state.designerTasks) {
          if (task === this.theData.id) {
            // Skip itself
            continue
          }
          for (const item in this.$store.state.designerTasks[task].items) {
            if (this.$store.state.designerTasks[task].items[item].type === 'reference') {
              // Skip references
              continue
            }

            // Internal identifier, task id | field id | task name | field name
            let theValue = '' + task
            theValue += '|' + this.$store.state.designerTasks[task].items[item].id + '|'
            theValue += '|' + this.$store.state.designerTasks[task].name
            theValue += '|' + this.$store.state.designerTasks[task].items[item].name

            // Display value
            let theText = this.$store.state.designerTasks[task].name
            theText += ' -> ' + this.$store.state.designerTasks[task].items[item].name

            // Add to the list (value + text)
            allFields.push({ value: theValue, text: theText })
          }
        }
        return allFields
      }
    }
  },
  mounted () {
    // alert('task editor')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#formbodyplaceholder {
  background-color: #dedede;
  padding: 5px;
  min-height: 100%;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  border: 1px solid #303030;
}
</style>
