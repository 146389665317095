<template>
  <div style="text-align: left">
    <b-row>
      <b-col><!-- Title -->
        <h1><b-icon icon="grid" style="color: #a470e6"></b-icon> {{ $t('MYWORKFLOWS.title') }} <b-badge variant="warning" scale="0.5">{{ workflowList.length }}</b-badge></h1>
      </b-col>
    </b-row>
    <div v-if="!loading">
    <!-- Ask the name of the workflow instance before to proceed -->
    <b-modal
      id="start-process-modal"
      ref="startprocessmodal"
      :title="$t('MYWORKFLOWS.startNewTitle')"
      @ok="okStartProcessModal"
    >
      <form ref="startworkflowform" @submit.stop.prevent="submitStartProcess">
        <b-form-group
          :state="startProcessState"
          :label="$t('MYWORKFLOWS.startNewTitle.2')"
          label-for="name-input"
          :invalid-feedback="$t('MYWORKFLOWS.invalidTitle')"
          label-align="left"
          class="text-primary font-weight-bold"
        >
          <b-form-input
            id="name-input"
            v-model="startProcessName"
            :state="startProcessState"
            required
            trim
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <b-row class="mt-3">
      <b-col>
        <b-card no-body>
          <b-tabs pills card vertical>
            <b-tab v-for="category in workflowListByCategory" v-bind:key="category.categoryId">
              <template #title><!-- Tab title -->
                <b-icon icon="square-fill" class="mr-2"
                  :style="{ color: category.categoryName? category.categoryColor : '#000000' }">
                </b-icon>
                <strong>{{ category.categoryName ? category.categoryName : $t('CATEGORY.missing') }}</strong>
              </template>
              <div v-for="workflow in category.workflowList" v-bind:key="workflow.id">
                <b-row><!-- Tab contents -->
                  <b-col><!-- popover, display description when over -->
                    <!-- title -->
                    <h3 class="text-primary" :id="workflow.id">
                      <b-icon icon="grid" class="mr-2"
                        :style="{ color: category.categoryName? category.categoryColor : '#000000' }">
                      </b-icon>{{ workflow.name }}
                    </h3>
                    <b-popover :target="workflow.id" triggers="hover focus" placement="bottom">
                      <template #title>{{ workflow.name }}</template>
                      <div v-html="workflow.description"></div>
                    </b-popover>
                  </b-col>
                  <!-- start button -->
                  <b-col class="text-right">
                    <b-button
                      variant="success"
                      size="sm"
                      @click="startWorkflow(workflow.id, workflow.name)"
                    >
                      <b-icon icon="play-fill"></b-icon> {{ $t('MYWORKFLOWS.btnStart') }}
                    </b-button>
                  </b-col>
                </b-row>
                <hr>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    </div>
    <div v-else><br><b-spinner label="Spinning"></b-spinner> {{ $t('GLOBAL.loading') }}</div>
    <div v-if="workflowList.length === 0"><br><b-alert show variant="info"><b-icon icon="info-circle-fill"></b-icon> {{ $t('MYWORKFLOWS.noWorkflows') }}</b-alert></div>
  </div>
</template>

<script>

export default {
  name: 'MyWorkflowsPage',
  components: {
  },
  data () {
    return {
      workflowList: [],
      workflowListByCategory: {},
      errorDescription: '',
      loading: true,
      startProcessName: '',
      startProcessState: null,
      startWorkflowId: null
    }
  },
  computed: {
  },
  mounted () {
    this.getMyWorkflowList()
  },
  methods: {
    startWorkflow (id, name) {
      this.resetStartProcessModal()
      this.$nextTick(() => {
        this.startWorkflowId = id
        this.startProcessName = (new Date().toISOString().slice(0, 10)).replace('-', '').replace('-', '') + ' - ' + name
        this.$nextTick(() => {
          this.$bvModal.show('start-process-modal')
        })
      })
    },
    async getMyWorkflowList () {
      // Retrieve the list of workflows
      this.errorDescription = ''
      this.loading = true
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/mylist', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.workflowList = content.workflowList
          this.workflowListByCategory = content.workflowListByCategory
        } else {
          this.errorDescription = this.$t('MYWORKFLOWS.error')
          this.workflowList = []
          this.workflowListByCategory = {}
        }
      } catch (err) {
        this.errorDescription = err
        this.workflowList = []
        this.workflowListByCategory = {}
      }

      this.loading = false
    },
    resetStartProcessModal () {
      this.startProcessName = ''
      this.startProcessState = null
      this.startWorkflowId = null
    },
    okStartProcessModal (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.submitStartProcess()
    },
    async submitStartProcess () {
      const valid = this.$refs.startworkflowform.checkValidity()
      this.startProcessState = valid
      if (!valid) {
        return
      }

      // Do the real call
      try {
        // Start process
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/start', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({
            workflowId: this.startWorkflowId,
            processName: this.startProcessName
          })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.$store.commit('setNextTasksData', { processId: content.processId, rootProcessId: content.processId, sourceObject: this.startProcessName, sourcePage: 'process' })
            this.$router.push({ name: 'NextTasks' })
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('MYWORKFLOWS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('MYWORKFLOWS.errorStart'), {
            title: this.$t('MYWORKFLOWS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('MYWORKFLOWS.errorStart'), {
          title: this.$t('MYWORKFLOWS.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }

      this.$bvModal.hide('start-process-modal')

      // Force a reload of the workflows
      this.getMyWorkflowList()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
