<template>
    <div style="text-align: left">
      <b-row>
        <b-col md="6">
          <h1 style="display:inline"><b-icon icon="stickies" style="color: #ff7725;"></b-icon> {{ $t('CATEGORY.title') }}
          </h1>
          <b-icon class="ml-4 my-1"
              :icon="showNewCategoryIcon"
              variant="success"
              scale="2"
              @click="showNewCategory = !showNewCategory;showNewCategory ? showNewCategoryIcon='dash-circle-fill' : showNewCategoryIcon='plus-circle-fill';">
            </b-icon>
        </b-col>
        <b-col md="6" class="text-right">
          <!-- Table filter -->
          <b-form-group
            :label="$t('GLOBAL.filter')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-2 mt-4 text-primary font-weight-bold"
          >
            <b-input-group size="sm">
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder=""
                style="background-color: #f9e49d"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{ $t('GLOBAL.clear') }}</b-button>
              </b-input-group-append>
              <b-button class="ml-2 py-0" variant="primary" v-on:click="getCategories()"><b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('CATEGORY.reload') }}</b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="showNewCategory">
        <b-col sm="12"><!-- New Category -->
          <div class="bg-primary p-1 p-md-3">
            <div class="bg-secondary p-3">
              <span>{{ $t('CATEGORY.name') }}</span>
              <b-form-input class="mb-2" v-model="newCategoryName" placeholder="" type="text" trim></b-form-input>
              <span>{{ $t('CATEGORY.color') }}</span>
              <b-form-input class="mb-2" v-model="newCategoryColor" placeholder="" type="color"></b-form-input>
              <div class="text-right">
                <b-button :disabled="!(newCategoryName != null && newCategoryName.length > 0)" variant="success" v-on:click="createCategory()"><b-icon icon="plus"></b-icon> {{ $t('GLOBAL.insert') }}</b-button>
              </div>
            </div>
          </div>
          <br>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <!-- Current categories -->
          <div v-if="categoryList.length > 0" class="mt-2">
            <b-table striped hover stacked='md'
              outlined bordered
              head-variant="dark"
              :items="categoryList"
              :fields="categoryFields"
              :filter="filter"
            >
              <!-- Header -->
              <template #head(name)>{{ $t('CATEGORY.name') }}</template>
              <template #head(color)>{{ $t('CATEGORY.color') }}</template>
              <template #head(actions)>{{ $t('GLOBAL.tableActions') }}</template>

              <!-- Columns -->
              <template v-slot:cell(name)="data">
                <p class="text-primary font-weight-bold text-break">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(color)="data">
                <p class="p-1" v-bind:style="{ color: data.item.color }"><b-icon icon="square-fill" font-scale="2"></b-icon></p>
              </template>
              <template v-slot:cell(actions)="data"><!-- Show / hide version list -->
                <div class="text-right">
                  <b-button size="sm" @click="data.toggleDetails" class="mr-2" variant="primary">
                    <b-icon icon="pencil-square"></b-icon> {{ data.detailsShowing ? $t('GLOBAL.hideDetails') : $t('GLOBAL.showDetails') }}
                  </b-button>
                </div>
              </template>
              <template #row-details="data"><!-- Template for details -->
                <div class="bg-primary p-1 p-md-3">
                  <div class="bg-secondary p-3">
                    <span>{{ $t('CATEGORY.name') }}</span> <b-form-input class="mb-2" v-model="data.item.name" placeholder="" type="text" trim></b-form-input>
                    <span>{{ $t('CATEGORY.color') }}</span> <b-form-input class="mb-2" v-model="data.item.color" placeholder="" type="color"></b-form-input>
                    <div class="text-right">
                      <b-button variant="danger" class="m-1" v-on:click="deleteCategory(data.item)"><b-icon icon="trash"></b-icon> {{ $t('GLOBAL.delete') }}</b-button>
                      <b-button variant="success" class="m-1" v-on:click="updateCategory(data.item)"><b-icon icon="pencil"></b-icon> {{ $t('GLOBAL.update') }}</b-button>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </div>
</template>
<script>
export default {
  name: 'ManageCategoryPage',
  data () {
    return {
      loading: true,
      newCategoryName: null,
      newCategoryColor: null,
      categoryList: [],
      categoryFields: [{
        key: 'name',
        label: this.$t('CATEGORY.name'),
        sortable: true
      },
      {
        key: 'color',
        label: this.$t('CATEGORY.color'),
        sortable: false
      },
      {
        key: 'actions',
        label: this.$t('GLOBAL.tableActions'),
        sortable: false
      }],
      filter: null,
      showNewCategory: false,
      showNewCategoryIcon: 'plus-circle-fill'
    }
  },
  mounted () {
    // Force a load of categories
    this.getCategories()
  },
  methods: {
    async getCategories () {
      // Retrieve the list of all categories
      this.showNewCategory = false
      this.showNewCategoryIcon = 'plus-circle-fill'
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/category', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.categoryList = content.categoryList
        } else {
          this.categoryList = []
          this.$bvToast.toast(this.$t('CATEGORY.error'), {
            title: this.$t('CATEGORY.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.categoryList = []
        this.$bvToast.toast(this.$t('CATEGORY.error'), {
          title: this.$t('CATEGORY.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }

      this.loading = false
    },
    async createCategory () {
      // Create a new category
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/category', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ name: this.newCategoryName, color: this.newCategoryColor })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            // Reload list of categories
            this.getCategories()
            this.newCategoryName = null
            this.newCategoryColor = null
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('CATEGORY.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('CATEGORY.errorInsert'), {
            title: this.$t('CATEGORY.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('CATEGORY.errorInsert'), {
          title: this.$t('CATEGORY.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async updateCategory (category) {
      // Update category
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/category', {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ id: category.id, name: category.name, color: category.color })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.getCategories() // Reload list of categories
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('CATEGORY.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('CATEGORY.errorUpdate'), {
            title: this.$t('CATEGORY.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('CATEGORY.errorUpdate'), {
          title: this.$t('CATEGORY.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    },
    async deleteCategory (category) {
      // Delete category
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/category', {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ id: category.id })
        })
        if (rawResponse.ok) {
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.getCategories() // Reload list of categories
          } else {
            this.$bvToast.toast(content.errorDescription, {
              title: this.$t('CATEGORY.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('CATEGORY.errorDelete'), {
            title: this.$t('CATEGORY.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.$bvToast.toast(this.$t('CATEGORY.errorDelete'), {
          title: this.$t('CATEGORY.title'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
