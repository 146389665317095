<template>
  <div class="decisionEditorProcessItem">
    <b-row>
      <b-col align="right"><b-icon icon="square-fill" v-bind:style="{ color: ruleGroupColors[processCopy.ruleGroup] }"></b-icon>&nbsp;
      Rule <b-icon icon="arrow-right" style="color:green"></b-icon>
      </b-col>
      <b-col><b-form-select v-model="processTaskId" :options="listOfAllSubprocesses"></b-form-select></b-col> <!-- Subprocess id -->
      <b-col class="text-center"> {{ $t('DESIGNER.decision.process.result') }} </b-col>
      <b-col><b-form-input type="text" v-model="processResult" trim></b-form-input></b-col> <!-- Process Result -->
      <b-col>
        <!-- Group -->
        <b-row>
          <b-col>
            <b-form-select v-bind:style="{ borderColor: ruleGroupColors[processCopy.ruleGroup] }" v-model="ruleGroup" class="mb-3">
              <b-form-select-option :value="null">{{ $t('DESIGNER.decision.selectGroup') }}</b-form-select-option>
              <b-form-select-option value="1">1</b-form-select-option>
              <b-form-select-option value="2">2</b-form-select-option>
              <b-form-select-option value="3">3</b-form-select-option>
              <b-form-select-option value="4">4</b-form-select-option>
              <b-form-select-option value="5">5</b-form-select-option>
              <b-form-select-option value="6">6</b-form-select-option>
              <b-form-select-option value="7">7</b-form-select-option>
              <b-form-select-option value="8">8</b-form-select-option>
              <b-form-select-option value="9">9</b-form-select-option>
              <b-form-select-option value="10">10</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col><b-button href="#" variant="primary" @click="removeRule()"><b-icon icon="trash"></b-icon></b-button></b-col>
    </b-row>
  </div>

</template>

<script>

export default {
  name: 'DecisionEditorProcessItem',
  props: {
    decisionId: String,
    process: Object, // processes[x]
    removeCallback: Function
  },
  components: {
  },
  data () {
    return {
      ruleGroupColors: {
        1: '#0078d7',
        2: '#ffb900',
        3: '#e81123',
        4: '#f7630c',
        5: '#8e8cd8',
        6: '#00b7c3',
        7: '#00cc6a',
        8: '#847545',
        9: '#498205',
        10: '#9a0089'
      },
      processCopy: { ...this.process }
    }
  },
  methods: {
    removeRule () {
      this.removeCallback(this.process.id) // Remove this rule (remove me)
    },
    updateRuleField (name, value) {
      const copyOfData = { ...this.processCopy }
      copyOfData[name] = value

      const obj = {
        decisionId: this.decisionId,
        processId: this.process.id,
        ruleValue: copyOfData
      }
      this.$store.commit('designerDecisionRuleProcessSet', obj)
    }
  },
  computed: {
    listOfAllSubprocesses: {
      get () {
        // Retrieve the list of all the subprocesses
        var allSubprocesses = []
        for (const process in this.$store.state.designerProcesses) {
          allSubprocesses.push({ value: this.$store.state.designerProcesses[process].id, text: this.$store.state.designerProcesses[process].name })
        }
        return allSubprocesses
      }
    },
    processTaskId: {
      get () {
        return this.process.processTaskId
      },
      set (value) {
        this.updateRuleField('processTaskId', value)
        this.processCopy.processTaskId = value
      }
    },
    processResult: {
      get () {
        return this.process.processResult
      },
      set (value) {
        this.updateRuleField('processResult', value)
        this.processCopy.processResult = value
      }
    },
    ruleGroup: {
      get () {
        return this.process.ruleGroup
      },
      set (value) {
        this.updateRuleField('ruleGroup', value)
        this.processCopy.ruleGroup = value
      }
    }
  },
  mounted () {
    // Force an initial load of the functions
    this.fillFunctions()
  },
  beforeDestroy () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.decisionEditorProcessItem {
  padding: 10px 5px;
  border-top: 1px solid rgb(231, 222, 222);
  border-bottom: 1px solid rgb(231, 222, 222);
}
</style>
