import mxgraphFactory from 'mxgraph'

// MAB: THIS IS A WORKAROUND TO FIX NPM PACKAGE OF MXGRAPH!!!
// To be removed in future.
export default mxgraphFactory({
  // here should be your paths to mxgraph's assets (it's optional)
  // mxImageBasePath: 'assets/mxgraph/images',
  mxImageBasePath: process.env.BASE_URL + 'mxGraph/images',
  mxBasePath: 'assets/mxgraph'
})
