// Utility functions
export function arrayBufferToBase64 (buffer) {
  var bytes = new Uint8Array(buffer)
  var chars = []
  var len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    chars.push(String.fromCharCode(bytes[i]))
  }
  var binary = chars.join('')
  return window.btoa(binary)
}
