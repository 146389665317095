<template>
    <b-container>
    <b-row>
        <b-col style="text-align:center">
            <b-img :src="require('../assets/LogoLG.png')" fluid alt=""></b-img>
        </b-col>
    </b-row>
    <b-row><b-col style="text-align:center"><h2 class="bg-secondary text-primary">{{ $t('RESET.title') }}</h2><br></b-col></b-row>
    <b-row>
      <b-col sm="12" md="2"></b-col>
      <b-col sm="12" md="8" style="text-align:left">
        <br>
        <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger" dismissible fade>
            {{ $t('RESET.error') }} <br> {{ errorDescription }}</b-alert>
        </div>
        <div v-if="displayMessage.length > 0"><br><b-alert show variant="success" dismissible fade>{{ displayMessage }}</b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row>
        <b-col sm="12" md="3"></b-col>
        <b-col sm="12" md="6" style="text-align:left">
          <div><LanguageSelector></LanguageSelector></div>
          <b-form @submit="doResetPassword">
            <b-form-group>
              <div class="text-primary my-0 p-0"><b>{{ $t('RESET.email') }}</b></div>
              <b-form-input v-model="form.email" type="email" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('RESET.password') }}</b></div>
              <b-form-input v-model="form.password" type="password" placeholder="" trim required></b-form-input>
              <br>
              <div class="text-primary my-0 p-0"><b>{{ $t('RESET.repeatPassword') }}</b></div>
              <b-form-input v-model="form.passwordRepeat" type="password" placeholder="" trim required></b-form-input>
            </b-form-group>
             <b-button fluid type="submit" variant="primary" block >{{ $t('RESET.btnReset') }}</b-button>
          </b-form>
        </b-col>
        <b-col sm="12" md="3"></b-col>
    </b-row>
    <b-row>
      <b-col><br><b-link :to="{ name: 'LoginPage' }"><b>{{ $t('RESET.loginPage') }}</b></b-link></b-col>
    </b-row>
    </b-container>
</template>

<script>
import LanguageSelector from './common/LanguageSelector'

export default {
  name: 'ResetPasswordPage',
  data () {
    return {
      form: {
        email: '',
        password: '',
        passwordRepeat: ''
      },
      errorDescription: '',
      displayMessage: ''
    }
  },
  components: {
    LanguageSelector
  },
  methods: {
    async doResetPassword (evt) {
      // Do the authentication
      evt.preventDefault()
      this.errorDescription = ''
      this.displayMessage = ''

      if (this.form.password !== this.form.passwordRepeat) {
        this.errorDescription = this.$t('RESET.errorPasswordRepeat')
        return
      }

      try {
        // Reset password. No authentication, but use (and rebuild) the query string of the url (tenant/token). Same as received as parameters
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/resetpassword/' + this.$route.params.tenant + '/' + this.$route.params.token, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify(this.form)
        })

        // Get the json contents
        const content = await rawResponse.json()

        if (content.errorCode === 0) {
          // Reset successful
          this.errorDescription = ''
          this.displayMessage = this.$t('RESET.passwordChanged')
        } else {
          // Error
          this.errorDescription = content.errorDescription
        }
      } catch (err) {
        // Connection problems
        this.errorDescription = err
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
  color: #4283b9;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
