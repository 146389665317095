<template>
  <b-card-text align="left">
    <b-row>
      <b-col align="left"><b-icon :icon="menuIcon"></b-icon> {{ menuText }}</b-col>
      <b-col align="right">
        <b-button href="#" variant="primary" @click="clickCallback(type)">
          <span><b-icon icon="forward-fill" scale="1" ></b-icon></span>
        </b-button>
      </b-col>
    </b-row>
  </b-card-text>
</template>

<script>
// Editor toolbar item

export default {
  name: 'TaskEditorToolbarItem',
  props: {
    clickCallback: Function, // Function to call when selected
    type: String, // Type argument to pass to clickCallback
    menuText: String, // Text to display
    menuIcon: String
  },
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
