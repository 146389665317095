<template>
  <div>
    <br>Swimlane
    <h4 class="text-primary">{{ theData.name }}</h4>
    <br>
    <b-row>
      <b-col sm="12" lg="8">
        <!-- Shared swimlane flag -->
        <b-alert show variant="light">
          <b-form-checkbox v-show="true"
              v-model="isShared"
              :value=1
              :unchecked-value=0
              switch
            >{{ $t('DESIGNER.swimlane.shared') }}
          </b-form-checkbox>
        </b-alert>
        {{ $t('DESIGNER.swimlane.description') }}
        <b-form-input class="my-1" v-model="description" placeholder="" type="text" trim></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <!-- User list -->
      <b-col sm="12" lg="4" class="mt-3">
        <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger" dismissible fade>{{ errorDescription }}</b-alert></div>
        <b-card>
          <h3 v-if="userList.length > 0"><b-icon icon="person-fill" scale="1"></b-icon> {{ $t('GROUPS.users') }}</h3><hr>
          <h3 v-if="userList.length == 0"><b-icon icon="person-fill" scale="1"></b-icon> {{ $t('GROUPS.users.notAvailable') }}</h3>
          <ol class="user_list">
            <li v-for="(user, index) in userList" v-bind:key="user.id">
              <b-form-checkbox
                v-model="userList[index].selected"
                :value="'1;'+user.id"
                :unchecked-value="'0;'+user.id"
                switch
                @input="changeUserSelection"
              >{{ user.lastName }}, {{ user.firstName }} <span v-if="user.active === 0" style="background-color:red;color:white"> - {{ $t('GROUPS.users.inactive') }} -</span>
              </b-form-checkbox>
            </li>
          </ol>
        </b-card>
      </b-col>
       <!-- Group list -->
      <b-col sm="12" lg="4" class="mt-3">
        <div v-if="errorDescription.length > 0"><br><b-alert show variant="danger" dismissible fade>{{ errorDescription }}</b-alert></div>
        <b-card>
          <h3 v-if="groupList.length > 0"><b-icon icon="people-fill" scale="1"></b-icon> {{ $t('GROUPS.groups') }}</h3><hr>
          <h3 v-if="groupList.length == 0"><b-icon icon="people-fill" scale="1"></b-icon> {{ $t('GROUPS.groups.notAvailable') }}</h3>
          <ol class="user_list">
            <li v-for="(group, index) in groupList" v-bind:key="group.id">
              <b-form-checkbox
                v-model="groupList[index].selected"
                :value="'1;'+group.id"
                :unchecked-value="'0;'+group.id"
                switch
                @input="changeGroupSelection"
              >{{ group.name }}
              </b-form-checkbox>
            </li>
          </ol>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

// Form editor
export default {
  name: 'SwimlaneEditor',
  props: {
    theData: Object
  },
  data () {
    return {
      userList: [],
      groupList: [],
      errorDescription: ''
    }
  },
  components: {
  },
  methods: {
    changeUserSelection (statusAndId) {
      // alert(statusAndId)
      var isSelected = statusAndId.split(';')[0]
      var userId = statusAndId.split(';')[1]

      var swimlaneUser = { swimlaneId: this.theData.id, userId: userId }
      if (isSelected === '1') {
        this.$store.commit('designerSwimlaneUserInsert', swimlaneUser) // Add to the list of this swimlane
      } else {
        this.$store.commit('designerSwimlaneUserRemove', swimlaneUser) // Remove from the list of this swimlane
      }
    },
    changeGroupSelection (statusAndId) {
      // alert(statusAndId)
      var isSelected = statusAndId.split(';')[0]
      var groupId = statusAndId.split(';')[1]

      var swimlaneGroup = { swimlaneId: this.theData.id, groupId: groupId }
      if (isSelected === '1') {
        this.$store.commit('designerSwimlaneGroupInsert', swimlaneGroup) // Add to the list of this swimlane
      } else {
        this.$store.commit('designerSwimlaneGroupRemove', swimlaneGroup) // Remove from the list of this swimlane
      }
    }
  },
  async mounted () {
    // Load from server the full user list
    try {
      // Get full list of users
      this.errorDescription = ''

      const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'usermanager/data/', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.$store.state.userToken,
          'Accept-Language': this.$i18n.locale
        }
      })
      if (rawResponse.ok) {
        // Get the json contents
        const content = await rawResponse.json()

        for (const user of content) {
          user.selected = '0;' + user.id // Add a new field to be used for selection. We reuse the current structure from web call
          if (user.id in this.theData.users) { // Check if user is in our list and set to selected (1)
            user.selected = '1;' + user.id
          }
        }
        this.userList = content
      } else {
        this.errorDescription = this.$t('USERS.error')
        this.userList = []
      }
    } catch (err) {
      this.errorDescription = this.$t('USERS.error')
      this.userList = []
    }
    //
    // Load from server the full group list
    try {
      // Get full list of users
      this.errorDescription = ''

      const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'user/groups', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.$store.state.userToken,
          'Accept-Language': this.$i18n.locale
        }
      })
      if (rawResponse.ok) {
        // Get the json contents
        const content = await rawResponse.json()

        for (const group of content.groupList) {
          group.selected = '0;' + group.id // Add a new field to be used for selection. We reuse the current structure from web call
          if (group.id in this.theData.groups) { // Check if group is in our list and set to selected (1)
            group.selected = '1;' + group.id
          }
        }
        this.groupList = content.groupList
      } else {
        this.errorDescription = this.$t('GROUPS.error')
        this.groupList = []
      }
    } catch (err) {
      this.errorDescription = this.$t('GROUPS.error')
      this.groupList = []
    }
  },
  computed: {
    description: {
      get () {
        return this.theData.description
      },
      set (value) {
        const copyOfData = { ...this.theData, description: value }
        this.$store.commit('designerSwimlaneSet', copyOfData)
      }
    },
    isShared: {
      get () {
        return this.theData.isShared
      },
      set (value) {
        const copyOfData = { ...this.theData, isShared: value }
        this.$store.commit('designerSwimlaneSet', copyOfData)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
