import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Embed from 'v-video-embed'
import CountryFlag from 'vue-country-flag'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'

// Standard bootstrap theme
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Custom bootstrap theme
// Custom css (build from scss parametric file). Inside we load boostrap scss.
import './bootstrap_custom.scss'

// Language translations
import i18n from './i18n'

// Cookies and language
Vue.use(VueCookies)
Vue.$cookies.config('30d') // Expire in 30 days
if (!Vue.$cookies.isKey('language')) {
  Vue.$cookies.set('language', 'en') // Default language
  i18n.locale = 'en'
} else {
  i18n.locale = Vue.$cookies.get('language')
}

Vue.config.productionTip = false
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Video embedding plugin (Youtube, Vimeo, DailyMotion)
Vue.use(Embed)
// Country flags
Vue.component('country-flag', CountryFlag)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
