<template>
  <div>
    <br>
    <h1 class="text-primary">{{ $t('ABOUT.title') }}</h1>
      <b-row>
        <b-col><br><h4 class="text-primary">{{ $t('ABOUT.tenant') }}</h4></b-col>
      </b-row>
      <b-row>
        <b-col><div class="fieldDescr">{{ $t('ABOUT.registrant') }}</div></b-col>
        <b-col><div class="fieldValue text-break">{{ this.$store.state.userData.tenant_full_name }}</div></b-col>
      </b-row>
      <b-row>
        <b-col><div class="fieldDescr">{{ $t('ABOUT.tenant') }}</div></b-col>
        <b-col><div class="fieldValue text-break">{{ this.$store.state.userData.tenant_account_name }}</div></b-col>
      </b-row>
      <b-row>
        <b-col><br><h4 class="text-primary">{{ $t('ABOUT.user') }}</h4></b-col>
      </b-row>
      <b-row>
        <b-col><div class="fieldDescr">{{ $t('ABOUT.email') }}</div></b-col>
        <b-col><div class="fieldValue text-break">{{ this.$store.state.userData.user_email }}</div></b-col>
      </b-row>
      <b-row>
        <b-col><div class="fieldDescr">{{ $t('ABOUT.firstName') }}</div></b-col>
        <b-col><div class="fieldValue text-break">{{ this.$store.state.userData.user_first_name }}</div></b-col>
      </b-row>
      <b-row>
        <b-col><div class="fieldDescr">{{ $t('ABOUT.lastName') }}</div></b-col>
        <b-col><div class="fieldValue text-break">{{ this.$store.state.userData.user_last_name }}</div></b-col>
      </b-row>
      <b-row class="m-1">
        <b-col><div class="fieldDescr">{{ $t('ABOUT.isAdministrator') }}</div></b-col>
        <b-col>
          <div class="fieldValue" v-if="this.$store.state.userData.is_admin === 1">
            <span class="h4 mb-2"><b-icon icon="check-circle" variant="info"></b-icon></span>
          </div>
          <div class="fieldValue" v-else >
            <span class="h4 mb-2"><b-icon icon="dash" variant="info"></b-icon></span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col><br><h4 class="text-primary">{{ $t('ABOUT.accountInfo') }}</h4></b-col>
      </b-row>
      <b-row class="m-1">
        <b-col><div class="fieldDescr">{{ $t('ABOUT.accountType') }}</div></b-col>
        <b-col><div class="fieldValue"><b>{{ this.$store.state.userData.account_code }}</b></div></b-col>
      </b-row>
      <b-row class="mx-1 mt-3">
        <b-col><div class="fieldDescr">{{ $t('ABOUT.users') }}</div></b-col>
        <b-col>
          <div class="fieldValue">
            <div v-if="limits.activeUsers >= limits.maxUsers">
              <b-alert variant="warning" show><b-icon icon="exclamation-triangle"></b-icon> {{ $t('ABOUT.usersLimit') }}</b-alert>
            </div>
            {{ limits.activeUsers }} / {{ limits.maxUsers }} {{ $t('ABOUT.usersMax') }}
            <b-progress :max="limits.maxUsers" height="2rem" variant="info">
              <b-progress-bar :value="limits.activeUsers" :striped="true">
                <span><strong>{{ limits.activeUsers }} / {{ limits.maxUsers }}</strong></span>
              </b-progress-bar>
            </b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-1 mt-3">
        <b-col><div class="fieldDescr">{{ $t('ABOUT.workflows') }}</div></b-col>
        <b-col>
          <div class="fieldValue">
            <div v-if="limits.activeWorkflows >= limits.maxWorkflows">
              <b-alert variant="warning" show><b-icon icon="exclamation-triangle"></b-icon> {{ $t('ABOUT.workflowsLimit') }}</b-alert>
            </div>
            {{ limits.activeWorkflows }} / {{ limits.maxWorkflows }} {{ $t('ABOUT.workflowsMax') }}
            <b-progress :max="limits.maxWorkflows" height="2rem" variant="info">
                <b-progress-bar :value="limits.activeWorkflows" :striped="true">
                  <span><strong>{{ limits.activeWorkflows }} / {{ limits.maxWorkflows }}</strong></span>
                </b-progress-bar>
            </b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-1 mt-3">
        <b-col><div class="fieldDescr">{{ $t('ABOUT.storageSpace') }}</div></b-col>
        <b-col>
          <div class="fieldValue">
            <div v-if="limits.currentQuota >= limits.maxQuota">
              <b-alert variant="warning" show><b-icon icon="exclamation-triangle"></b-icon> {{ $t('ABOUT.storageSpaceLimit') }}</b-alert>
            </div>
            {{ Math.round(limits.currentQuota / 1024 / 1024) }} MB / {{ Math.round(limits.maxQuota / 1024 / 1024) }} {{ $t('ABOUT.storageSpaceMax') }}
            <b-progress :max="limits.maxQuota" height="2rem" variant="info">
                <b-progress-bar :value="limits.currentQuota" :striped="true">
                  <span><strong>{{ Math.round(limits.currentQuota / 1024 / 1024) }} / {{ Math.round(limits.maxQuota / 1024 / 1024) }}</strong></span>
                </b-progress-bar>
            </b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-1 mt-3">
        <b-col><div class="fieldDescr">{{ $t('ABOUT.storageFiles') }}</div></b-col>
        <b-col>
          <div class="fieldValue">
            <div v-if="limits.currentAttachments >= limits.maxAttachments">
              <b-alert variant="warning" show><b-icon icon="exclamation-triangle"></b-icon> {{ $t('ABOUT.storageFilesLimit') }}</b-alert>
            </div>
            {{ limits.currentAttachments }} / {{ limits.maxAttachments }} {{ $t('ABOUT.storageFilesMax') }}
            <b-progress :max="limits.maxAttachments" height="2rem" variant="info">
                <b-progress-bar :value="limits.currentAttachments" :striped="true">
                  <span><strong>{{ limits.currentAttachments }} / {{ limits.maxAttachments }}</strong></span>
                </b-progress-bar>
            </b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="this.$store.state.userData.is_admin === 1">
        <b-col class="mt-3 mb-2">
          <br><b-button variant="danger" @click="$router.push({ name: 'UnsubscribePage' })"><b>{{ $t('ABOUT.unsubscribeBtn') }} ...</b></b-button>
        </b-col>
      </b-row>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  data () {
    return {
      msg: 'About page',
      limits: {
        activeUsers: 0,
        maxUsers: this.$store.state.userData.max_users,
        activeWorkflows: 0,
        maxWorkflows: this.$store.state.userData.workflow_number,
        currentQuota: 0,
        maxQuota: this.$store.state.userData.storage_limit_mb * 1024 * 1024,
        currentAttachments: 0,
        maxAttachments: this.$store.state.userData.attachments
      }
    }
  },
  mounted () {
    this.getLimits() // Get limits for this account
  },
  methods: {
    async getLimits () {
      // Get limits for this account
      try {
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'tenant/limits', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.limits = content
        } else {
          this.limits = {}
          this.$bvToast.toast(this.$t('ABOUT.errorQuota'), {
            title: 'About',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
        this.limits = {}
        this.$bvToast.toast(this.$t('ABOUT.errorQuota'), {
          title: 'About',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fieldDescr {
  text-align: right;
}
.fieldValue {
  text-align: left;
}
</style>
