<template>
  <span>
    <!--
    <span v-if="type === 'process'">
        <span v-if="status===2" style="color:green" class="px-1">&nbsp;
            <b-icon icon="battery-full"></b-icon>
        </span>
        <span v-if="status<2" style="color:blue" class="px-1">&nbsp;
            <b-icon icon="battery-charging"></b-icon>
        </span>
        <span v-if="status===3" style="color:red" class="px-1">&nbsp;
            <b-icon icon="battery-half"></b-icon>
        </span>
        <span v-if="!noText">&nbsp;{{ processStatuses[status] }}</span>
    </span>
    -->
    <span v-if="type === 'process'">
        <span v-if="status===2" style="color:green" class="px-1">&nbsp;
            <b-icon icon="gear-wide-connected"></b-icon><!-- Terminated -->
        </span>
        <span v-if="status<2" style="color:blue" class="px-1">&nbsp;
            <b-icon icon="gear-wide-connected"></b-icon><!-- Running+created -->
        </span>
        <span v-if="status===3" style="color:red" class="px-1">&nbsp;
            <b-icon icon="gear-wide-connected"></b-icon><!-- Deleted -->
        </span>
        <span v-if="!noText">&nbsp;{{ processStatuses[status] }}</span>
    </span>

    <!--
    <span v-if="type === 'task'">
        <span v-if="status===0">
          <b-icon style="color:gray" icon="battery"></b-icon>
        </span>
        <span v-if="status===1">
          <b-icon style="color:blue" icon="battery-half"></b-icon>
        </span>
        <span v-if="status===2">
          <b-icon style="color:#e77f00" icon="battery-charging"></b-icon>
        </span>
        <span v-if="status===3">
          <b-icon style="color:green" icon="battery-full"></b-icon>
        </span>
        <span v-if="!noText">&nbsp;{{ taskStatuses[status] }}</span>
    </span>
    -->
    <span v-if="type === 'task'">
        <span v-if="status===0">
          <b-icon style="color:gray" icon="puzzle-fill"></b-icon><!-- Created -->
        </span>
        <span v-if="status===1">
          <b-icon style="color:blue" icon="puzzle-fill"></b-icon><!-- Available -->
        </span>
        <span v-if="status===2">
          <b-icon style="color:#e77f00" icon="puzzle-fill"></b-icon><!-- Assigned -->
        </span>
        <span v-if="status===3">
          <b-icon style="color:green" icon="puzzle-fill"></b-icon><!-- Completed -->
        </span>
        <span v-if="!noText">&nbsp;{{ taskStatuses[status] }}</span>
    </span>

    <!--
    <span v-if="type === 'approval'">
        <span v-if="status===0">
          <b-icon style="color:gray" icon="battery"></b-icon>
        </span>
        <span v-if="status===1">
          <b-icon style="color:green" icon="battery-full"></b-icon>
        <span v-if="status===2">
          <b-icon style="color:red" icon="battery-half"></b-icon>
        </span>
        <span v-if="status===3">
          <b-icon style="color:gray" icon="battery-charging"></b-icon>
        </span>
        <span v-if="!noText">&nbsp;{{ approvalStatuses[status] }}</span>
    </span>
    -->
    <span v-if="type === 'approval'">
        <span v-if="status===0">
          <b-icon style="color:gray" icon="clock"></b-icon><!-- Created -->
        </span>
        <span v-if="status===1">
          <b-icon style="color:green" icon="hand-thumbs-up"></b-icon><!-- approved -->
        </span>
        <span v-if="status===2">
          <b-icon style="color:red" icon="hand-thumbs-down"></b-icon><!-- rejected -->
        </span>
        <span v-if="status===3">
          <b-icon style="color:gray" icon="dash"></b-icon><!-- deleted -->
        </span>
        <span v-if="!noText">&nbsp;{{ approvalStatuses[status] }}</span>
    </span>
  </span>
</template>

<script>

export default {
  name: 'ViewStatus',
  props: {
    type: String,
    status: Number,
    noText: Boolean
  },
  data () {
    return {
      processStatuses: [
        this.$t('STATUS.process.created'),
        this.$t('STATUS.process.running'),
        this.$t('STATUS.process.terminated'),
        this.$t('STATUS.process.deleted')], // 0=Created, 1=running, 2=terminated, 3=deleted
      taskStatuses: [
        this.$t('STATUS.task.created'),
        this.$t('STATUS.task.available'),
        this.$t('STATUS.task.assigned'),
        this.$t('STATUS.task.completed')], // 0=Created, 1=Available, 2=Assigned, 3=Completed
      approvalStatuses: [
        this.$t('STATUS.approval.created'),
        this.$t('STATUS.approval.approved'),
        this.$t('STATUS.approval.rejected'),
        this.$t('STATUS.approval.deleted')] // 0=created, 1=approved, 2=rejected, 3=deleted
    }
  }
}
</script>

<style scoped>
</style>
