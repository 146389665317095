<template>
  <div style="text-align: left">
    <!-- Modal frame for process display -->
    <b-modal id="viewProcessModal" size="xl">
      <div v-if="currentProcessId != null" id="viewProcessPlaceholder">
        <ViewProcess :processId="currentProcessId" :hideActions=false></ViewProcess>
      </div>
    </b-modal>
    <b-row>
      <b-col md="6"><!-- Title -->
        <h1><b-icon icon="puzzle-fill" style="color: #fad145"></b-icon> {{ $t('MYTASKS.title') }}
        </h1>
      </b-col>
      <b-col md="6" class="text-right mb-1"><!-- Refresh -->
        <b-button
            variant="primary"
            size="sm"
            @click="loading=true;refreshData()"
        >
            <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.refresh') }}
        </b-button>
        <!-- Show / hide filter -->
        <b-icon v-if="!showFilter" @click="showFilter=true" class="ml-3 p-1" scale="3" icon="filter-circle-fill" variant="info"></b-icon>
      </b-col>
    </b-row>
    <b-row>
      <b-col :lg="showFilter ? 3 : 12" :xl="showFilter ? 2 : 12" order-lg="12" v-show="showFilter"><!-- Filter contents -->
        <div class="filter">
          <div class="text-right">
            <b-icon v-if="showFilter" @click="showFilter=false" class="" icon="dash-square" variant="secondary"></b-icon>
          </div>
          <div class="m-1"><!-- From -->
            {{ $t('GLOBAL.filterFrom') }}
            <b-form-datepicker
              size="sm"
              dropleft
              v-model="fromDate" class="mb-2"
              inline
              today-button
              reset-button
              close-button
              :show-decade-nav="true"
              :locale="$i18n.locale">
            </b-form-datepicker>
          </div>
          <div class="m-1"><!-- To -->
            {{ $t('GLOBAL.filterTo') }}
            <b-form-datepicker
              size="sm"
              dropleft
              v-model="toDate" class="mb-2"
              inline
              today-button
              reset-button
              close-button
              :show-decade-nav="true"
              :locale="$i18n.locale">
            </b-form-datepicker>
          </div>
          <div class="m-1"><!-- Status for Task-->
            {{ $t('GLOBAL.filterStatusTask') }}
            <b-form-select v-model="status" class="mb-2">
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option :value="1">{{ $t('STATUS.task.available') }}</b-form-select-option>
              <b-form-select-option :value="2">{{ $t('STATUS.task.assigned') }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="m-1"><!-- workflow -->
            {{ $t('GLOBAL.filterWorkflow') }}
            <b-form-select
              v-model="workflowId"
              :options="workflowList"
              class="mb-2"
              value-field="originatorId"
              text-field="name">
            </b-form-select>
          </div>
          <div class="m-1"><!-- process -->
            {{ $t('GLOBAL.filterProcess') }}
            <b-form-input v-model="process" placeholder=""></b-form-input>
          </div>
          <div class="text-right m-1"><!-- Refresh + apply buttons -->
            <br>
            <b-button class="m-1"
              variant="secondary"
              size="sm"
              @click="loading=true;toDate='';fromDate='';status=null;workflowId='';process='';refreshData()"
            >
                <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.clear') }}
            </b-button>
            <b-button class="m-1"
              variant="primary"
              size="sm"
              @click="loading=true;refreshData()"
            >
                <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.filterApply') }}
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col :lg="showFilter ? 9 : 12" :xl="showFilter ? 10 : 12"><!-- Contents -->
        <div v-if="!loading">
          <br>
          <b-tabs>
            <b-tab><!-- Task tab -->
              <template v-slot:title><!-- Tab title -->
                  {{ $t('MYTASKS.tasks') }} <b-badge variant="info">{{ taskList.length }}</b-badge>
              </template>
              <div v-if="taskList.length > 0">
                  <!-- tasks table filter -->
                  <div class="m-2">
                    <b-form-group
                      :label="$t('GLOBAL.filter')"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="filterInputTask"
                      class="mb-0 mt-4 text-primary font-weight-bold"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          v-model="filterTask"
                          type="search"
                          id="filterInputTask"
                          placeholder=""
                          style="background-color: #f9e49d"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button :disabled="!filterTask" @click="filterTask = ''">{{ $t('GLOBAL.clear') }}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>

                  <b-row>
                    <b-col md="2"></b-col>
                    <b-col md="4"><!-- Rows per page -->
                      <b-form-group
                        :label="$t('GLOBAL.filterPerPage')"
                        label-align-sm="right"
                        label-size="sm"
                        label-cols-sm="4"
                        label-cols-md="8"
                        label-cols-lg="8"
                        label-for="perPageSelectTask"
                      >
                        <b-form-select
                          v-model="perPageTask"
                          id="perPageSelectTask"
                          size="sm"
                          :options="pageOptionsTask"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6"><!-- Page selector -->
                      <b-pagination
                        v-model="currentPageTask"
                        :total-rows="totalRowsTask"
                        :per-page="perPageTask"
                        align="fill"
                        size="sm"
                      ></b-pagination>
                    </b-col>
                  </b-row>

                  <b-table striped hover stacked='md'
                      outlined bordered
                      head-variant="dark"
                    :items="taskList" :fields="taskListFields"
                    :filter="filterTask"
                    @filtered="onFilteredTask"
                    :current-page="currentPageTask"
                    :per-page="perPageTask"
                  >

                    <!-- Header -->
                    <template #head(creationDate)>{{ $t('MYTASKS.date') }}</template>
                    <template #head(categoryName)>{{ $t('MYTASKS.category') }}</template>
                    <template #head(workflowName)>{{ $t('MYTASKS.workflow') }}</template>
                    <template #head(processName)>{{ $t('MYTASKS.process') }}</template>
                    <template #head(taskName)>{{ $t('MYTASKS.task') }}</template>
                    <template #head(status)>{{ $t('MYTASKS.statusTask') }}</template>
                    <template #head(actions)>{{ $t('GLOBAL.tableActions') }}</template>

                    <!-- Values -->
                    <template v-slot:cell(processId)="data">
                      <b-button variant="primary" size="sm" v-on:click="viewProcess(data.item.processId)"><b-icon icon="search"></b-icon></b-button>
                    </template>
                    <template v-slot:cell(processName)="data">
                      <b-link v-on:click="viewProcess(data.item.processId)">
                        <!-- Subprocess ? -->
                        <b-icon v-if="data.item.processId != data.item.processRootId" class="mr-2" icon="puzzle" variant="warning"></b-icon>
                        <span class="text-primary font-weight-bold">{{ data.item.processName }}</span>
                      </b-link>
                    </template>
                    <template v-slot:cell(taskName)="data">
                      <span class="text-primary">{{ data.item.taskName }}</span>
                    </template>
                    <template v-slot:cell(actions)="data">
                      <div class="text-right">
                        <span v-if="data.item.status === 1"><!-- not yet assigned but started -->
                        <b-button
                            variant="success"
                            size="sm"
                            @click="startTask(data.item.workflowId, data.item.taskId, data.item.processId)"
                            class="m-1"
                        >
                            <b-icon icon="play-fill"></b-icon> {{ $t('MYTASKS.btnTakeInCharge') }}
                        </b-button>
                        </span>
                        <span v-if="data.item.status === 2"><!-- assigned to this user -->
                          <b-button
                              variant="success"
                              size="sm"
                              @click="startTask(data.item.workflowId, data.item.taskId, data.item.processId)"
                              class="m-1"
                          >
                              <b-icon icon="play-fill"></b-icon> {{ $t('MYTASKS.btnContinue') }}
                          </b-button>
                          <b-button
                              variant="warning"
                              size="sm"
                              @click="releaseTask(data.item.workflowId, data.item.taskId, data.item.processId)"
                              class="m-1"
                          >
                              <b-icon icon="stop-fill"></b-icon> {{ $t('MYTASKS.btnRelease') }}
                          </b-button>
                        </span>
                      </div>
                    </template>
                    <template v-slot:cell(status)="data">
                      <ViewStatus type="task" :status="data.item.status" :noText="false"></ViewStatus>
                    </template>
                    <template v-slot:cell(categoryName)="data">
                      <b-icon v-if="data.item.categoryName && data.item.categoryName.length > 0" icon="square-fill" :style="{ color: data.item.categoryColor }"></b-icon>
                      {{ data.item.categoryName }}
                    </template>
                    <template v-slot:cell(creationDate)="data">
                      {{ data.item.creationDate.slice(0, 19) }}
                    </template>
                  </b-table>
              </div>
              <div v-else><br><b-alert show variant="info"><b-icon icon="info-circle-fill"></b-icon> {{ $t('MYTASKS.noTasks') }}</b-alert></div>
            </b-tab>
            <b-tab><!-- Task tab -->
              <template v-slot:title><!-- Tab approvals -->
                  {{ $t('MYTASKS.approvals') }} <b-badge variant="warning">{{ approveList.length }}</b-badge>
              </template>
              <div v-if="approveList.length > 0">
                  <!-- approval table filter -->
                  <div class="m-2">
                    <b-form-group
                      :label="$t('GLOBAL.filter')"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      label-for="filterInputApproval"
                      class="mb-0 mt-4 text-primary font-weight-bold"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          v-model="filterApproval"
                          type="search"
                          id="filterInputApproval"
                          placeholder=""
                          style="background-color: #f9e49d"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button :disabled="!filterApproval" @click="filterApproval = ''">{{ $t('GLOBAL.clear') }}</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>

                  <b-row>
                    <b-col md="2"></b-col>
                    <b-col md="4"><!-- Rows per page -->
                      <b-form-group
                        :label="$t('GLOBAL.filterPerPage')"
                        label-align-sm="right"
                        label-size="sm"
                        label-cols-sm="4"
                        label-cols-md="8"
                        label-cols-lg="8"
                        label-for="perPageSelectApproval"
                      >
                        <b-form-select
                          v-model="perPageApproval"
                          id="perPageSelectTaskApproval"
                          size="sm"
                          :options="pageOptionsApproval"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6"><!-- Page selector -->
                      <b-pagination
                        v-model="currentPageApproval"
                        :total-rows="totalRowsApproval"
                        :per-page="perPageApproval"
                        align="fill"
                        size="sm"
                      ></b-pagination>
                    </b-col>
                  </b-row>

                  <b-table striped hover stacked='md'
                      outlined bordered
                      head-variant="dark"
                    :items="approveList" :fields="approveListFields"
                    :filter="filterApproval"
                    @filtered="onFilteredApproval"
                    :current-page="currentPageApproval"
                    :per-page="perPageApproval"
                  >
                    <!-- Header -->
                    <template #head(approvalCreationDate)>{{ $t('MYTASKS.date') }}</template>
                    <template #head(categoryName)>{{ $t('MYTASKS.category') }}</template>
                    <template #head(workflowName)>{{ $t('MYTASKS.workflow') }}</template>
                    <template #head(processName)>{{ $t('MYTASKS.process') }}</template>
                    <template #head(approvalDefName)>{{ $t('MYTASKS.approvalStep') }}</template>
                    <template #head(approvalStatus)>{{ $t('MYTASKS.statusApproval') }}</template>
                    <template #head(actions)>{{ $t('GLOBAL.tableActions') }}</template>

                    <!-- Values -->
                    <template v-slot:cell(processId)="data">
                      <b-button variant="primary" size="sm" v-on:click="viewProcess(data.item.processId)"><b-icon icon="search"></b-icon></b-button>
                    </template>
                    <template v-slot:cell(approvalStatus)="data">
                      <ViewStatus type="approval" :status="data.item.approvalStatus" :noText="false"></ViewStatus>
                    </template>
                    <template v-slot:cell(approvalCreationDate)="data">
                      {{ data.item.approvalCreationDate.slice(0, 19) }}
                    </template>
                    <template v-slot:cell(categoryName)="data">
                      <b-icon v-if="data.item.categoryName && data.item.categoryName.length > 0" icon="square-fill" :style="{ color: data.item.categoryColor }"></b-icon>
                      {{ data.item.categoryName }}
                    </template>
                    <template v-slot:cell(processName)="data">
                      <b-link v-on:click="viewProcess(data.item.processId)">
                        <!-- Subprocess ? -->
                        <b-icon v-if="data.item.processId != data.item.processRootId" class="mr-2" icon="puzzle" variant="warning"></b-icon>
                        <span class="text-primary font-weight-bold">{{ data.item.processName }}</span>
                      </b-link>
                    </template>
                    <template v-slot:cell(approvalDefName)="data">
                      <span class="text-primary">{{ data.item.approvalDefName }}</span>
                    </template>
                    <template v-slot:cell(actions)="data">
                      <div class="text-right">
                        <span v-if="data.item.approvalStatus === 0"><!-- created ==> available for approval/reject -->
                        <b-button
                            variant="success"
                            size="sm"
                            @click="startApproval(data.item)"
                        >
                            <b-icon icon="play-fill"></b-icon> {{ $t('MYTASKS.btnApproveReject') }}
                        </b-button>
                        </span>
                      </div>
                    </template>
                  </b-table>
              </div>
              <div v-else><br><b-alert show variant="info"><b-icon icon="info-circle-fill"></b-icon> {{ $t('MYTASKS.noApprovals') }}</b-alert></div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else><br><b-spinner label="Spinning"></b-spinner> {{ $t('GLOBAL.loading') }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ViewProcess from '../common/ViewProcess' // View the whole process data
import ViewStatus from '../common/ViewStatus'

export default {
  name: 'MyTasksPage',
  components: {
    ViewProcess,
    ViewStatus
  },
  data () {
    return {
      taskListFields: [{
        key: 'processId',
        label: '',
        sortable: false
      },
      {
        key: 'creationDate',
        label: this.$t('MYTASKS.date'),
        sortable: true
      },
      {
        key: 'categoryName',
        label: this.$t('MYTASKS.category'),
        sortable: true
      },
      {
        key: 'workflowName',
        label: this.$t('MYTASKS.workflow'),
        sortable: true
      },
      {
        key: 'processName',
        label: this.$t('MYTASKS.process'),
        sortable: true
      },
      {
        key: 'taskName',
        label: this.$t('MYTASKS.task'),
        sortable: true
      },
      {
        key: 'status',
        label: this.$t('MYTASKS.statusTask'),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('GLOBAL.tableActions'),
        sortable: false
      }],
      approveListFields: [{
        key: 'processId',
        label: '',
        sortable: false
      },
      {
        key: 'approvalCreationDate',
        label: this.$t('MYTASKS.date'),
        sortable: true
      },
      {
        key: 'categoryName',
        label: this.$t('MYTASKS.category'),
        sortable: true
      },
      {
        key: 'workflowName',
        label: this.$t('MYTASKS.workflow'),
        sortable: true
      },
      {
        key: 'processName',
        label: this.$t('MYTASKS.process'),
        sortable: true
      },
      {
        key: 'approvalDefName',
        label: this.$t('MYTASKS.approvalStep'),
        sortable: true
      },
      {
        key: 'approvalStatus',
        label: this.$t('MYTASKS.statusApproval'),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('GLOBAL.tableActions'),
        sortable: false
      }
      ],
      taskList: [],
      approveList: [],
      errorDescription: '',
      loading: true,
      currentProcessId: null,
      fromDate: this.$store.state.mytaskspageParameters.fromDate, // Search (used for tasks and approvals)
      toDate: this.$store.state.mytaskspageParameters.toDate, // Search (used for tasks and approvals)
      status: this.$store.state.mytaskspageParameters.status, // Search (used for tasks)
      workflowId: this.$store.state.mytaskspageParameters.workflowId, // Search
      workflowList: null, // Retrieved from svc call
      process: this.$store.state.mytaskspageParameters.process, // Search
      showFilter: this.$store.state.mytaskspageParameters.showFilter, // Filter
      filterTask: this.$store.state.mytaskspageParameters.filterTask, // Filter for tasks
      totalRowsTask: this.$store.state.mytaskspageParameters.totalRowsTask, // Paging for tasks
      currentPageTask: this.$store.state.mytaskspageParameters.currentPageTask, // Paging for tasks
      perPageTask: this.$store.state.mytaskspageParameters.perPageTask, // Paging for tasks
      pageOptionsTask: this.$store.state.mytaskspageParameters.pageOptionsTask, // Paging for tasks
      filterApproval: this.$store.state.mytaskspageParameters.filterApproval, // Filter for tasks
      totalRowsApproval: this.$store.state.mytaskspageParameters.totalRowsApproval, // Paging for approvals
      currentPageApproval: this.$store.state.mytaskspageParameters.currentPageApproval, // Paging for approvals
      perPageApproval: this.$store.state.mytaskspageParameters.perPageApproval, // Paging for approvals
      pageOptionsApproval: this.$store.state.mytaskspageParameters.pageOptionsApproval // Paging for approvals
    }
  },
  computed: {
  },
  mounted () {
    this.getWorkflowList()
    this.refreshData()
  },
  beforeDestroy () {
    // Save all search / and filter parameters to the store
    this.$store.commit('updateMytaskspageParameters', {
      fromDate: this.fromDate,
      toDate: this.toDate,
      status: this.status,
      workflowId: this.workflowId,
      process: this.process,
      showFilter: this.showFilter,
      filterTask: this.filterTask,
      totalRowsTask: this.totalRowsTask,
      currentPageTask: this.currentPageTask,
      perPageTask: this.perPageTask,
      pageOptionsTask: [...this.pageOptionsTask],
      filterApproval: this.filterApproval,
      totalRowsApproval: this.totalRowsApproval,
      currentPageApproval: this.currentPageApproval,
      perPageApproval: this.perPageApproval,
      pageOptionsApproval: [...this.pageOptionsApproval]
    }
    )
  },
  methods: {
    async viewProcess (processId) {
      this.currentProcessId = processId // Set the id for the ViewProcess component
      this.$nextTick(() => {
        this.$bvModal.show('viewProcessModal') // Open modal to display single process
      })
    },
    refreshData () {
      this.getMyTaskList()
      this.getMyApprovalList()
    },
    async getMyTaskList () {
      // Retrieve the list of tasks
      this.errorDescription = ''
      this.loading = true
      try {
        // Get the list of my tasks
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/mylist', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ fromDate: this.fromDate, toDate: this.toDate, status: this.status, workflowId: this.workflowId, process: this.process, rootProcessId: null })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.taskList = content.taskList
          this.totalRowsTask = content.taskList.length
        } else {
          this.errorDescription = this.$t('MYTASKS.errorTasks')
          this.taskList = []
        }
      } catch (err) {
        this.errorDescription = err
        this.taskList = []
      }

      this.loading = false
    },
    async getMyApprovalList () {
      // Retrieve the list of tasks
      try {
        // Get full list of approval tasks
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'approve/mylist', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ fromDate: this.fromDate, toDate: this.toDate, workflowId: this.workflowId, process: this.process, rootProcessId: null })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.approveList = content.approveList
          this.totalRowsApproval = content.approveList.length
        } else {
          this.approveList = []
        }
      } catch (err) {
        this.approveList = []
      }
    },
    async getWorkflowList () {
      // Populate the workflow list filter
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/simplelist', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          content.workflowList.unshift({ id: '', name: '-' })
          this.workflowList = content.workflowList
        }
      } catch (err) {
      }
    },
    onFilteredTask (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsTask = filteredItems.length
      this.currentPage = 1
    },
    onFilteredApproval (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsApproval = filteredItems.length
      this.currentPage = 1
    },
    async startApproval (approval) {
      // Store approval data into store and open approval page
      this.$store.commit('currentApprovalSet', approval)
      this.$router.push({ name: 'ApproveEdit' })
    },
    async startTask (workflowId, taskId, processId) {
      // Take task (or continue)
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/work', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({
            workflowId: workflowId,
            taskId: taskId,
            processId: processId
          })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.$store.commit('currentTaskSet', content) // Update store with this data
            this.$router.push({ name: 'TaskEdit' }) // Open the task edit page
          } else {
            this.$bvToast.toast(this.$t('MYTASKS.errorTaskWork') + ': ' + content.errorDescription, {
              title: this.$t('MYTASKS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('MYTASKS.errorTaskWork'), {
            title: this.$t('MYTASKS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
      }
    },
    async releaseTask (workflowId, taskId, processId) {
      // Take task (or continue)
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'task/release', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({
            workflowId: workflowId,
            taskId: taskId,
            processId: processId
          })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          if (content.errorCode === 0) {
            this.$bvToast.toast(this.$t('MYTASKS.released'), {
              title: this.$t('MYTASKS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
            this.getMyTaskList() // Reload
          } else {
            this.$bvToast.toast(this.$t('MYTASKS.errorRelease') + ': ' + content.errorDescription, {
              title: this.$t('MYTASKS.title'),
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-full'
            })
          }
        } else {
          this.$bvToast.toast(this.$t('MYTASKS.errorRelease'), {
            title: this.$t('MYTASKS.title'),
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-full'
          })
        }
      } catch (err) {
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.filter {
  margin-top: 1em;
  padding: 1em;
  border: 1px solid var(--secondary);
  /*
  background-color: var(--secondary);
  background: linear-gradient(90deg, rgb(200, 200, 200) 0%, rgb(220, 220, 220) 35%, rgb(200, 200, 200) 100%);
  box-shadow: 10px 10px 8px #888888;
  border: 1px solid var(--primary);
  */
  /*
  border-top: 10px solid #e5e6e7;
  border-left: 10px solid #e5e6e7;
  border-bottom: 10px solid #e5e6e7;
  padding-left: 0.5em;
  padding-top: 1em;
  margin-top: 2em;
  */
}
#viewProcessPlaceholder {
  margin-top:10px;
  xxpadding:10px;
  xxbox-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  xxborder: 10px solid rgb(141, 132, 132);
}
</style>
