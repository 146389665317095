<template>
  <div>
    <br><!-- TODO TUTTA DA RIFARE -->
    <div><br><b-img fluid :src="require('../assets/LogoLG.png')"></b-img></div><br><br>
    <h1 class="text-primary">{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data () {
    return {
      msg: 'Welcome to DigiWi application'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
