<template>
  <div id="workflowplaceholder">
    <!-- Modal frame for process display -->
    <b-modal id="viewProcessModal" size="xl">
      <div v-if="currentProcessId != null" id="viewProcessPlaceholder">
        <ViewProcess :processId="currentProcessId" :hideActions=false></ViewProcess>
      </div>
    </b-modal>
    <b-row>
      <b-col md="6"><!-- Title -->
        <h1><b-icon icon="gear-wide-connected" style="color: #3395e6"></b-icon> {{ $t('PROCESSES.title') }} <b-badge variant="warning">{{ processList.length }}</b-badge>
        </h1>
      </b-col>
      <b-col md="6" class="text-right mb-1">
        <!-- Table filter -->
        <b-form-group
          :label="$t('GLOBAL.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 mt-4 text-primary font-weight-bold"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder=""
              style="background-color: #f9e49d"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{ $t('GLOBAL.clear') }}</b-button>
            </b-input-group-append>
            <b-button
              variant="primary"
              size="sm"
              @click="loading=true;getProcessList()"
              class="ml-2"
            ><!-- Refresh -->
              <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.refresh') }}
            </b-button>
            <!-- Show / hide filter -->
            <b-icon v-if="!showFilter" @click="showFilter=true" font-scale="2" class="ml-2 p-1" icon="filter-circle-fill" variant="info"></b-icon>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col :lg="showFilter ? 3 : 12" :xl="showFilter ? 2 : 12" order-lg="12" v-show="showFilter"><!-- Filter contents -->
        <div class="filter">
          <div class="text-right">
            <b-icon v-if="showFilter" @click="showFilter=false" class="" icon="dash-square" variant="secondary"></b-icon>
          </div>
          <div class="m-1"><!-- From -->
            {{ $t('GLOBAL.filterFrom') }}
            <b-form-datepicker
              size="sm"
              dropleft
              v-model="fromDate" class="mb-2"
              inline
              today-button
              reset-button
              close-button
              :show-decade-nav="true"
              :locale="$i18n.locale">
            </b-form-datepicker>
          </div>
          <div class="m-1"><!-- To -->
            {{ $t('GLOBAL.filterTo') }}
            <b-form-datepicker
              size="sm"
              dropleft
              v-model="toDate" class="mb-2"
              inline
              today-button
              reset-button
              close-button
              :show-decade-nav="true"
              :locale="$i18n.locale">
            </b-form-datepicker>
          </div>
          <div class="m-1"><!-- Status -->
            {{ $t('GLOBAL.filterStatusProcess') }}
            <b-form-select v-model="status" class="mb-2">
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option :value="1">{{ $t('STATUS.process.running') }}</b-form-select-option>
              <b-form-select-option :value="2">{{ $t('STATUS.process.terminated') }}</b-form-select-option>
              <b-form-select-option :value="3">{{ $t('STATUS.process.deleted') }}</b-form-select-option>
            </b-form-select>
          </div>
          <div class="m-1"><!-- workflow -->
            {{ $t('GLOBAL.filterWorkflow') }}
            <b-form-select
              v-model="workflowId"
              :options="workflowList"
              class="mb-2"
              value-field="originatorId"
              text-field="name">
            </b-form-select>
          </div>
          <div class="m-1"><!-- process -->
            {{ $t('GLOBAL.filterProcess') }}
            <b-form-input v-model="process" placeholder=""></b-form-input>
          </div>
          <div class="m-1"><!-- Inclued subprocesses -->
            <b-form-checkbox class="m-1"
              id="checkbox-include-subprocesses"
              v-model="includeSubProcesses"
              name="checkbox-include-subprocesses"
              :value="1"
              :unchecked-value="0"
            >
              {{ $t('GLOBAL.filterIncludeSubProcess') }}
            </b-form-checkbox>
          </div>
          <div class="text-right m-1"><!-- Refresh + apply buttons -->
            <br>
            <b-button class="m-1"
              variant="secondary"
              size="sm"
              @click="loading=true;toDate='';fromDate='';status=null;workflowId='';process='';getProcessList()"
            >
                <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.clear') }}
            </b-button>
            <b-button class="m-1"
              variant="primary"
              size="sm"
              @click="loading=true;getProcessList()"
            >
              <b-icon icon="arrow-counterclockwise"></b-icon> {{ $t('GLOBAL.filterApply') }}
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col :lg="showFilter ? 9 : 12" :xl="showFilter ? 10 : 12"><!-- Contents -->
        <b-row class="mt-2"><!-- Paging -->
          <b-col md="2"></b-col>
          <b-col md="4"><!-- Rows per page -->
            <b-form-group
              :label="$t('GLOBAL.filterPerPage')"
              label-align-sm="right"
              label-size="sm"
              label-cols-sm="4"
              label-cols-md="8"
              label-cols-lg="8"
              label-for="perPageSelect"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6"><!-- Page selector -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
        <div v-if="!loading">
          <div v-if="processList.length > 0">
              <b-table striped hover stacked='md'
                      outlined bordered
                      head-variant="dark"
                      :items="processList" :fields="processFields"
                      :filter="filter"
                      @filtered="onFiltered"
                      :current-page="currentPage"
                      :per-page="perPage"
              >
                <!-- Header -->
                <template #head(creationDate)>{{ $t('PROCESSES.date') }}</template>
                <template #head(categoryName)>{{ $t('PROCESSES.category') }}</template>
                <template #head(workflowName)>{{ $t('PROCESSES.workflow') }}</template>
                <template #head(processName)>{{ $t('PROCESSES.process') }}</template>
                <template #head(endTaskName)>{{ $t('PROCESSES.end') }}</template>
                <template #head(status)>{{ $t('PROCESSES.status') }}</template>

                <!-- Values -->
                <template v-slot:cell(status)="data">
                  <ViewStatus type="process" :status="data.item.status" :noText="false"></ViewStatus>
                </template>
                <template v-slot:cell(categoryName)="data">
                  <b-icon v-if="data.item.categoryName && data.item.categoryName.length > 0" icon="square-fill" :style="{ color: data.item.categoryColor }"></b-icon>
                  {{ data.item.categoryName }}
                </template>
                <template v-slot:cell(processName)="data">
                    <b-link v-on:click="viewProcess(data.item.processId)">
                      <!-- Subprocess ? -->
                      <b-icon v-if="data.item.processId != data.item.processRootId" class="mr-2" icon="puzzle" variant="warning"></b-icon>
                      <span class="text-primary font-weight-bold">{{ data.item.processName }}</span>
                    </b-link>
                </template>
                <template v-slot:cell(creationDate)="data">
                    {{ data.item.creationDate.slice(0, 19) }}
                </template>
                <template v-slot:cell(processId)="data">
                    <b-button variant="primary" size="sm" v-on:click="viewProcess(data.item.processId)"><b-icon icon="search"></b-icon></b-button>
                </template>
              </b-table>
          </div>
          <div v-else><br><b-alert show variant="info"><b-icon icon="info-circle-fill"></b-icon> {{ $t('PROCESSES.noProcesses') }}</b-alert></div>
        </div>
        <div v-else><br><b-spinner label="Spinning"></b-spinner> {{ $t('GLOBAL.loading') }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import ViewProcess from '../common/ViewProcess' // View the whole process data
import ViewStatus from '../common/ViewStatus'

export default {
  name: 'ProcessesPage',
  components: {
    ViewProcess,
    ViewStatus
  },
  data () {
    return {
      processFields: [{
        key: 'processId',
        label: '',
        sortable: false
      },
      {
        key: 'creationDate',
        label: this.$t('PROCESSES.date'),
        sortable: true
      },
      {
        key: 'categoryName',
        label: this.$t('PROCESSES.category'),
        sortable: true
      },
      {
        key: 'workflowName',
        label: this.$t('PROCESSES.workflow'),
        sortable: true
      },
      {
        key: 'processName',
        label: this.$t('PROCESSES.process'),
        sortable: true
      },
      {
        key: 'endTaskName',
        label: this.$t('PROCESSES.end'),
        sortable: true
      },
      {
        key: 'status',
        label: this.$t('PROCESSES.status'),
        sortable: true
      }],
      processList: [],
      errorDescription: '', // Not implemented
      loading: true,
      currentProcessId: null,
      filter: this.$store.state.processPageParameters.filter, // Filter
      showFilter: this.$store.state.processPageParameters.showFilter, // Filter
      fromDate: this.$store.state.processPageParameters.fromDate, // Search
      toDate: this.$store.state.processPageParameters.toDate, // Search
      status: this.$store.state.processPageParameters.status, // Search
      includeSubProcesses: this.$store.state.processPageParameters.includeSubProcesses, // Search
      workflowId: this.$store.state.processPageParameters.workflowId, // Search
      workflowList: null, // Retrieved from svc call
      process: this.$store.state.processPageParameters.process, // Search
      totalRows: this.$store.state.processPageParameters.totalRows, // Paging
      currentPage: this.$store.state.processPageParameters.currentPage, // Paging
      perPage: this.$store.state.processPageParameters.perPage, // Paging
      pageOptions: this.$store.state.processPageParameters.pageOptions // Paging
    }
  },
  computed: {
  },
  mounted () {
    this.getWorkflowList()
    this.getProcessList()
  },
  beforeDestroy () {
    // Save all search / and filter parameters to the store
    this.$store.commit('updateProcessPageParameters', {
      filter: this.filter,
      showFilter: this.showFilter,
      fromDate: this.fromDate,
      toDate: this.toDate,
      status: this.status,
      includeSubProcesses: this.includeSubProcesses,
      workflowId: this.workflowId,
      process: this.process,
      totalRows: this.totalRows,
      currentPage: this.currentPage,
      perPage: this.perPage,
      pageOptions: [...this.pageOptions]
    })
  },
  methods: {
    async viewProcess (processId) {
      this.currentProcessId = processId // Set the id for the ViewProcess component
      this.$nextTick(() => {
        this.$bvModal.show('viewProcessModal') // Open modal to display single process
      })
    },
    async getProcessList () {
      // Retrieve the list of running processes
      this.errorDescription = ''
      this.loading = true
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/processes', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          },
          body: JSON.stringify({ fromDate: this.fromDate, toDate: this.toDate, status: this.status, workflowId: this.workflowId, process: this.process, includeSubProcesses: this.includeSubProcesses })
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          this.processList = content.processList
          this.totalRows = content.processList.length
        } else {
          this.errorDescription = this.$t('PROCESSES.error')
          this.processList = []
        }
      } catch (err) {
        this.errorDescription = err
        this.processList = []
      }

      this.loading = false
    },
    async getWorkflowList () {
      // Populate the workflow list filter
      try {
        // Get full list of users
        const rawResponse = await fetch(this.$store.state.serviceAPIUrl + 'workflow/simplelist', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.$store.state.userToken,
            'Accept-Language': this.$i18n.locale
          }
        })
        if (rawResponse.ok) {
          // Get the json contents
          const content = await rawResponse.json()
          content.workflowList.unshift({ id: '', name: '-' })
          this.workflowList = content.workflowList
        }
      } catch (err) {
      }
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.filter {
  margin-top: 1em;
  padding: 1em;
  border: 1px solid var(--secondary);
  /*
  background-color: var(--secondary);
  background: linear-gradient(90deg, rgb(200, 200, 200) 0%, rgb(220, 220, 220) 35%, rgb(200, 200, 200) 100%);
  box-shadow: 10px 10px 8px #888888;
  border: 1px solid var(--primary);
  */
  /*
  border-top: 10px solid #e5e6e7;
  border-left: 10px solid #e5e6e7;
  border-bottom: 10px solid #e5e6e7;
  padding-left: 0.5em;
  padding-top: 1em;
  margin-top: 2em;
  */
}
#workflowplaceholder {
  text-align: left;
}
#viewProcessPlaceholder {
  margin-top:10px;
  xxpadding:10px;
  xxbox-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  xxborder: 10px solid rgb(141, 132, 132);
}
</style>
