<template>
  <div>
    <b-alert variant="success" show>
      <b-icon class="mr-2" icon="check-circle" font-scale="1"></b-icon>
      <span v-if="this.$store.state.nextTasksData.sourcePage === 'process'">
        {{ $t('NEXTTASKS.process.1') }} <span class="font-weight-bold">{{ this.$store.state.nextTasksData.sourceObject }}</span> {{ $t('NEXTTASKS.process.2') }}
      </span>
      <span v-if="this.$store.state.nextTasksData.sourcePage === 'task'">
        {{ $t('NEXTTASKS.task.1') }} <span class="font-weight-bold">{{ this.$store.state.nextTasksData.sourceObject }}</span> {{ $t('NEXTTASKS.task.2') }}
      </span>
      <span v-if="this.$store.state.nextTasksData.sourcePage === 'approval'">
        {{ $t('NEXTTASKS.approval.1') }} <span class="font-weight-bold">{{ this.$store.state.nextTasksData.sourceObject }}</span> {{ $t('NEXTTASKS.approval.2') }}
      </span>
    </b-alert>

    <b-row>
          <b-col xs="12" md="12" lg="2"></b-col>
          <b-col xs="12" md="12" lg="8">
            <div v-if="this.$store.state.nextTasksData.rootProcessId != null" id="viewProcessPlaceholder">
              <ViewProcess :processId="this.$store.state.nextTasksData.rootProcessId" :hideActions=false></ViewProcess>
            </div>
          </b-col>
    </b-row>
  </div>
</template>

<script>
import ViewProcess from '../common/ViewProcess'

export default {
  name: 'NextTasksPage',
  components: {
    ViewProcess
  }
}
</script>

<style scoped>
#viewProcessPlaceholder {
  margin-top:10px;
  padding:10px;
  box-shadow: 5px 5px 5px 0px rgba(92, 89, 89, 0.75);
  border: 10px solid rgb(141, 132, 132);
}
</style>
